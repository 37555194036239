export const callStatusMap = (callStatus: string): string => {
  // TODO: check when implementing i18n
  const callStatusTextMap: Record<string, string> = {
    busy: 'Rechazada',
    'no-answer': 'No contesta',
    ringing: 'Llamando',
    queued: 'Llamando',
    'in-progress': 'En curso',
    initiated: 'Llamando',
    failed: 'Fallida',
    canceled: 'Cancelada',
    completed: 'Completada',
  };

  return callStatusTextMap[callStatus] || '';
};
