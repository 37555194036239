import { useNavigate, useSearch } from '@tanstack/react-router';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import {
  Route as CommunicationsRoute,
  communicationsSearchSchema,
} from 'src/routes/communications';
import {
  TableCellsIcon,
  ViewColumnsIcon,
  Squares2X2Icon,
} from '@heroicons/react/24/outline';

type ViewType = 'cards' | 'pipeline' | 'table';

interface ViewSwitcherProps {
  showLabels?: boolean;
}

const ViewSwitcher: React.FC<ViewSwitcherProps> = ({ showLabels = true }) => {
  const navigate = useNavigate();
  const { view } = useSearch({ from: CommunicationsRoute.id });

  const handleViewChange = (newView: ViewType) => {
    navigate({
      to: CommunicationsRoute.to,

      search: (prev) => {
        const parsedSearch = communicationsSearchSchema.parse(prev);
        return {
          ...parsedSearch,
          view: newView,
        };
      },
    });
  };

  const views: { value: ViewType; label: string; icon: React.ReactNode }[] = [
    {
      value: 'cards',
      label: 'Cards',
      icon: <Squares2X2Icon className="w-4 h-4" />,
    },
    {
      value: 'pipeline',
      label: 'Pipeline',
      icon: <ViewColumnsIcon className="w-4 h-4" />,
    },
    {
      value: 'table',
      label: 'Table',
      icon: <TableCellsIcon className="w-4 h-4" />,
    },
  ];

  return (
    <div className="flex gap-1">
      {views.map(({ value, label, icon }) => (
        <Button
          key={value}
          variant={view === value ? 'primary' : 'ghost'}
          size="sm"
          onClick={() => handleViewChange(value)}
          className={!showLabels ? 'p-1.5' : undefined}
        >
          {!showLabels ? icon : label}
        </Button>
      ))}
    </div>
  );
};

export default ViewSwitcher;
