import { Typography } from '@material-tailwind/react';
import { format } from 'date-fns';
import { type ReactNode } from 'react';

interface DateGroupItemsProps {
  date: Date;
  children: ReactNode;
}

export function DateGroupItems({ date, children }: DateGroupItemsProps) {
  return (
    <div className="mb-8 last:mb-0">
      <div className="flex items-center mb-4">
        <div className="flex-1 h-px bg-gray-200" />
        <div className="px-4">
          <Typography variant="small" color="gray">
            {format(date, 'EEEE, MMMM d')}
          </Typography>
        </div>
        <div className="flex-1 h-px bg-gray-200" />
      </div>
      <div className="space-y-4">{children}</div>
    </div>
  );
}
