import { type BaseProps } from 'src/modules/shared/types/component.type';

interface HeaderSkeletonProps extends BaseProps {
  avatar?: boolean;
  title?: boolean;
  subtitle?: boolean;
  actions?: boolean;
}

export const HeaderSkeleton: React.FC<HeaderSkeletonProps> = ({
  className = '',
  avatar = true,
  title = true,
  subtitle = true,
  actions = true,
  variant = 'primary',
}) => {
  const variantClasses = {
    primary: 'bg-gray-200',
    secondary: 'bg-neutral-200',
    tertiary: 'bg-tertiary-200',
    danger: 'bg-danger-200',
    ghost: 'bg-gray-200',
  };

  const bgClass = variantClasses[variant];

  return (
    <div
      className={`flex items-center justify-between animate-pulse ${className}`}
    >
      <div className="flex items-center gap-3 min-w-0">
        {avatar && (
          <div className={`w-10 h-10 ${bgClass} rounded-full flex-shrink-0`} />
        )}
        <div className="flex flex-col space-y-2">
          {title && <div className={`h-5 ${bgClass} rounded w-32`} />}
          {subtitle && <div className={`h-4 ${bgClass} rounded w-24`} />}
        </div>
      </div>
      {actions && (
        <div className="flex gap-2">
          <div className={`h-8 w-24 ${bgClass} rounded`} />
          <div className={`h-8 w-16 ${bgClass} rounded`} />
        </div>
      )}
    </div>
  );
};
