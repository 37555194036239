import { type FC } from 'react';
import { type FunnelStatus } from 'src/api/generated';

interface StatusHeaderProps {
  status: FunnelStatus;
  count: number;
  className?: string;
}

export const PipelineStatusColumnHeader: FC<StatusHeaderProps> = ({
  status,
  count,
  className = '',
}) => {
  return (
    <div className={`flex items-center justify-between ${className}`}>
      <h3 className="text-sm font-medium text-gray-900">{status.name}</h3>
      <span className="inline-flex items-center justify-center w-6 h-6 text-xs font-medium text-gray-600 bg-gray-100 rounded-full">
        {count}
      </span>
    </div>
  );
};
