/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents the type of interaction in a funnel conversation
 */
export enum FunnelInteractionType {
    MESSAGE = 'Message',
    CALL = 'Call',
}
