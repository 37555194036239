/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContactAddressCreateBodyParams } from '../models/ContactAddressCreateBodyParams';
import type { ContactAddressCreateResponse } from '../models/ContactAddressCreateResponse';
import type { ContactAddressDeleteResponse } from '../models/ContactAddressDeleteResponse';
import type { ContactAddressGetResponse } from '../models/ContactAddressGetResponse';
import type { ContactAddressListResponse } from '../models/ContactAddressListResponse';
import type { ContactAddressUpdateBodyParams } from '../models/ContactAddressUpdateBodyParams';
import type { ContactAddressUpdateResponse } from '../models/ContactAddressUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CrmContactsAddressesService {

    /**
     * @param filterBy
     * @param searchBy
     * @param sortBy
     * @param page
     * @param pageSize
     * @param pageToken
     * @returns ContactAddressListResponse Ok
     * @throws ApiError
     */
    public static getContactAddresses(
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
        pageToken?: string,
    ): CancelablePromise<ContactAddressListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/crm/contact-addresses',
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
                'pageToken': pageToken,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns ContactAddressCreateResponse Ok
     * @throws ApiError
     */
    public static createContactAddress(
        requestBody: ContactAddressCreateBodyParams,
    ): CancelablePromise<ContactAddressCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/crm/contact-addresses',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param id
     * @returns ContactAddressGetResponse Ok
     * @throws ApiError
     */
    public static getContactAddress(
        id: number,
    ): CancelablePromise<ContactAddressGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/crm/contact-addresses/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ContactAddressUpdateResponse Ok
     * @throws ApiError
     */
    public static updateContactAddress(
        id: number,
        requestBody: ContactAddressUpdateBodyParams,
    ): CancelablePromise<ContactAddressUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/crm/contact-addresses/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param id
     * @returns ContactAddressDeleteResponse Ok
     * @throws ApiError
     */
    public static deleteContactAddress(
        id: number,
    ): CancelablePromise<ContactAddressDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/crm/contact-addresses/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

}
