import { type FC } from 'react';
import {
  ComponentSize,
  ComponentVariant,
  type BaseProps,
} from 'src/modules/shared/types/component.type';
import { getDisabledClasses } from 'src/modules/shared/styles/component.styles';

const baseCardClasses = [
  'w-full',
  'bg-white',
  'rounded-lg',
  'shadow-sm',
  'border',
  'border-gray-200',
  'select-none',
  'transition-all',
  'duration-200',
];

const cardVariantClasses: Record<NonNullable<ComponentVariant>, string> = {
  primary: 'hover:ring-2 hover:ring-primary-500 hover:border-primary-500',
  secondary: 'hover:ring-2 hover:ring-gray-500 hover:border-gray-500',
  tertiary: 'hover:ring-2 hover:ring-neutral-500 hover:border-neutral-500',
  danger: 'hover:ring-2 hover:ring-red-500 hover:border-red-500',
  ghost: 'hover:ring-2 hover:ring-gray-500 hover:border-gray-500',
};

const cardSizeClasses: Record<NonNullable<ComponentSize>, string> = {
  '2xs': 'p-2',
  xs: 'p-2',
  sm: 'p-4',
  md: 'p-4',
  lg: 'p-6',
};

export type CardProps = BaseProps & {
  onClick?: () => void;
};

export const Card: FC<CardProps> = ({
  children,
  className = '',
  variant = 'primary',
  size = 'md',
  disabled = false,
  onClick,
}) => {
  const handleClick = (_e: React.MouseEvent) => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  const classes = [
    ...baseCardClasses,
    cardVariantClasses[variant],
    cardSizeClasses[size],
    getDisabledClasses(disabled),
    !disabled && onClick ? 'hover:cursor-pointer hover:shadow-md' : '',
    className,
  ].join(' ');

  return (
    <div onClick={handleClick} className={classes}>
      {children}
    </div>
  );
};
