import {
  type DragEndEvent,
  type DragOverEvent,
  type DragStartEvent,
} from '@dnd-kit/core';
import {
  type FunnelStatus,
  type FunnelConversationDocument,
} from 'src/api/generated';
import { useUpdateConversationStatusMutation } from '../api/funnels/funnel-conversations-updates';
import { useBoardDnd } from 'src/modules/shared/hooks/board/use-board-dnd';

interface UsePipelineDndParams {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}

interface UsePipelineDndReturn {
  activeConversation: FunnelConversationDocument | null;
  activeStatusId: string | null;
  shouldAnimate: boolean;
  isUpdating: boolean;
  sensors: ReturnType<typeof useBoardDnd>['sensors'];
  handleDragStart: (event: DragStartEvent) => void;
  handleDragOver: (event: DragOverEvent) => void;
  handleDragEnd: (event: DragEndEvent) => void;
}

export const usePipelineDnd = ({
  onSuccess,
  onError,
}: UsePipelineDndParams): UsePipelineDndReturn => {
  const { updateStatus, loading: isUpdating } =
    useUpdateConversationStatusMutation(
      onSuccess,
      onError ??
        ((error) => {
          console.error('Failed to update conversation status:', error);
        })
    );

  const {
    activeItem: activeConversation,
    activeContainerId: activeStatusId,
    shouldAnimate,
    sensors,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
  } = useBoardDnd<FunnelConversationDocument>({
    onItemMove: async (conversationId, statusId) => {
      await updateStatus({
        id: parseInt(conversationId),
        data: { funnelStatusId: parseInt(statusId) },
      });
    },
    onError,
    getItemData: (event: DragStartEvent) =>
      event.active.data.current?.conversation,
    getContainerId: (event: DragOverEvent | DragEndEvent) => {
      if (!event.over) return undefined;

      const overStatus = event.over.data.current?.status as FunnelStatus;
      const overContainer = event.over.data.current?.container as FunnelStatus;
      const targetStatus = overStatus || overContainer;
      return targetStatus?.id?.toString();
    },
    getSourceContainerId: (event: DragEndEvent) => {
      const status = event.active.data.current?.status as FunnelStatus;
      return status?.id?.toString();
    },
  });

  return {
    activeConversation,
    activeStatusId,
    shouldAnimate,
    isUpdating,
    sensors,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
  };
};
