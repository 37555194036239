import { useNavigate, useSearch } from '@tanstack/react-router';
import ContactPanel from '../organisms/contact-panel';
import { useEffect } from 'react';
import {
  Route as CommunicationsRoute,
  CommunicationsSearch,
} from 'src/routes/communications';
import { useFunnels } from 'src/modules/communications/hooks/api';
import { ConversationDetailPanel } from '../organisms/conversation-detail-panel';
import ConversationPanel from '../organisms/conversation-panel/conversation-panel';

interface CommunicationsPageProps {}

const CommunicationsPage: React.FC<CommunicationsPageProps> = () => {
  const navigate = useNavigate();
  const { funnelId, conversationId, contactId } = useSearch({
    from: CommunicationsRoute.id,
    select: (search: CommunicationsSearch) => ({
      view: search.view,
      funnelId: search.funnelId,
      conversationId: search.conversationId,
      contactId: search.contactId,
    }),
  });

  const { funnels, loading: loadingFunnels } = useFunnels();

  useEffect(() => {
    const omitFirstFunnelSelection =
      loadingFunnels || funnels.length === 0 || funnelId;
    if (omitFirstFunnelSelection) return;

    navigate({
      to: CommunicationsRoute.to,
      search: (prev) => ({
        ...(prev as CommunicationsSearch),
        view: (prev as CommunicationsSearch).view,
        funnelId: funnels[0].id,
      }),
    });
  }, [loadingFunnels, funnels, funnelId, navigate]);

  const handleFunnelSelect = (id: number) => {
    navigate({
      to: CommunicationsRoute.to,
      search: (prev) => {
        const {
          conversationId: _,
          contactId: __,
          ...rest
        } = prev as CommunicationsSearch;

        return {
          ...rest,
          funnelId: id,
        };
      },
    });
  };

  const handleConversationSelect = (id: number) => {
    navigate({
      to: CommunicationsRoute.to,
      search: (prev) => {
        const { contactId: _, ...rest } = prev as CommunicationsSearch;

        return {
          ...rest,
          conversationId: id,
        };
      },
    });
  };

  const handleConversationClose = () => {
    navigate({
      to: CommunicationsRoute.to,
      search: (prev) => {
        const {
          conversationId: _,
          contactId: __,
          ...rest
        } = prev as CommunicationsSearch;

        return rest;
      },
    });
  };

  const handleOpenContactProfile = (id: number) => {
    navigate({
      to: CommunicationsRoute.to,
      search: (prev) => ({
        ...(prev as CommunicationsSearch),
        contactId: id,
      }),
    });
  };

  const handleCloseContactProfile = () => {
    navigate({
      to: CommunicationsRoute.to,
      search: (prev) => {
        const { contactId: _, ...rest } = prev as CommunicationsSearch;

        return rest;
      },
    });
  };

  const getListWidth = () => {
    if (!conversationId && !contactId) return 'w-full';
    if (conversationId && contactId) return 'w-5/12';
    if (conversationId) return 'w-7/12';
    return 'w-9/12';
  };

  const getConversationWidth = () => {
    if (!conversationId) return 'w-0 hidden';
    if (!contactId) return 'w-5/12';
    return 'w-4/12';
  };

  const getContactProfileWidth = () => {
    if (!contactId) return 'w-0 hidden';
    return 'w-3/12';
  };

  if (loadingFunnels) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-gray-500">Loading funnels...</p>
      </div>
    );
  }

  if (funnels.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <p className="text-gray-500">No funnels available</p>
        <p className="text-sm text-gray-400 mt-2">
          Create a funnel to get started
        </p>
      </div>
    );
  }

  return (
    <div className="flex h-full">
      <div
        className={`${getListWidth()} transition-all duration-200 border-r border-gray-200`}
      >
        <ConversationPanel
          funnels={funnels}
          selectedFunnelId={funnelId}
          onFunnelSelect={handleFunnelSelect}
          onConversationSelect={handleConversationSelect}
          selectedConversationId={conversationId}
        />
      </div>

      <div
        className={`${getConversationWidth()} transition-all duration-200 border-r border-gray-200`}
      >
        {conversationId && (
          <ConversationDetailPanel
            conversationId={conversationId}
            onOpenProfile={handleOpenContactProfile}
            onClose={handleConversationClose}
          />
        )}
      </div>

      <div
        className={`${getContactProfileWidth()} transition-all duration-200`}
      >
        {contactId && (
          <ContactPanel
            onClose={handleCloseContactProfile}
            contactId={contactId}
          />
        )}
      </div>
    </div>
  );
};

export default CommunicationsPage;
