import React from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from '@material-tailwind/react';
import {
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';
import Button from '../../../atoms/buttons/button';

export interface MenuDropdownItem {
  icon?: React.ReactNode;
  label: string;
  onClick: () => void;
  separator?: boolean;
}

type MenuDropdownProps = {
  label: string;
  items: MenuDropdownItem[];
  className?: string;
  size?: ComponentSize;
  variant?: ComponentVariant;
  icon?: React.ReactNode;
  placement?:
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-start'
    | 'bottom'
    | 'bottom-end'
    | 'left-start'
    | 'left'
    | 'left-end';
};

const MenuDropdown: React.FC<MenuDropdownProps> = ({
  label,
  items,
  className = '',
  size = 'md',
  variant = 'primary',
  icon,
  placement = 'bottom-end',
}) => {
  const menuItemSizeClasses: Record<ComponentSize, string> = {
    lg: 'py-3 px-4 text-md',
    md: 'py-2 px-4 text-sm',
    sm: 'py-1.5 px-3 text-sm',
    xs: 'py-1 px-2 text-xs',
    '2xs': 'py-0.5 px-1.5 text-xs',
  };

  const menuItemClasses = [
    'flex',
    'items-center',
    'gap-2',
    menuItemSizeClasses[size],
  ].join(' ');

  return (
    <Menu placement={placement}>
      <MenuHandler>
        <div className="inline-block">
          <Button variant={variant} size={size} className={className}>
            <div className="flex items-center justify-center gap-1">
              {icon}
              <span>{label}</span>
            </div>
            <ChevronDownIcon className="h-4 w-4 ml-1" />
          </Button>
        </div>
      </MenuHandler>
      <MenuList className="p-1">
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <MenuItem onClick={item.onClick} className={menuItemClasses}>
              {item.icon}
              <span>{item.label}</span>
            </MenuItem>
            {item.separator && index < items.length - 1 && (
              <hr className="my-1 border-blue-gray-50" />
            )}
          </React.Fragment>
        ))}
      </MenuList>
    </Menu>
  );
};

export default MenuDropdown;
