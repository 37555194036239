import { type FC } from 'react';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { type BaseProps } from 'src/modules/shared/types/component.type';
import { Panel } from 'src/modules/shared/components/molecules/panel';

interface DraggableBoardColumnProps extends BaseProps {
  items: string[];
  header?: React.ReactNode;
  isHighlighted?: boolean;
  setRefs?: (node: HTMLDivElement | null) => void;
}

export const DraggableBoardColumn: FC<DraggableBoardColumnProps> = ({
  children,
  className = '',
  items,
  header,
  isHighlighted,
  setRefs,
}) => {
  return (
    <Panel
      neumorphic="inset-sm"
      className={`flex-none w-[320px] h-full min-h-0 mx-2 ${className}`}
      isHighlighted={isHighlighted}
    >
      <div ref={setRefs} className="flex flex-col h-full min-h-0">
        {header && <div className="m-4">{header}</div>}

        <div className="flex-1 min-h-0 overflow-y-auto overflow-x-hidden px-0.5">
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            <div className="flex flex-col h-full space-y-4 p-2 py-0.5">
              {children}
            </div>
          </SortableContext>
        </div>
      </div>
    </Panel>
  );
};
