import { useState } from 'react';
import MenuDropdown from 'src/modules/shared/components/molecules/dropdowns/menu-dropdown';
import { MdAdd, MdCall, MdPersonAddAlt1 } from 'react-icons/md';
import { SiWhatsapp } from 'react-icons/si';
import Modal from 'src/modules/shared/components/molecules/modals/modal';
import { NewCallForm } from '../calls/new-call-form';
import { useGlobalPhoneContext } from 'src/contexts/global-phone-context';

export const InteractionsActionsDropdown = () => {
  const [isNewCallOpen, setIsNewCallOpen] = useState(false);
  const { setGlobalPhoneVisible } = useGlobalPhoneContext();

  const actionItems = [
    {
      icon: <MdCall className="h-4 w-4" />,
      label: 'New Call',
      onClick: () => {
        setGlobalPhoneVisible(true);
        setIsNewCallOpen(true);
      },
    },
    {
      icon: <SiWhatsapp className="h-4 w-4" />,
      label: 'New WhatsApp',
      onClick: () => {
        /* TODO: Implement new message */
      },
    },
    {
      icon: <MdPersonAddAlt1 className="h-4 w-4" />,
      label: 'New Contact',
      onClick: () => {
        /* TODO: Implement new contact */
      },
    },
  ];

  return (
    <>
      <MenuDropdown
        label=""
        items={actionItems}
        icon={<MdAdd className="h-5 w-5" />}
        size="sm"
        variant="primary"
        placement="bottom-start"
        className="rounded-lg"
      />

      <Modal
        isOpen={isNewCallOpen}
        onClose={() => setIsNewCallOpen(false)}
        header={<h2 className="text-2xl font-bold">New Call</h2>}
        body={<NewCallForm onClose={() => setIsNewCallOpen(false)} />}
      />
    </>
  );
};
