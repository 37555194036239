/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents the direction of a funnel interaction
 */
export enum FunnelInteractionDirection {
    INCOMING = 'incoming',
    OUTGOING = 'outgoing',
}
