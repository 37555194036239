import { XCircleIcon } from '@heroicons/react/24/solid';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import TextInput from 'src/modules/shared/components/atoms/inputs/text-input';

interface DisplayProps {
  value: string;
  initialValue: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  className?: string;
  disabled?: boolean;
}

function Display({
  value,
  initialValue,
  onChange,
  onClear,
  className = '',
  disabled = false,
}: DisplayProps) {
  const hasValue = value !== initialValue;

  const textInputClasses = [
    'text-center',
    'text-lg',
    'text-blue-600',
    'rounded-none',
    'border-b',
    'border-blue-600',
    'pt-2',
    'pl-2',
    'pr-10',
    'h-10',
    'focus:bg-blue-50/80',
    'hover:bg-blue-50/80',
  ].join(' ');

  return (
    <div className={`relative flex items-center w-full ${className}`}>
      <TextInput
        value={value}
        onChange={onChange}
        placeholder="+56..."
        disabled={disabled}
        className={textInputClasses}
      />
      {hasValue && (
        <Button
          onClick={onClear}
          variant="ghost"
          size="xs"
          className="absolute right-0 mr-1 text-red-300 hover:text-red-900 bg-transparent border-none"
          disabled={disabled}
        >
          <XCircleIcon strokeWidth={1} className="h-6 w-6" />
        </Button>
      )}
    </div>
  );
}

export default Display;
