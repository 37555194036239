import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from 'src/hooks/api/api';
import { Contact, CrmContactsService } from 'src/api/generated';

interface UseContactParams {
  id: number;
}

interface UseContactResponse extends ApiResponse {
  contact: Contact | null;
}

export const useContact = (
  { id }: UseContactParams,
  enabled: boolean = true
): UseContactResponse => {
  const fetchContact = async (): Promise<Contact | null> => {
    return await CrmContactsService.getContact(id);
  };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['crm/contacts', id],
    queryFn: fetchContact,
    enabled,
  });

  return {
    loading,
    error,
    contact: data ?? null,
    refetch,
  };
};
