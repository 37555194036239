import { useState, useCallback } from 'react';
import {
  type DragEndEvent,
  type DragOverEvent,
  type DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

interface UseBoardDndParams<TItem> {
  onItemMove?: (itemId: string, containerId: string) => Promise<void>;
  onError?: (error: unknown) => void;
  getItemData?: (event: DragStartEvent) => TItem | undefined;
  getContainerId?: (event: DragOverEvent | DragEndEvent) => string | undefined;
  getSourceContainerId?: (event: DragEndEvent) => string | undefined;
}

interface UseBoardDndReturn<TItem> {
  activeItem: TItem | null;
  activeContainerId: string | null;
  shouldAnimate: boolean;
  isUpdating: boolean;
  sensors: ReturnType<typeof useSensors>;
  handleDragStart: (event: DragStartEvent) => void;
  handleDragOver: (event: DragOverEvent) => void;
  handleDragEnd: (event: DragEndEvent) => void;
}

export function useBoardDnd<TItem>({
  onItemMove,
  onError,
  getItemData,
  getContainerId,
  getSourceContainerId,
}: UseBoardDndParams<TItem>): UseBoardDndReturn<TItem> {
  const [activeItem, setActiveItem] = useState<TItem | null>(null);
  const [shouldAnimate, setShouldAnimate] = useState(true);
  const [activeContainerId, setActiveContainerId] = useState<string | null>(
    null
  );
  const [isUpdating, setIsUpdating] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
        delay: 100,
        tolerance: 5,
      },
    })
  );

  function resetDragState(animate: boolean) {
    setShouldAnimate(animate);
    setActiveItem(null);
    setActiveContainerId(null);
  }

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      if (!getItemData) return;

      const item = getItemData(event);
      if (item) {
        resetDragState(true);
        setActiveItem(item);
      }
    },
    [getItemData]
  );

  const handleDragOver = useCallback(
    (event: DragOverEvent) => {
      if (!getContainerId) return;

      const containerId = getContainerId(event);
      setActiveContainerId(containerId ?? null);
    },
    [getContainerId]
  );

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      if (!getContainerId || !getItemData || !onItemMove) return;

      const containerId = getContainerId(event);
      if (!containerId) {
        resetDragState(true);
        return;
      }

      const item = getItemData(event);
      if (!item) {
        console.error('No item data found in drag event');
        return;
      }

      const sourceContainerId = getSourceContainerId?.(event);
      if (sourceContainerId === containerId) {
        resetDragState(true);
        return;
      }

      setShouldAnimate(false);
      setIsUpdating(true);

      try {
        await onItemMove(event.active.id.toString(), containerId);
        setActiveItem(null);
        setActiveContainerId(null);
      } catch (error) {
        onError?.(error);
        resetDragState(true);
      } finally {
        setIsUpdating(false);
      }
    },
    [getContainerId, getItemData, onItemMove, onError, getSourceContainerId]
  );

  return {
    activeItem,
    activeContainerId,
    shouldAnimate,
    isUpdating,
    sensors,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
  };
}
