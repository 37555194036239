import {
  SearcherDocumentSearchFilterOperator,
  SearcherDocumentSearchSortDirection,
} from 'src/api/generated';
import { useFunnelConversationDocuments } from '../../../../hooks/api';
import ConversationList from '../../../molecules/lists/conversation-list';
import { useMemo } from 'react';

interface ConversationListViewProps {
  selectedFunnelId?: number;
  searchTerm?: string;
  onConversationSelect?: (id: number) => void;
  selectedConversationId?: number;
}

export default function ConversationListView({
  selectedFunnelId,
  searchTerm,
  onConversationSelect,
  selectedConversationId,
}: ConversationListViewProps) {
  const searchParams = useMemo(() => {
    if (!selectedFunnelId) return {};

    return {
      search: searchTerm,
      filters: [
        {
          field: 'funnelId',
          operator: 'eq' as SearcherDocumentSearchFilterOperator,
          value: selectedFunnelId.toString(),
        },
      ],
      sort: [
        {
          field: 'createdAt',
          direction: 'desc' as SearcherDocumentSearchSortDirection,
        },
      ],
      size: 20,
    };
  }, [selectedFunnelId, searchTerm]);

  const {
    conversations,
    loading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useFunnelConversationDocuments(searchParams, !!selectedFunnelId);

  return (
    <ConversationList
      isLoading={loading}
      error={error}
      conversations={conversations}
      onLoadMore={() => fetchNextPage()}
      hasNextPage={hasNextPage && !isFetchingNextPage}
      onConversationSelect={onConversationSelect}
      selectedConversationId={selectedConversationId?.toString()}
      onRetry={refetch}
    />
  );
}
