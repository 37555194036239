import { useEffect, useState } from 'react';
import { CallProvider } from 'src/hooks/phones/types';
import { Typography } from '@material-tailwind/react';
import { motion } from 'framer-motion';
import { formatPhoneNumberAsYouType } from 'src/modules/shared/utils/formatting/phone.util';
import { formatDuration } from 'src/modules/shared/utils/formatting/time.util';
import STATES from 'states/index';
import { usePhoneContext } from 'src/contexts/phone-context';
import { AcceptCallButton } from '../../../atoms/phones/buttons/accept-call-button';
import { EndCallButton } from '../../../atoms/phones/buttons/end-call-button';
import { MuteButton } from '../../../atoms/phones/buttons/mute-button';
import { SettingsButton } from '../../../atoms/phones/buttons/settings-button';

interface ContainerPhoneProps {
  callProvider: CallProvider;
}

export const ContainerPhone: React.FC<ContainerPhoneProps> = ({
  callProvider: {
    callActions,
    loading: callProviderLoading,
    error: callProviderError,
    onCallNumber,
    callerNumber,
  },
}) => {
  const [duration, setDuration] = useState(0);
  const { phoneState, isMuted, setIsMuted } = usePhoneContext();

  useEffect(() => {
    if (phoneState !== STATES.PHONE.ON_CALL) return;

    const timer = setInterval(() => {
      setDuration((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [phoneState]);

  useEffect(() => {
    if (phoneState !== STATES.PHONE.ON_CALL) {
      setDuration(0);
    }
  }, [phoneState]);

  if (callProviderLoading || callProviderError) return null;

  const handleMute = () => {
    const newMutedState = !isMuted;
    setIsMuted(newMutedState);
    callActions.muteCall(newMutedState);
  };

  const currentNumber =
    phoneState === STATES.PHONE.INCOMING ? callerNumber : onCallNumber;
  const displayNumber = currentNumber
    ? formatPhoneNumberAsYouType(currentNumber)
    : '';

  const renderCallControls = () => {
    if (phoneState === STATES.PHONE.INCOMING) {
      return (
        <div className="flex items-center gap-2">
          <AcceptCallButton onClick={callActions.acceptCall} />
          <EndCallButton onClick={callActions.rejectCall} />
        </div>
      );
    }
    return (
      <div className="flex items-center gap-2">
        <MuteButton onClick={handleMute} isMuted={isMuted} />
        <EndCallButton onClick={callActions.endCall} />
        <SettingsButton disabled onClick={() => {}} />
      </div>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="absolute top-4 left-0 right-0 mx-auto flex justify-center z-10"
    >
      <div className="bg-white rounded-lg shadow-nm-floating-lg p-3 flex items-center justify-between gap-4 min-w-[250px]">
        <div className="flex flex-col">
          <Typography className="text-sm font-medium text-gray-900">
            {displayNumber}
          </Typography>
          {phoneState === STATES.PHONE.ON_CALL && (
            <Typography className="text-sm text-gray-500">
              {formatDuration(duration)}
            </Typography>
          )}
          {phoneState === STATES.PHONE.INCOMING && (
            <Typography className="text-sm text-gray-500">
              Incoming call...
            </Typography>
          )}
        </div>

        {renderCallControls()}
      </div>
    </motion.div>
  );
};
