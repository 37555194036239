import { MessageStatus } from 'src/api/generated';
import {
  CheckIcon,
  ClockIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import Tooltip from '../../tooltips/tooltip';
import { type BaseProps } from 'src/modules/shared/types/component.type';
import { Typography } from '@material-tailwind/react';

interface MessageStatusIndicatorProps extends BaseProps {
  status: MessageStatus;
  errorMessage?: string;
}

interface StatusConfig {
  icon: JSX.Element;
  text: string;
}

const STATUS_CONFIGS: Record<MessageStatus, StatusConfig | null> = {
  [MessageStatus.PENDING]: {
    icon: <ClockIcon className="h-3 w-3" />,
    text: 'Sending',
  },
  [MessageStatus.FAILED]: {
    icon: <ExclamationCircleIcon className="h-4 w-4 mt-0.5 stroke-[3px]" />,
    text: 'Failed',
  },
  [MessageStatus.SENT]: {
    icon: <CheckIcon className="h-3 w-3 stroke-[2px]" />,
    text: 'Sent',
  },
  [MessageStatus.DELIVERED]: {
    icon: (
      <span className="flex -space-x-1">
        <CheckIcon className="h-3 w-3 stroke-[2px]" />
        <CheckIcon className="h-3 w-3 stroke-[2px]" />
      </span>
    ),
    text: 'Delivered',
  },
  [MessageStatus.READ]: {
    icon: (
      <span className="flex -space-x-1">
        <CheckIcon className="h-3 w-3 stroke-[3px]" />
        <CheckIcon className="h-3 w-3 stroke-[3px]" />
      </span>
    ),
    text: 'Read',
  },
  [MessageStatus.DELETED]: null,
  [MessageStatus.WARNING]: null,
};

const VARIANT_COLORS = {
  primary: 'text-blue-600',
  secondary: 'text-secondary-600',
  tertiary: 'text-tertiary-600',
  danger: 'text-rose-500',
  ghost: 'text-gray-400',
} as const;

const baseContainerClasses = 'inline-flex items-center gap-1 text-xs';

export const MessageStatusIndicator: React.FC<MessageStatusIndicatorProps> = ({
  status,
  errorMessage,
  variant = 'primary',
  className = '',
}) => {
  const config = STATUS_CONFIGS[status];
  if (!config) return null;

  const colorClass =
    status === MessageStatus.PENDING
      ? VARIANT_COLORS.ghost
      : status === MessageStatus.FAILED
        ? VARIANT_COLORS.danger
        : VARIANT_COLORS[variant];

  if (status === MessageStatus.FAILED) {
    return (
      <Tooltip content={errorMessage || config.text} size="sm" placement="top">
        <span className={`${baseContainerClasses} ${colorClass} ${className}`}>
          {config.icon}
        </span>
      </Tooltip>
    );
  }

  return (
    <span className={`${baseContainerClasses} ${colorClass} ${className}`}>
      {config.icon}
      <Typography className="text-xs">{config.text}</Typography>
    </span>
  );
};
