import { useState } from 'react';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import { PhoneIcon } from '@heroicons/react/24/solid';
import { ContactSelect } from 'src/modules/shared/components/molecules/contact-select';
import { formatPhoneNumberAsYouType } from 'src/modules/shared/utils/formatting/phone.util';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import TextInput from 'src/modules/shared/components/atoms/inputs/text-input';

interface NewCallFormProps {
  onClose: () => void;
}

export const NewCallForm = ({ onClose }: NewCallFormProps) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedContactId, setSelectedContactId] = useState<string | null>(
    null
  );

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPhoneNumberAsYouType(e.target.value);
    setPhoneNumber(formatted);
  };

  const handleContactSelect = (contactId: string, phoneNumber: string) => {
    setSelectedContactId(contactId);
    setPhoneNumber(phoneNumber);
  };

  const handleStartCall = () => {
    if (!phoneNumber) return;
    // TODO: Implement start call
    onClose();
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">New Call</h2>
        <Button
          variant="ghost"
          size="sm"
          disabled
          className="flex items-center gap-2"
        >
          <UserCircleIcon className="h-5 w-5" />
          View Contact
        </Button>
      </div>

      <div className="space-y-4">
        <TextInput
          type="tel"
          placeholder="Phone Number"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        />

        <ContactSelect
          onSelect={handleContactSelect}
          selectedContactId={selectedContactId}
        />

        <div className="flex justify-end gap-3">
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="flex items-center gap-2"
            disabled={!phoneNumber}
            onClick={handleStartCall}
          >
            <PhoneIcon className="h-4 w-4" />
            Call
          </Button>
        </div>
      </div>
    </div>
  );
};
