/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnalysisValue } from '../models/AnalysisValue';
import type { CallAnalysis } from '../models/CallAnalysis';
import type { PaginatedData_AnalysisValueView_ } from '../models/PaginatedData_AnalysisValueView_';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AnalysisService {

    /**
     * @param id
     * @returns any Ok
     * @throws ApiError
     */
    public static getCallCallAnalysis(
        id: number,
    ): CancelablePromise<CallAnalysis | null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/calls/{id}/call-analyses',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @param callsIds
     * @returns CallAnalysis Ok
     * @throws ApiError
     */
    public static getCallsCallAnalyses(
        callsIds: Array<number>,
    ): CancelablePromise<Array<CallAnalysis>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/calls/call-analyses',
            query: {
                'callsIds': callsIds,
            },
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @param callAnalysisIds
     * @returns AnalysisValue Ok
     * @throws ApiError
     */
    public static getCallAnalysesValues(
        callAnalysisIds?: Array<number>,
    ): CancelablePromise<Array<AnalysisValue>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/analysis-values',
            query: {
                'callAnalysisIds': callAnalysisIds,
            },
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @param filterBy
     * @param searchBy
     * @param sortBy
     * @param page
     * @param pageSize
     * @param pageToken
     * @returns PaginatedData_AnalysisValueView_ Ok
     * @throws ApiError
     */
    public static getAnalysisValueViews(
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
        pageToken?: string,
    ): CancelablePromise<PaginatedData_AnalysisValueView_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/analysis-values/views',
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
                'pageToken': pageToken,
            },
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

}
