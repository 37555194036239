import { type FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { type BaseProps } from 'src/modules/shared/types/component.type';

type DraggableItemProps = BaseProps & {
  id: string;
  data?: Record<string, unknown>;
  disabled?: boolean;
};

export const DraggableItem: FC<DraggableItemProps> = ({
  id,
  children,
  className = '',
  data = {},
  disabled = false,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    data,
    disabled,
  });

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
      className={`
        bg-white rounded-lg transition-all duration-200 shadow-nm-flat-xs hover:shadow-nm-flat-sm
        ${isDragging ? 'cursor-grabbing shadow-nm-flat-xl' : 'cursor-grab'}
        ${className}
      `}
    >
      {children}
    </div>
  );
};
