// src/app.tsx

import { useAuth0 } from '@auth0/auth0-react';
import AuthenticatedApp from 'components/organisms/app-layouts/authenticated-app';
import UnauthenticatedApp from 'components/organisms/app-layouts/unauthenticated-app';
import { useMatchRoute, Outlet } from '@tanstack/react-router';
import Loading from 'components/atoms/loading/loading';

export function App() {
  const { isAuthenticated, isLoading } = useAuth0();
  const matchRoute = useMatchRoute();

  // TODO: try to do it for /integrations/*
  const pipedriveParams = matchRoute({
    to: '/integrations/pipedrive',
    fuzzy: true,
  });

  if (isLoading) {
    return <Loading className="h-screen flex items-center justify-center" />;
  }

  if (pipedriveParams) {
    return <Outlet />;
  }

  return (
    <div className="flex flex-col min-h-screen">
      {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </div>
  );
}
