import { type FC } from 'react';
import { type FunnelConversationDocument } from 'src/api/generated';
import { formatFullName } from 'src/modules/shared/utils/formatting/name.util';
import { ChannelIcon } from 'src/modules/shared/components/atoms/icons/channel-icon';

interface CardConversationHeaderProps {
  conversation: FunnelConversationDocument;
  className?: string;
}

export const ConversationCardHeader: FC<CardConversationHeaderProps> = ({
  conversation,
  className = '',
}) => {
  const contact = conversation.contacts?.[0];
  const address = contact?.addresses?.[0];

  return (
    <div className={`flex items-start justify-between ${className}`}>
      <div className="flex-1 min-w-0">
        <h4 className="text-sm font-medium text-gray-900 truncate">
          {formatFullName(contact?.firstName, contact?.lastName)}
        </h4>
        <p className="text-xs text-gray-500 truncate">
          {address?.address || 'No address'}
        </p>
      </div>
      <div className="ml-2">
        <ChannelIcon channelType={address?.channelType} />
      </div>
    </div>
  );
};
