import React, { ComponentProps, ReactNode } from 'react';
import { BaseProps } from 'src/modules/shared/types/component.type';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { motion, AnimatePresence } from 'framer-motion';

interface ExpandableProps
  extends BaseProps<Omit<ComponentProps<'div'>, 'children'>> {
  children: ReactNode;
  expandedContent: ReactNode;
  separator?: boolean;
  expandIcon?: ReactNode;
  collapseIcon?: ReactNode;
}

const baseExpandableClasses = [
  'overflow-hidden',
  'transition-all',
  'duration-200',
];

const baseExpandButtonClasses = [
  'w-full',
  'h-8',
  'hover:bg-gray-50',
  'transition-colors',
  'duration-200',
  'flex',
  'items-center',
  'justify-center',
];

export const Expandable: React.FC<ExpandableProps> = ({
  children,
  expandedContent,
  className = '',
  separator = true,
  expandIcon = <MdExpandMore className="w-5 h-5" />,
  collapseIcon = <MdExpandLess className="w-5 h-5" />,
  ...rest
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const classes = [...baseExpandableClasses, className].join(' ');

  const buttonClasses = [
    ...baseExpandButtonClasses,
    separator && 'border-t border-gray-100',
  ].join(' ');

  return (
    <div className={classes} {...rest}>
      {children}

      <button
        type="button"
        className={buttonClasses}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? collapseIcon : expandIcon}
      </button>

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {expandedContent}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
