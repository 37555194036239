// src/hooks/api/tasks.ts

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from './api';
import { ApiQueryParams, NewTask, Task, TasksService } from 'src/api/generated';
import { CacheKeyValue } from 'src/types/cache';
import { updateCache } from 'src/utils/cache';

interface TasksResponse extends ApiResponse {
  tasks: Task[];
}

export const useTasks = (queryParams: ApiQueryParams = {}): TasksResponse => {
  const fetchTasks = async (): Promise<Task[]> => {
    const { filterBy, searchBy, sortBy, page, pageSize } = queryParams;

    return await TasksService.getTasks(
      filterBy,
      searchBy,
      sortBy,
      page,
      pageSize
    );
  };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['tasks', queryParams],
    queryFn: fetchTasks,
  });
  const tasks = useMemoizedArrayData<Task>(data);

  return {
    loading,
    error,
    tasks,
    refetch,
  };
};

interface UpdateTaskParams {
  taskId: number;
  updatedTask: Partial<Task>;
}

interface UpdateTaskResponse {
  loading: boolean;
  error: Error | null;
  updateTask: (updateTaskParams: UpdateTaskParams) => void;
}

export const useUpdateTask = (
  onUpdateSuccess?: () => void,
  onUpdateError?: () => void,
  cacheKey: CacheKeyValue[] = []
): UpdateTaskResponse => {
  const queryClient = useQueryClient();

  const updateTask = async ({
    taskId: taskId,
    updatedTask: updatedTask,
  }: UpdateTaskParams): Promise<Task> => {
    return await TasksService.updateTask(taskId, updatedTask);
  };
  const mutation = useMutation({
    mutationFn: updateTask,
    onSuccess: (data: Task) => {
      const queryKey = ['tasks', ...cacheKey];
      updateCache({
        queryClient,
        queryKey,
        updatedData: data,
      });
      queryClient.invalidateQueries({ queryKey: ['tasksCount'] });
      onUpdateSuccess && onUpdateSuccess();
    },
    onError: () => onUpdateError && onUpdateError(),
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    updateTask: mutation.mutate,
  };
};

interface CreateTaskParams {
  newTask: NewTask;
}

interface CreateTaskResponse {
  loading: boolean;
  error: Error | null;
  createTask: (createTaskParams: CreateTaskParams) => void;
}

export const useCreateTask = (
  onCreateSuccess?: () => void,
  onCreateError?: () => void,
  cacheKey: CacheKeyValue[] = []
): CreateTaskResponse => {
  const queryClient = useQueryClient();

  const createTask = async ({ newTask }: CreateTaskParams): Promise<Task> => {
    return await TasksService.createTask(newTask);
  };
  const mutation = useMutation({
    mutationFn: createTask,
    onSuccess: (data: Task) => {
      const queryKey = ['tasks', ...cacheKey];
      updateCache({
        queryClient,
        queryKey,
        updatedData: data,
      });
      queryClient.invalidateQueries({ queryKey: ['tasksCount'] });
      onCreateSuccess && onCreateSuccess();
    },
    onError: () => onCreateError && onCreateError(),
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    createTask: mutation.mutate,
  };
};

interface TasksCountResponse extends ApiResponse {
  count: number;
}

export const useActiveOpportunityTasksCount = (
  queryParams: ApiQueryParams = {},
  onlyOverdue: boolean = false
): TasksCountResponse => {
  const fetchTasksCount = async (): Promise<number> => {
    const { filterBy, searchBy, sortBy, page, pageSize, pageToken } =
      queryParams;

    return await TasksService.getActiveOpportunityTasksCount(
      filterBy,
      searchBy,
      sortBy,
      page,
      pageSize,
      pageToken,
      onlyOverdue
    );
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ['tasksCount', queryParams],
    queryFn: fetchTasksCount,
  });
  const count = data ?? 0;

  return {
    loading: isLoading,
    error,
    count,
    refetch,
  };
};
