import {
  PhoneNumber,
  CountryCode,
  NumberFormat,
  parsePhoneNumberFromString,
  AsYouType,
} from 'libphonenumber-js';
import { isValidPhoneNumber } from '../validation/phone.util';

const STANDARD_FORMAT: NumberFormat = 'E.164';

const parseAndValidatePhoneNumber = (
  phoneNumber?: string | null,
  defaultCountry?: CountryCode
): PhoneNumber | null => {
  const isValid = isValidPhoneNumber(phoneNumber, defaultCountry);

  if (!phoneNumber || !isValid) {
    return null;
  }

  const parsedPhoneNumber = parsePhoneNumberFromString(
    phoneNumber,
    defaultCountry
  );
  if (!parsedPhoneNumber) {
    return null;
  }

  return parsedPhoneNumber;
};

export const formatPhoneNumber = (
  phoneNumber?: string | null,
  defaultCountry?: CountryCode
): string => {
  const validPhoneNumber = parseAndValidatePhoneNumber(
    phoneNumber,
    defaultCountry
  );
  if (!validPhoneNumber) {
    const unformattedPhoneNumber = phoneNumber || '';
    return unformattedPhoneNumber;
  }

  return validPhoneNumber.formatInternational();
};

export const formatPhoneNumberAsYouType = (
  phoneNumber: string,
  defaultCountry?: CountryCode
): string => {
  const formatter = new AsYouType(defaultCountry);
  return formatter.input(phoneNumber);
};

export const standardizePhoneNumber = (
  phoneNumber: string,
  defaultCountry?: CountryCode
): string | null => {
  const validPhoneNumber = parseAndValidatePhoneNumber(
    phoneNumber,
    defaultCountry
  );
  if (!validPhoneNumber) {
    return null;
  }

  return validPhoneNumber.format(STANDARD_FORMAT);
};
