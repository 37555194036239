import { createContext, useContext, useState, ReactNode } from 'react';

interface GlobalPhoneContextType {
  isGlobalPhoneVisible: boolean;
  isGlobalPhoneEnabled: boolean;
  setGlobalPhoneVisible: (isVisible: boolean) => void;
  setGlobalPhoneEnabled: (isEnabled: boolean) => void;
}

const GlobalPhoneContext = createContext<GlobalPhoneContextType | undefined>(
  undefined
);

interface GlobalPhoneProviderProps {
  children: ReactNode;
}

export const GlobalPhoneProvider: React.FC<GlobalPhoneProviderProps> = ({
  children,
}) => {
  const [isGlobalPhoneVisible, setGlobalPhoneVisible] = useState(false);
  const [isGlobalPhoneEnabled, setGlobalPhoneEnabled] = useState(true);

  return (
    <GlobalPhoneContext.Provider
      value={{
        isGlobalPhoneVisible,
        isGlobalPhoneEnabled,
        setGlobalPhoneVisible,
        setGlobalPhoneEnabled,
      }}
    >
      {children}
    </GlobalPhoneContext.Provider>
  );
};

export const useGlobalPhoneContext = () => {
  const context = useContext(GlobalPhoneContext);
  if (!context) {
    throw new Error(
      'useGlobalPhoneContext must be used within a GlobalPhoneProvider'
    );
  }
  return context;
};
