/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type representing the allowed sort directions
 */
export enum SearcherDocumentSearchSortDirection {
    ASC = 'asc',
    DESC = 'desc',
}
