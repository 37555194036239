import { useRef, useCallback } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { type FunnelStatus } from 'src/api/generated';
import { useFunnelConversationDocuments } from '../api/funnels/funnel-conversations';
import { useInfiniteScroll } from 'src/modules/shared/hooks/scroll';
import {
  SearcherDocumentSearchFilterOperator,
  SearcherDocumentSearchSortDirection,
} from 'src/api/generated';

interface UseStatusColumnParams {
  status: FunnelStatus;
  selectedFunnelId?: number;
  searchTerm?: string;
}

interface UseStatusColumnReturn {
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  setRefs: (node: HTMLDivElement | null) => void;
  conversations: ReturnType<
    typeof useFunnelConversationDocuments
  >['conversations'];
  loading: boolean;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
}

export const useStatusColumnConversations = ({
  status,
  selectedFunnelId,
  searchTerm,
}: UseStatusColumnParams): UseStatusColumnReturn => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { setNodeRef } = useDroppable({
    id: status.id.toString(),
    data: {
      type: 'container',
      container: status,
      status,
    },
  });

  const setRefs = useCallback(
    (node: HTMLDivElement | null) => {
      setNodeRef(node);
      containerRef.current = node;
    },
    [setNodeRef]
  );

  const {
    conversations,
    loading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useFunnelConversationDocuments(
    {
      search: searchTerm,
      filters: [
        {
          field: 'funnelId',
          operator: SearcherDocumentSearchFilterOperator.EQ,
          value: selectedFunnelId?.toString(),
        },
        {
          field: 'funnelStatusId',
          operator: SearcherDocumentSearchFilterOperator.EQ,
          value: status.id.toString(),
        },
      ],
      sort: [
        {
          field: 'lastInteractionAt',
          direction: SearcherDocumentSearchSortDirection.DESC,
        },
      ],
      size: 20,
    },
    !!selectedFunnelId
  );

  useInfiniteScroll({
    containerRef,
    isFetching: isFetchingNextPage,
    hasMore: hasNextPage,
    fetchMore: () => fetchNextPage(),
  });

  return {
    containerRef,
    setRefs,
    conversations,
    loading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  };
};
