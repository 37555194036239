import { MdMic, MdMicOff } from 'react-icons/md';
import Button from '../../../buttons/button';
import { PhoneControlButtonProps } from '../types';

interface MuteButtonProps extends PhoneControlButtonProps {
  isMuted: boolean;
}

export const MuteButton: React.FC<MuteButtonProps> = ({
  onClick,
  isMuted,
  disabled,
  size = 'sm',
}) => {
  return (
    <Button
      variant="ghost"
      size={size}
      onClick={onClick}
      disabled={disabled}
      className={`rounded-full w-8 h-8 p-0 flex items-center justify-center ${
        isMuted ? 'bg-danger-50 hover:bg-danger-100 text-danger-500' : ''
      }`}
    >
      {isMuted ? (
        <MdMicOff className="w-4 h-4" />
      ) : (
        <MdMic className="w-4 h-4" />
      )}
    </Button>
  );
};
