import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  FunnelConversation,
  CrmFunnelsConversationsService,
  FunnelConversationDocument,
  FunnelConversationUpdateBodyParams,
} from 'src/api/generated';
import { getModelFromCache, updateCache } from 'src/utils/cache';

interface UpdateConversationStatusParams {
  id: number;
  data: FunnelConversationUpdateBodyParams;
}

interface UseUpdateConversationStatusMutationResponse {
  updateStatus: (
    params: UpdateConversationStatusParams
  ) => Promise<FunnelConversation>;
  loading: boolean;
  error: Error | null;
}

/**
 * Creates an optimistic conversation document for cache updates.
 */
const createOptimisticConversationDocument = (
  params: UpdateConversationStatusParams,
  previousDocument?: FunnelConversationDocument
): FunnelConversationDocument => {
  const {
    funnelStatusId,
    archivedAt,
    updatedAt,
    contactId,
    funnelId,
    ...updatedData
  } = params.data;

  const optimisticDocument: FunnelConversationDocument = {
    ...previousDocument,
    createdAt: previousDocument?.createdAt ?? new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    modelType: FunnelConversationDocument.modelType.CONVERSATION,
    funnelId: previousDocument?.funnelId,
    funnelStatusId: previousDocument?.funnelStatusId,
    contactId: previousDocument?.contactId,
    archivedAt: previousDocument?.archivedAt,
    ...updatedData,
    id: params.id.toString(),
    tenantId: previousDocument?.tenantId ?? params.data.tenantId ?? '',
  };

  if (funnelStatusId !== undefined) {
    optimisticDocument.funnelStatusId = funnelStatusId
      ? funnelStatusId.toString()
      : null;
  }
  if (archivedAt !== undefined) {
    optimisticDocument.archivedAt = archivedAt || undefined;
  }
  if (contactId !== undefined) {
    optimisticDocument.contactId = contactId.toString();
  }
  if (funnelId !== undefined) {
    optimisticDocument.funnelId = funnelId.toString();
  }

  return optimisticDocument;
};

/**
 * Creates an optimistic conversation for regular cache updates.
 */
const createOptimisticConversation = (
  params: UpdateConversationStatusParams,
  previousConversation?: FunnelConversation
): FunnelConversation => {
  const {
    funnelStatusId,
    archivedAt,
    updatedAt,
    contactId,
    funnelId,
    ...updatedData
  } = params.data;

  const optimisticConversation: FunnelConversation = {
    ...previousConversation,
    createdAt: previousConversation?.createdAt ?? new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    contactId: previousConversation?.contactId ?? 0,
    funnelId: previousConversation?.funnelId ?? 0,
    funnelStatusId: previousConversation?.funnelStatusId,
    archivedAt: previousConversation?.archivedAt,
    ...updatedData,
    id: params.id,
    tenantId: previousConversation?.tenantId ?? params.data.tenantId ?? '',
  };

  if (funnelStatusId !== undefined) {
    optimisticConversation.funnelStatusId = funnelStatusId;
  }
  if (archivedAt !== undefined) {
    optimisticConversation.archivedAt = archivedAt;
  }
  if (contactId !== undefined) {
    optimisticConversation.contactId = contactId;
  }
  if (funnelId !== undefined) {
    optimisticConversation.funnelId = funnelId;
  }

  return optimisticConversation;
};

export const useUpdateConversationStatusMutation = (
  onSuccess: (conversation: FunnelConversation) => void = () => {},
  onError: (error: Error) => void = () => {}
): UseUpdateConversationStatusMutationResponse => {
  const queryClient = useQueryClient();

  const updateConversationStatus = async (
    params: UpdateConversationStatusParams
  ): Promise<FunnelConversation> => {
    return await CrmFunnelsConversationsService.updateFunnelConversation(
      params.id,
      params.data
    );
  };

  const {
    mutateAsync,
    isPending: loading,
    error,
  } = useMutation({
    mutationFn: updateConversationStatus,
    onMutate: async (params) => {
      await queryClient.cancelQueries({
        queryKey: ['crm/documents/funnel-conversations'],
      });
      await queryClient.cancelQueries({
        queryKey: ['crm/funnel-conversations'],
      });

      const previousConversationDocument =
        getModelFromCache<FunnelConversationDocument>(
          queryClient,
          ['crm/documents/funnel-conversations'],
          params.id
        );
      const previousConversation = getModelFromCache<FunnelConversation>(
        queryClient,
        ['crm/funnel-conversations'],
        params.id
      );

      const optimisticConversationDocument =
        createOptimisticConversationDocument(
          params,
          previousConversationDocument
        );
      const optimisticConversation = createOptimisticConversation(
        params,
        previousConversation
      );

      updateCache({
        queryClient,
        queryKey: ['crm/documents/funnel-conversations'],
        updatedData: optimisticConversationDocument,
        shouldRemoveItem: (oldItem) =>
          oldItem.funnelStatusId !==
          optimisticConversationDocument.funnelStatusId,
        shouldAddItem: (matchedKey, newItem) => {
          if (!Array.isArray(matchedKey) || matchedKey.length !== 2) {
            return false;
          }

          const params = matchedKey[1] as {
            filters?: Array<{ field: string; operator: string; value: string }>;
          };
          if (!params?.filters) return false;

          const statusFilter = params.filters.find(
            (filter) =>
              filter.field === 'funnelStatusId' && filter.operator === 'eq'
          );
          if (!statusFilter) return false;

          return statusFilter.value === newItem.funnelStatusId;
        },
      });
      updateCache({
        queryClient,
        queryKey: ['crm/funnel-conversations'],
        updatedData: optimisticConversation,
        shouldRemoveItem: (oldItem) =>
          oldItem.funnelStatusId !== optimisticConversation.funnelStatusId,
      });
      updateCache({
        queryClient,
        queryKey: ['crm/funnel-conversations', params.id],
        updatedData: optimisticConversation,
        exact: true,
      });

      return {
        previousConversationDocument,
        previousConversation,
      };
    },
    onError: (error, variables, context) => {
      if (context?.previousConversationDocument) {
        updateCache({
          queryClient,
          queryKey: ['crm/documents/funnel-conversations'],
          updatedData: context.previousConversationDocument,
        });
      }

      if (context?.previousConversation) {
        updateCache({
          queryClient,
          queryKey: ['crm/funnel-conversations'],
          updatedData: context.previousConversation,
        });
        updateCache({
          queryClient,
          queryKey: ['crm/funnel-conversations', variables.id],
          updatedData: context.previousConversation,
          exact: true,
        });
      }

      onError(error);
    },
    onSuccess: (updatedConversation) => {
      onSuccess(updatedConversation);
    },
  });

  return {
    updateStatus: mutateAsync,
    loading,
    error,
  };
};
