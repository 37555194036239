// src/hooks/api/contacts/contacts.ts

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from '../api';
import {
  ApiQueryParams,
  Contact,
  ContactsService,
  ContactWithCalls,
  PaginatedData_Contact_,
  PaginatedData_ContactWithCalls_,
} from 'src/api/generated';
import { updateCache } from 'src/utils/cache';
import { CacheKeyValue } from 'src/types/cache';

interface ContactsResponse extends ApiResponse {
  contacts: Contact[];
  totalPages: number;
}

export const useContacts = (
  queryParams: ApiQueryParams = {}
): ContactsResponse => {
  const fetchContacts = async (): Promise<PaginatedData_Contact_> => {
    const { filterBy, searchBy, sortBy, page, pageSize } = queryParams;

    return await ContactsService.getContacts(
      filterBy,
      searchBy,
      sortBy,
      page,
      pageSize
    );
  };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['contacts', queryParams],
    queryFn: fetchContacts,
  });

  const contacts = useMemoizedArrayData<Contact>(data?.data);
  const totalPages = data?.totalPages || 1;

  return {
    loading,
    error,
    contacts,
    totalPages,
    refetch,
  };
};

interface ContactsWithCallsResponse extends ApiResponse {
  contacts: ContactWithCalls[];
  totalPages: number;
}

export const useContactsWithCalls = (
  queryParams: ApiQueryParams = {},
  enabled = true
): ContactsWithCallsResponse => {
  const fetchContactsWithCalls =
    async (): Promise<PaginatedData_ContactWithCalls_> => {
      const { filterBy, searchBy, sortBy, page, pageSize } = queryParams;

      return await ContactsService.getContactsWithCalls(
        filterBy,
        searchBy,
        sortBy,
        page,
        pageSize
      );
    };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['contacts-with-calls', queryParams],
    queryFn: fetchContactsWithCalls,
    enabled,
  });
  const contacts = useMemoizedArrayData<ContactWithCalls>(data?.data);
  const totalPages = data?.totalPages || 1;

  return {
    loading,
    error,
    contacts,
    totalPages,
    refetch,
  };
};

export const useOpportunitiesContacts = (
  queryParams: ApiQueryParams = {}
): ContactsWithCallsResponse => {
  const fetchOpportunitiesContacts =
    async (): Promise<PaginatedData_ContactWithCalls_> => {
      const { filterBy, searchBy, sortBy, page, pageSize } = queryParams;

      return await ContactsService.getOpportunitiesContacts(
        filterBy,
        searchBy,
        sortBy,
        page,
        pageSize
      );
    };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['opportunities-contacts', queryParams],
    queryFn: fetchOpportunitiesContacts,
  });
  const contacts = useMemoizedArrayData<ContactWithCalls>(data?.data);
  const totalPages = data?.totalPages || 1;

  return {
    loading,
    error,
    contacts,
    totalPages,
    refetch,
  };
};

interface UpdateContactParams {
  contactId: number;
  updatedContact: Partial<Contact>;
}

interface UpdateContactResponse {
  loading: boolean;
  error: Error | null;
  updateContact: (updateContactParams: UpdateContactParams) => void;
}

export const useUpdateContact = (
  onUpdateSuccess?: () => void,
  onUpdateError?: () => void,
  cacheKey: CacheKeyValue[] = []
): UpdateContactResponse => {
  const queryClient = useQueryClient();

  const updateContact = async ({
    contactId,
    updatedContact,
  }: UpdateContactParams): Promise<Contact> => {
    return await ContactsService.updateContact(contactId, updatedContact);
  };

  const mutation = useMutation({
    mutationFn: updateContact,
    onSuccess: (data: Contact) => {
      queryClient.invalidateQueries({ queryKey: ['contacts-with-calls'] });

      const queryKey = ['contacts', ...cacheKey];
      updateCache({
        queryClient,
        queryKey,
        updatedData: data,
      });

      onUpdateSuccess && onUpdateSuccess();
    },
    onError: () => onUpdateError && onUpdateError(),
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    updateContact: mutation.mutate,
  };
};
