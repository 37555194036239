import { motion } from 'framer-motion';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import LabelTextInput from 'src/modules/shared/components/atoms/inputs/label-text-input';
import { TimelineItemList } from '../../molecules/timeline-item-list';
import { useContact } from '../../../hooks/api/contacts';
import { useFunnelConversation } from '../../../hooks/api/funnels/funnel-conversations';
import { Panel } from 'src/modules/shared/components/molecules/panel';
import { createQuery } from 'src/modules/shared/utils/api/query-builder.util';
import { useContactAddresses } from '../../../hooks/api/contact-addresses';
import { ErrorState } from 'src/modules/shared/components/atoms/states/error-state';
import { ConversationHeader } from './conversation-header';
import { usePhoneContext } from 'src/contexts/phone-context';
import { useGlobalPhoneContext } from 'src/contexts/global-phone-context';
import { ContainerPhone } from 'src/modules/shared/components/organisms/phones/container-phone';
import STATES from 'states/index';
import { useMemo, useEffect } from 'react';
import { standardizePhoneNumber } from 'src/modules/shared/utils/formatting/phone.util';
import { useGlobalCallProviderContext } from 'src/contexts/global-call-provider-context';

interface ConversationDetailPanelProps {
  conversationId: number;
  onOpenProfile: (contactId: number) => void;
  onClose: () => void;
}

export const ConversationDetailPanel: React.FC<
  ConversationDetailPanelProps
> = ({ conversationId, onOpenProfile, onClose }) => {
  const {
    conversation,
    loading: loadingConversation,
    error: conversationError,
    refetch: refetchConversation,
  } = useFunnelConversation({
    id: conversationId,
  });

  const { contact, loading: loadingContact } = useContact(
    { id: conversation?.contactId ?? 0 },
    !!conversation?.contactId
  );

  const contactAddressesQuery = createQuery()
    .equals('contactId', contact?.id ?? 0)
    .orderByDesc('createdAt')
    .build();
  const { addresses, loading: loadingAddresses } = useContactAddresses(
    contactAddressesQuery,
    !!contact?.id
  );

  const callProvider = useGlobalCallProviderContext();
  const { setGlobalPhoneEnabled } = useGlobalPhoneContext();
  const { phoneState } = usePhoneContext();

  const handleCall = (phoneNumber: string) => {
    callProvider.callActions.startCall({ phoneNumber });
  };

  const shouldShowContainerPhone = useMemo(() => {
    if (phoneState === STATES.PHONE.READY) return false;
    if (!addresses?.length) return false;

    const currentNumber =
      phoneState === STATES.PHONE.INCOMING
        ? callProvider.callerNumber
        : callProvider.onCallNumber;

    if (!currentNumber) return false;

    const standardizedCurrentNumber = standardizePhoneNumber(currentNumber);
    if (!standardizedCurrentNumber) return false;

    return addresses.some(
      (addr) =>
        standardizePhoneNumber(addr.address) === standardizedCurrentNumber
    );
  }, [
    addresses,
    callProvider.callerNumber,
    callProvider.onCallNumber,
    phoneState,
  ]);

  useEffect(() => {
    setGlobalPhoneEnabled(!shouldShowContainerPhone);

    return () => {
      setGlobalPhoneEnabled(true);
    };
  }, [shouldShowContainerPhone, setGlobalPhoneEnabled]);

  if (conversationError) {
    return (
      <ErrorState
        title="Failed to load conversation"
        description={
          conversationError.message || 'An unexpected error occurred'
        }
        onAction={refetchConversation}
        variant="primary"
      />
    );
  }

  const isLoading = loadingConversation || loadingContact || loadingAddresses;

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ duration: 0.2 }}
      className="flex flex-col h-full bg-white"
    >
      <ConversationHeader
        isLoading={isLoading}
        contact={contact || undefined}
        addresses={addresses}
        funnelId={conversation?.funnelId || undefined}
        funnelStatusId={conversation?.funnelStatusId || undefined}
        onOpenProfile={onOpenProfile}
        onClose={onClose}
        onCall={handleCall}
      />

      <div className="flex-1 bg-white min-h-0">
        <Panel
          neumorphic="inset-sm"
          className="relative flex h-full rounded-none"
        >
          {shouldShowContainerPhone && (
            <ContainerPhone callProvider={callProvider} />
          )}
          <TimelineItemList
            contactId={contact?.id}
            loadingContact={loadingConversation || loadingContact}
          />
        </Panel>
      </div>

      <Panel neumorphic="flat" className="border-t border-gray-200">
        <form className="flex gap-2">
          <div className="flex-1">
            <LabelTextInput
              label="Message"
              labelPosition="top"
              inputProps={{
                className: 'w-full',
                // TODO: check when implementing i18n
                placeholder: 'Type your message...',
                disabled: isLoading,
              }}
            />
          </div>
          <Button
            type="submit"
            variant="primary"
            disabled={isLoading}
            className="self-end"
          >
            {/* TODO: check when implementing i18n */}
            Send
          </Button>
        </form>
      </Panel>
    </motion.div>
  );
};
