/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FunnelCreateBodyParams } from '../models/FunnelCreateBodyParams';
import type { FunnelCreateResponse } from '../models/FunnelCreateResponse';
import type { FunnelDeleteResponse } from '../models/FunnelDeleteResponse';
import type { FunnelGetResponse } from '../models/FunnelGetResponse';
import type { FunnelListResponse } from '../models/FunnelListResponse';
import type { FunnelUpdateBodyParams } from '../models/FunnelUpdateBodyParams';
import type { FunnelUpdateResponse } from '../models/FunnelUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CrmFunnelsService {

    /**
     * @param filterBy
     * @param searchBy
     * @param sortBy
     * @param page
     * @param pageSize
     * @param pageToken
     * @returns FunnelListResponse Ok
     * @throws ApiError
     */
    public static getFunnels(
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
        pageToken?: string,
    ): CancelablePromise<FunnelListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/crm/funnels',
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
                'pageToken': pageToken,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns FunnelCreateResponse Ok
     * @throws ApiError
     */
    public static createFunnel(
        requestBody: FunnelCreateBodyParams,
    ): CancelablePromise<FunnelCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/crm/funnels',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param id
     * @returns FunnelGetResponse Ok
     * @throws ApiError
     */
    public static getFunnel(
        id: number,
    ): CancelablePromise<FunnelGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/crm/funnels/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns FunnelUpdateResponse Ok
     * @throws ApiError
     */
    public static updateFunnel(
        id: number,
        requestBody: FunnelUpdateBodyParams,
    ): CancelablePromise<FunnelUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/crm/funnels/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param id
     * @returns FunnelDeleteResponse Ok
     * @throws ApiError
     */
    public static deleteFunnel(
        id: number,
    ): CancelablePromise<FunnelDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/crm/funnels/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

}
