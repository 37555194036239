/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FunnelStatusCreateBodyParams } from '../models/FunnelStatusCreateBodyParams';
import type { FunnelStatusCreateResponse } from '../models/FunnelStatusCreateResponse';
import type { FunnelStatusDeleteResponse } from '../models/FunnelStatusDeleteResponse';
import type { FunnelStatusGetResponse } from '../models/FunnelStatusGetResponse';
import type { FunnelStatusListResponse } from '../models/FunnelStatusListResponse';
import type { FunnelStatusUpdateBodyParams } from '../models/FunnelStatusUpdateBodyParams';
import type { FunnelStatusUpdateResponse } from '../models/FunnelStatusUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CrmFunnelsStatusesService {

    /**
     * @param filterBy
     * @param searchBy
     * @param sortBy
     * @param page
     * @param pageSize
     * @param pageToken
     * @returns FunnelStatusListResponse Ok
     * @throws ApiError
     */
    public static getFunnelStatuses(
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
        pageToken?: string,
    ): CancelablePromise<FunnelStatusListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/crm/funnel-statuses',
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
                'pageToken': pageToken,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns FunnelStatusCreateResponse Ok
     * @throws ApiError
     */
    public static createFunnelStatus(
        requestBody: FunnelStatusCreateBodyParams,
    ): CancelablePromise<FunnelStatusCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/crm/funnel-statuses',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param id
     * @returns FunnelStatusGetResponse Ok
     * @throws ApiError
     */
    public static getFunnelStatus(
        id: number,
    ): CancelablePromise<FunnelStatusGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/crm/funnel-statuses/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns FunnelStatusUpdateResponse Ok
     * @throws ApiError
     */
    public static updateFunnelStatus(
        id: number,
        requestBody: FunnelStatusUpdateBodyParams,
    ): CancelablePromise<FunnelStatusUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/crm/funnel-statuses/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param id
     * @returns FunnelStatusDeleteResponse Ok
     * @throws ApiError
     */
    public static deleteFunnelStatus(
        id: number,
    ): CancelablePromise<FunnelStatusDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/crm/funnel-statuses/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

}
