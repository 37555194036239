// src/modules/messaging/hooks/api/conversations.ts

import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from 'hooks/api/api';
import {
  Conversation,
  Alias_ConversationListQueryParams as ConversationsListParams,
  MessagingConversationsService,
  Alias_ConversationUpdateBodyParams as ConversationUpdateBodyParams,
} from 'src/api/generated';
import { ConversationsListResponse } from 'src/api/generated/models/ConversationsListResponse';
import { updateCache } from 'src/utils/cache';
import { CacheKeyValue } from 'src/types/cache';
import { SEC_IN_MS } from 'src/utils/date';

interface ConversationsResponse extends ApiResponse {
  conversations: Conversation[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
}

export const useConversations = (
  params: ConversationsListParams
): ConversationsResponse => {
  const fetchConversations = async ({
    pageParam,
  }: {
    pageParam: string | undefined;
  }): Promise<ConversationsListResponse> => {
    const { limit, status } = params;

    return await MessagingConversationsService.getConversations(
      limit,
      pageParam,
      status
    );
  };
  const getNextPageParam = (lastPage: ConversationsListResponse) =>
    lastPage.nextPageToken;

  const {
    data,
    error,
    isLoading: loading,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['messaging/conversations', params],
    queryFn: fetchConversations,
    getNextPageParam,
    initialPageParam: undefined as string | undefined,
    refetchInterval: SEC_IN_MS * 15,
  });

  const conversations = useMemoizedArrayData<Conversation>(
    data?.pages.flatMap((page) => page.items) || []
  );

  return {
    loading,
    error,
    conversations,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  };
};

interface UseUpdateConversationParams {
  onUpdateSuccess?: (data: Conversation) => void;
  onUpdateError?: (error: Error) => void;
  cacheKey?: CacheKeyValue[];
}

interface UseConversationUpdateParams extends ConversationUpdateBodyParams {
  conversationId: string;
}

interface UseUpdateConversationResponse {
  loading: boolean;
  error: Error | null;
  updatedConversation?: Conversation;
  updateConversation: (params: UseConversationUpdateParams) => void;
}

export const useUpdateConversation = ({
  onUpdateSuccess = () => {},
  onUpdateError = () => {},
  cacheKey = [],
}: UseUpdateConversationParams = {}): UseUpdateConversationResponse => {
  const queryClient = useQueryClient();

  const updateConversation = async ({
    conversationId,
    ...params
  }: UseConversationUpdateParams): Promise<Conversation> => {
    const { tenantId: _, ...conversationParams } = params;

    return MessagingConversationsService.updateConversation(
      conversationId,
      conversationParams
    );
  };

  const mutation = useMutation({
    mutationFn: updateConversation,
    onSuccess: (data: Conversation) => {
      const queryKey: CacheKeyValue[] = ['messaging/conversations'];
      if (cacheKey.length > 0) {
        queryKey.push(...cacheKey);
      }

      updateCache({
        queryClient,
        queryKey,
        updatedData: data,
      });

      onUpdateSuccess(data);
    },
    onError: (error: Error) => onUpdateError(error),
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    updatedConversation: mutation.data,
    updateConversation: mutation.mutate,
  };
};
