import { PlusIcon } from '@heroicons/react/24/outline';
import { FiFilter } from 'react-icons/fi';
import React from 'react';
import { ConversationStatus, MessageDirection } from 'src/api/generated';
import { getConversationStatusOptions } from 'src/modules/messaging/utils/conversations/status.util';
import { getMessageDirectionOptions } from 'src/modules/messaging/utils/messages/direction.util';
import { WhatsAppTemplateModal } from 'src/modules/messaging/whatsapp/components/organisms/templates/template-modal';
import LabelSelect from 'src/modules/shared/components/atoms/inputs/label-select';
import Button from 'src/modules/shared/components/atoms/buttons/button';

interface ChatActionsHeaderProps {
  selectedStatus: ConversationStatus | undefined;
  onStatusChange: (status?: ConversationStatus) => void;
  selectedDirection: MessageDirection | undefined;
  onDirectionChange: (direction?: MessageDirection) => void;
}

const containerClasses = `
  flex-none flex flex-col 2xl:flex-row overflow-hidden border-gray-200 
  p-3 items-center justify-between bg-white
  space-y-2 2xl:space-y-0 min-h-[4rem] 2xl:h-16
`;
const actionsContainerClasses =
  'flex items-center space-x-2 w-full justify-end flex-col 2xl:flex-row gap-1';

const ChatActionsHeader: React.FC<ChatActionsHeaderProps> = ({
  selectedStatus,
  onStatusChange,
  selectedDirection,
  onDirectionChange,
}) => {
  const [isWhatsAppTemplateModalOpen, setIsWhatsAppTemplateModalOpen] =
    React.useState(false);

  const handleStatusChange = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value as ConversationStatus | '';
      const updatedStatus = value === '' ? undefined : value;

      onStatusChange(updatedStatus);
    },
    [onStatusChange]
  );

  const handleDirectionChange = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value as MessageDirection | '';
      const updatedDirection = value === '' ? undefined : value;

      onDirectionChange(updatedDirection);
    },
    [onDirectionChange]
  );

  const statusOptions = React.useMemo(
    () =>
      getConversationStatusOptions({
        includeAllOption: true,
      }),
    []
  );

  const directionOptions = React.useMemo(
    () =>
      getMessageDirectionOptions({
        includeAllOption: true,
      }),
    []
  );

  return (
    <div className={containerClasses}>
      <div className={actionsContainerClasses}>
        <div className="flex w-full 2xl:max-w-64 flex-col 2xl:flex-row gap-1">
          <LabelSelect
            // TODO: check when implementing i18n
            label={
              <div className="flex items-center gap-1 justify-center w-full">
                <FiFilter className="h-3.5 w-3.5 -ml-3" />
                <span>Estado</span>
              </div>
            }
            labelPosition="top"
            options={statusOptions}
            size="sm"
            className="w-full sm:w-auto sm:max-w-64 flex-1"
            selectProps={{
              value: selectedStatus || '',
              onChange: handleStatusChange,
            }}
          />

          <LabelSelect
            // TODO: check when implementing i18n
            label={
              <div className="flex items-center gap-1 justify-center w-full">
                <FiFilter className="h-3.5 w-3.5 -ml-3" />
                <span>Último mensaje</span>
              </div>
            }
            labelPosition="top"
            options={directionOptions}
            size="sm"
            className="w-full sm:w-auto sm:max-w-64 flex-1"
            selectProps={{
              value: selectedDirection || '',
              onChange: handleDirectionChange,
            }}
          />
        </div>

        <Button
          size="sm"
          className="w-full 2xl:w-auto"
          onClick={() => setIsWhatsAppTemplateModalOpen(true)}
        >
          <PlusIcon className="h-3.5 w-3.5 mr-1" />
          {/* TODO: check when implementing i18n */}
          Mensaje
        </Button>

        <WhatsAppTemplateModal
          isOpen={isWhatsAppTemplateModalOpen}
          onClose={() => setIsWhatsAppTemplateModalOpen(false)}
        />
      </div>
    </div>
  );
};

export default ChatActionsHeader;
