import { type FC } from 'react';
import Button from '../button';
import { type BaseProps } from 'src/modules/shared/types/component.type';
import { ArrowPathIcon, ArrowDownIcon } from '@heroicons/react/24/outline';
import { StyledIcon } from '../../icons/styled-icon';

type LoadMoreButtonProps = Omit<BaseProps, 'children'> & {
  onClick: () => void;
};

export const LoadMoreButton: FC<LoadMoreButtonProps> = ({
  onClick,
  loading = false,
  className = '',
  variant = 'ghost',
  size = 'md',
  ...props
}) => {
  return (
    <Button
      onClick={onClick}
      loading={loading}
      variant={variant}
      size={size}
      className={`w-full ${className}`}
      {...props}
    >
      {loading ? (
        <StyledIcon
          icon={<ArrowPathIcon className="animate-spin" />}
          variant="ghost"
          size="sm"
        />
      ) : (
        <StyledIcon icon={<ArrowDownIcon />} size="sm" />
      )}
    </Button>
  );
};
