import {
  FilterCondition,
  FilterOperator,
  FilterValue,
  QueryParams,
  SortCondition,
  SortDirection,
} from 'src/modules/shared/types/api/query-builder.type';

/**
 * Builder class for constructing API query parameters in a fluent way
 * @example
 * const query = createQuery()
 *   .equals('status', 'active')
 *   .orderByDesc('createdAt')
 *   .take(10)
 *   .build();
 */
export class QueryBuilder {
  private filters: FilterCondition[] = [];
  private sorts: SortCondition[] = [];
  private searchTerm: string | undefined;
  private pageSize: number | undefined;
  private pageToken: string | undefined;
  private page: number | undefined;

  /**
   * Adds a filter condition with a custom operator
   * @param field - The field to filter on (supports nested fields with dot notation e.g. 'recording.callId')
   * @param operator - The comparison operator
   * @param value - The value to compare against
   * @returns The query builder instance for chaining
   */
  where(
    field: string,
    operator: FilterOperator,
    value: FilterValue | FilterValue[]
  ): QueryBuilder {
    this.filters.push({ field, operator, value });
    return this;
  }

  /**
   * Adds an equality filter condition
   * @param field - The field to filter on
   * @param value - The value to compare against
   * @returns The query builder instance for chaining
   */
  equals(field: string, value: FilterValue | FilterValue[]): QueryBuilder {
    return this.where(field, '=', value);
  }

  /**
   * Adds a greater than filter condition
   * @param field - The field to filter on
   * @param value - The value to compare against
   * @returns The query builder instance for chaining
   * @throws Error if value is an array
   */
  greaterThan(field: string, value: FilterValue): QueryBuilder {
    if (Array.isArray(value)) {
      throw new Error('Cannot use array values with greaterThan operator');
    }
    return this.where(field, '>', value);
  }

  /**
   * Adds a less than filter condition
   * @param field - The field to filter on
   * @param value - The value to compare against
   * @returns The query builder instance for chaining
   * @throws Error if value is an array
   */
  lessThan(field: string, value: FilterValue): QueryBuilder {
    if (Array.isArray(value)) {
      throw new Error('Cannot use array values with lessThan operator');
    }
    return this.where(field, '<', value);
  }

  /**
   * Adds a sort condition
   * @param field - The field to sort by
   * @param direction - The sort direction (defaults to 'asc')
   * @returns The query builder instance for chaining
   */
  orderBy(field: string, direction: SortDirection = 'asc'): QueryBuilder {
    this.sorts.push({ field, direction });
    return this;
  }

  /**
   * Adds a descending sort condition
   * @param field - The field to sort by
   * @returns The query builder instance for chaining
   */
  orderByDesc(field: string): QueryBuilder {
    return this.orderBy(field, 'desc');
  }

  /**
   * Adds an ascending sort condition
   * @param field - The field to sort by
   * @returns The query builder instance for chaining
   */
  orderByAsc(field: string): QueryBuilder {
    return this.orderBy(field, 'asc');
  }

  /**
   * Sets the page size for pagination
   * @param pageSize - Number of items per page
   * @returns The query builder instance for chaining
   */
  take(pageSize: number): QueryBuilder {
    this.pageSize = pageSize;
    return this;
  }

  /**
   * Sets the page token for cursor-based pagination
   * @param pageToken - Token for the next page
   * @returns The query builder instance for chaining
   */
  afterToken(pageToken: string): QueryBuilder {
    this.pageToken = pageToken;
    return this;
  }
  /**
   * Sets the page for pagination
   * @param pageNumber - Page number
   * @returns The query builder instance for chaining
   */
  setPage(pageNumber: number): QueryBuilder {
    this.page = pageNumber;
    return this;
  }

  /**
   * Sets the search term for text search
   * @param term - Text to search for
   * @returns The query builder instance for chaining
   */
  search(term: string): QueryBuilder {
    this.searchTerm = term;
    return this;
  }

  /**
   * Builds the filter string from the filter conditions
   * @returns Formatted filter string
   * @internal
   */
  private buildFilterString(): string {
    return this.filters
      .map(({ field, operator, value }) => {
        if (Array.isArray(value)) {
          const formattedArrayValues = value.map((item) =>
            typeof item === 'string' ? `"${item}"` : String(item)
          );
          const commaSeparatedArrayValues = formattedArrayValues.join(',');
          return `${field}${operator}[${commaSeparatedArrayValues}]`;
        }

        const formattedSingleValue =
          typeof value === 'string' ? `"${value}"` : String(value);
        return `${field}${operator}${formattedSingleValue}`;
      })
      .join(';');
  }

  /**
   * Builds the sort string from the sort conditions
   * @returns Formatted sort string
   * @internal
   */
  private buildSortString(): string {
    return this.sorts
      .map(({ field, direction }) => `${field}=${direction}`)
      .join(';');
  }

  /**
   * Builds the final query parameters object
   * @returns Query parameters object ready for API consumption
   */
  build(): QueryParams {
    const params: QueryParams = {};

    if (this.filters.length > 0) {
      params.filterBy = this.buildFilterString();
    }

    if (this.sorts.length > 0) {
      params.sortBy = this.buildSortString();
    }

    if (this.searchTerm) {
      params.searchBy = this.searchTerm;
    }

    if (this.pageSize) {
      params.pageSize = this.pageSize;
    }

    if (this.pageToken) {
      params.pageToken = this.pageToken;
    }

    if (this.page) {
      params.page = this.page;
    }

    return params;
  }
}

/**
 * Creates a new query builder instance
 * @returns A new QueryBuilder instance
 * @example
 * const query = createQuery()
 *   .equals('status', 'active')
 *   .orderByDesc('createdAt')
 *   .take(10)
 *   .build();
 */
export function createQuery(): QueryBuilder {
  return new QueryBuilder();
}
