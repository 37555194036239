import { CardSkeleton } from 'src/modules/shared/components/atoms/cards/card-skeleton';
import { type BaseProps } from 'src/modules/shared/types/component.type';

interface ConversationCardSkeletonProps extends BaseProps {
  showHeader?: boolean;
  showContent?: boolean;
}

export const ConversationCardSkeleton: React.FC<
  ConversationCardSkeletonProps
> = ({
  className = '',
  showHeader = true,
  showContent = true,
  variant = 'primary',
}) => {
  return (
    <CardSkeleton
      className={className}
      variant={variant}
      header={
        showHeader
          ? {
              title: true,
              subtitle: true,
              action: true,
            }
          : undefined
      }
      content={
        showContent
          ? {
              lines: 2,
              widths: ['75%', '50%'],
            }
          : undefined
      }
    />
  );
};
