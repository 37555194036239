import {
  SearcherTimelineItemDocument,
  CallDocument,
  MessageDocument,
} from 'src/api/generated';

/**
 * Configuration options for grouping timeline items
 */
interface GroupingOptions {
  /**
   * Maximum time difference in minutes between items to be grouped
   * @default 5
   */
  maxTimeWindowMinutes?: number;
}

/**
 * Represents a grouped timeline item with a main item and optional grouped items
 */
interface GroupedTimelineItem {
  mainItem: SearcherTimelineItemDocument;
  groupedItems?: SearcherTimelineItemDocument[];
}

/**
 * Type definition for a function that determines if two timeline items can be grouped
 */
type GroupingPredicate = (
  current: SearcherTimelineItemDocument,
  previous: SearcherTimelineItemDocument,
  options: GroupingOptions
) => boolean;

/**
 * Type guard to check if a timeline item is a CallDocument
 * @param item - The timeline item to check
 * @returns True if the item is a CallDocument
 */
const isCallItem = (item: SearcherTimelineItemDocument): item is CallDocument =>
  item.modelType === CallDocument.modelType.CALL;

/**
 * Type guard to check if a timeline item is a MessageDocument
 * @param item - The timeline item to check
 * @returns True if the item is a MessageDocument
 */
const isMessageItem = (
  item: SearcherTimelineItemDocument
): item is MessageDocument =>
  item.modelType === MessageDocument.modelType.MESSAGE;

/**
 * Determines if two call items can be grouped together
 * @param current - The current call item
 * @param previous - The previous call item
 * @returns True if the calls can be grouped
 */
const canGroupCalls = (
  current: CallDocument,
  previous: CallDocument
): boolean =>
  current.direction === previous.direction &&
  current.status === previous.status &&
  current.to === previous.to;

/**
 * Determines if two message items can be grouped together
 * @param current - The current message item
 * @param previous - The previous message item
 * @returns True if the messages can be grouped
 */
const canGroupMessages = (
  current: MessageDocument,
  previous: MessageDocument
): boolean =>
  current.direction === previous.direction &&
  current.sender.providerId === previous.sender.providerId &&
  !current.attachments?.length &&
  !previous.attachments?.length;

/**
 * Checks if two items are within the allowed time window
 */
const isWithinTimeWindow = (
  current: SearcherTimelineItemDocument,
  previous: SearcherTimelineItemDocument,
  { maxTimeWindowMinutes = 5 }: GroupingOptions
): boolean => {
  const currentTime = new Date(current.createdAt).getTime();
  const previousTime = new Date(previous.createdAt).getTime();
  const timeDifferenceMinutes =
    Math.abs(currentTime - previousTime) / (1000 * 60);

  return timeDifferenceMinutes <= maxTimeWindowMinutes;
};

/**
 * Determines if two timeline items can be grouped together based on their type, properties and time window
 */
const canGroupItems: GroupingPredicate = (current, previous, options) => {
  if (current.modelType !== previous.modelType) return false;

  if (!isWithinTimeWindow(current, previous, options)) return false;

  if (isCallItem(current) && isCallItem(previous)) {
    return canGroupCalls(current, previous);
  }

  if (isMessageItem(current) && isMessageItem(previous)) {
    return canGroupMessages(current, previous);
  }

  return false;
};

/**
 * Creates a grouped timeline item from an array of timeline items
 * @param items - Array of timeline items to group
 * @returns A grouped timeline item
 */
const createGroupedItem = (
  items: SearcherTimelineItemDocument[]
): GroupedTimelineItem => ({
  mainItem: items[0],
  ...(items.length > 1 && { groupedItems: items }),
});

/**
 * Groups sequential timeline items based on their type, properties and time window
 * @param items - Array of timeline items to group
 * @param options - Configuration options for grouping
 * @returns Array of grouped timeline items
 */
export const groupSequentialItems = (
  items: SearcherTimelineItemDocument[],
  options: GroupingOptions = {}
): GroupedTimelineItem[] => {
  if (!items.length) return [];

  return items.reduce<{
    groups: GroupedTimelineItem[];
    currentGroup: SearcherTimelineItemDocument[];
  }>(
    (acc, item, index) => {
      const previousItem = items[index - 1];
      const isFirstItem = index === 0;
      const shouldStartNewGroup =
        !isFirstItem && !canGroupItems(item, previousItem, options);

      if (shouldStartNewGroup) {
        acc.groups.push(createGroupedItem(acc.currentGroup));
        acc.currentGroup = [item];
      } else {
        acc.currentGroup.push(item);
      }

      if (index === items.length - 1) {
        acc.groups.push(createGroupedItem(acc.currentGroup));
      }

      return acc;
    },
    { groups: [], currentGroup: [] }
  ).groups;
};
