import { useMemo } from 'react';
import { startOfDay, compareAsc } from 'date-fns';

interface DateGroupedItems<T> {
  [key: string]: T[];
}

interface DateGroupable {
  createdAt: string | Date;
}

export function useDateGroupItems<T extends DateGroupable>(items: T[]) {
  const groupedItems = useMemo(() => {
    return items
      .slice()
      .reverse()
      .reduce((groups: DateGroupedItems<T>, item) => {
        const date = startOfDay(new Date(item.createdAt)).toISOString();
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(item);
        return groups;
      }, {});
  }, [items]);

  const sortedDates = useMemo(() => {
    return Object.keys(groupedItems).sort((a, b) =>
      compareAsc(new Date(a), new Date(b))
    );
  }, [groupedItems]);

  return {
    groupedItems,
    sortedDates,
  };
}
