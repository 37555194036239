/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FunnelConversationCreateBodyParams } from '../models/FunnelConversationCreateBodyParams';
import type { FunnelConversationCreateResponse } from '../models/FunnelConversationCreateResponse';
import type { FunnelConversationDeleteResponse } from '../models/FunnelConversationDeleteResponse';
import type { FunnelConversationGetResponse } from '../models/FunnelConversationGetResponse';
import type { FunnelConversationInteractionCreateBodyParams } from '../models/FunnelConversationInteractionCreateBodyParams';
import type { FunnelConversationInteractionCreateResponse } from '../models/FunnelConversationInteractionCreateResponse';
import type { FunnelConversationListResponse } from '../models/FunnelConversationListResponse';
import type { FunnelConversationSearchBodyParams } from '../models/FunnelConversationSearchBodyParams';
import type { FunnelConversationSearchResponse } from '../models/FunnelConversationSearchResponse';
import type { FunnelConversationUpdateBodyParams } from '../models/FunnelConversationUpdateBodyParams';
import type { FunnelConversationUpdateResponse } from '../models/FunnelConversationUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CrmFunnelsConversationsService {

    /**
     * @param requestBody
     * @returns FunnelConversationInteractionCreateResponse Ok
     * @throws ApiError
     */
    public static createFunnelConversationInteraction(
        requestBody: FunnelConversationInteractionCreateBodyParams,
    ): CancelablePromise<FunnelConversationInteractionCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/crm/funnel-conversation-interactions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param filterBy
     * @param searchBy
     * @param sortBy
     * @param page
     * @param pageSize
     * @param pageToken
     * @returns FunnelConversationListResponse Ok
     * @throws ApiError
     */
    public static getFunnelConversations(
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
        pageToken?: string,
    ): CancelablePromise<FunnelConversationListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/crm/funnel-conversations',
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
                'pageToken': pageToken,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns FunnelConversationCreateResponse Ok
     * @throws ApiError
     */
    public static createFunnelConversation(
        requestBody: FunnelConversationCreateBodyParams,
    ): CancelablePromise<FunnelConversationCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/crm/funnel-conversations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param id
     * @returns FunnelConversationGetResponse Ok
     * @throws ApiError
     */
    public static getFunnelConversation(
        id: number,
    ): CancelablePromise<FunnelConversationGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/crm/funnel-conversations/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns FunnelConversationUpdateResponse Ok
     * @throws ApiError
     */
    public static updateFunnelConversation(
        id: number,
        requestBody: FunnelConversationUpdateBodyParams,
    ): CancelablePromise<FunnelConversationUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/crm/funnel-conversations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param id
     * @returns FunnelConversationDeleteResponse Ok
     * @throws ApiError
     */
    public static deleteFunnelConversation(
        id: number,
    ): CancelablePromise<FunnelConversationDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/crm/funnel-conversations/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param id
     * @returns FunnelConversationUpdateResponse Ok
     * @throws ApiError
     */
    public static archiveFunnelConversation(
        id: number,
    ): CancelablePromise<FunnelConversationUpdateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/crm/funnel-conversations/{id}/archive',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns FunnelConversationSearchResponse Ok
     * @throws ApiError
     */
    public static searchFunnelConversations(
        requestBody: FunnelConversationSearchBodyParams,
    ): CancelablePromise<FunnelConversationSearchResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/crm/funnel-conversations/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

}
