import { useSearch } from '@tanstack/react-router';
import ViewSwitcher from '../../molecules/view-switcher';
import LabelTextInput from 'src/modules/shared/components/atoms/inputs/label-text-input';
import { useState } from 'react';
import FunnelSelector from '../../molecules/funnel-selector';
import { Funnel } from 'src/api/generated';
import { useDebounce } from '@uidotdev/usehooks';
import { Route as CommunicationsRoute } from 'src/routes/communications';
import ConversationListView from './lists/conversation-list-view';
import { ConversationPipelineView } from './pipelines/conversation-pipeline-view';
import { PanelHeader } from 'src/modules/shared/components/molecules/panel/panel-header';
import { Panel } from 'src/modules/shared/components/molecules/panel';
import { InteractionsActionsDropdown } from '../../molecules/interactions/actions-dropdown';

interface ConversationPanelProps {
  funnels: Funnel[];
  selectedFunnelId?: number;
  onFunnelSelect: (id: number) => void;
  onConversationSelect?: (id: number) => void;
  selectedConversationId?: number;
}

const ConversationPanel: React.FC<ConversationPanelProps> = ({
  funnels,
  selectedFunnelId,
  onFunnelSelect,
  onConversationSelect,
  selectedConversationId,
}) => {
  const { view } = useSearch({ from: CommunicationsRoute.id });
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearch = useDebounce(searchTerm, 300);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  // TODO: add a message to the user that they need to select or create a funnel first
  if (!selectedFunnelId) {
    return null;
  }

  return (
    <div className="flex flex-col h-full bg-white">
      <PanelHeader fixed size="lg" border={false}>
        <div className="flex items-center gap-3 min-w-0 w-full px-1">
          <InteractionsActionsDropdown />
          <ViewSwitcher showLabels={false} />
          <div className="w-40 flex-shrink-0">
            <FunnelSelector
              funnels={funnels}
              selectedFunnelId={selectedFunnelId}
              onFunnelSelect={onFunnelSelect}
              size="md"
            />
          </div>
          <div className="flex-1 min-w-0">
            <LabelTextInput
              label="Search"
              labelPosition="top"
              size="md"
              inputProps={{
                className: 'w-full',
                placeholder: 'Search conversations...',
                value: searchTerm,
                onChange: (e) => handleSearch(e.target.value),
              }}
            />
          </div>
        </div>
      </PanelHeader>
      {view === 'cards' && (
        <Panel
          neumorphic="inset-sm"
          className="flex-1 overflow-y-auto bg-white rounded-lg mx-3.5 p-2.5"
        >
          <ConversationListView
            selectedFunnelId={selectedFunnelId}
            searchTerm={debouncedSearch}
            onConversationSelect={onConversationSelect}
            selectedConversationId={selectedConversationId}
          />
        </Panel>
      )}
      {view === 'pipeline' && (
        <Panel
          neumorphic="flat"
          className="flex-1 overflow-y-auto bg-white rounded-none px-2"
        >
          <ConversationPipelineView
            selectedFunnelId={selectedFunnelId}
            searchTerm={debouncedSearch}
            onConversationSelect={onConversationSelect}
            selectedConversationId={selectedConversationId}
          />
        </Panel>
      )}
      {view === 'table' && (
        <Panel
          neumorphic="flat"
          className="flex-1 overflow-y-auto bg-white rounded-none"
        >
          <div>Table (Coming soon)</div>
        </Panel>
      )}
    </div>
  );
};

export default ConversationPanel;
