import { ComponentProps, forwardRef } from 'react';
import {
  BaseProps,
  ComponentSize,
} from 'src/modules/shared/types/component.type';
import {
  baseInputClasses,
  inputVariantClasses,
  inputSizeClasses,
  getDisabledClasses,
  getErrorClasses,
} from 'src/modules/shared/styles/component.styles';

interface TextInputProps
  extends BaseProps<Omit<ComponentProps<'input'>, 'size' | 'variant'>> {}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className = '',
      variant = 'primary',
      size = 'md',
      disabled = false,
      error,
      ...rest
    },
    ref
  ) => {
    const classes = [
      ...baseInputClasses,
      inputVariantClasses[variant],
      inputSizeClasses[size as ComponentSize],
      getDisabledClasses(disabled),
      getErrorClasses(!!error),
      'border',
      className,
    ].join(' ');

    return (
      <input
        ref={ref}
        type="text"
        className={classes}
        disabled={disabled}
        {...rest}
      />
    );
  }
);

TextInput.displayName = 'TextInput';

export default TextInput;
