/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CallStatus {
    QUEUED = 'queued',
    INITIATED = 'initiated',
    RINGING = 'ringing',
    IN_PROGRESS = 'in-progress',
    BUSY = 'busy',
    FAILED = 'failed',
    NO_ANSWER = 'no-answer',
    COMPLETED = 'completed',
}
