import { useMemo } from 'react';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import { PanelHeader } from 'src/modules/shared/components/molecules/panel/panel-header';
import { Avatar } from 'src/modules/shared/components/atoms/avatar';
import { formatFullName } from 'src/modules/shared/utils/formatting/name.util';
import Tag from 'src/modules/shared/components/atoms/tag';
import { MdCircle } from 'react-icons/md';
import { Typography } from '@material-tailwind/react';
import { Contact } from 'src/api/generated';
import { ContactAddress } from 'src/api/generated';
import { useFunnelStatuses } from '../../../hooks/api/funnels/funnel-statuses';
import { createQuery } from 'src/modules/shared/utils/api/query-builder.util';
import { HeaderSkeleton } from 'src/modules/shared/components/atoms/headers/header-skeleton';
import { ContactCallButton } from '../../molecules/buttons/contact-call-button';

interface ConversationHeaderProps {
  isLoading: boolean;
  contact?: Contact;
  addresses?: ContactAddress[];
  funnelId?: number;
  funnelStatusId?: number;
  onOpenProfile: (contactId: number) => void;
  onClose: () => void;
  onCall: (phoneNumber: string) => void;
}

export function ConversationHeader({
  isLoading,
  contact,
  addresses,
  funnelId,
  funnelStatusId,
  onOpenProfile,
  onClose,
  onCall,
}: ConversationHeaderProps) {
  const statusFilter = useMemo(() => {
    if (!funnelId) return undefined;

    const query = createQuery().equals('funnelId', funnelId).build();
    return query.filterBy;
  }, [funnelId]);

  const { statuses, loading: loadingStatuses } = useFunnelStatuses({
    filterBy: statusFilter,
  });

  const conversationName = useMemo(() => {
    if (isLoading) return 'Loading...';

    const firstAddress = addresses?.[0];

    return formatFullName(
      contact?.firstName,
      contact?.lastName,
      firstAddress?.address
    );
  }, [addresses, contact, isLoading]);

  const statusDisplay = useMemo(() => {
    if (loadingStatuses) {
      return <Tag text="Loading..." variant="neutral" size="sm" />;
    }

    const status = statuses?.find((status) => status.id === funnelStatusId);

    if (!status) return <></>;

    return (
      <Tag
        text={status.name.toLocaleLowerCase()}
        variant="info"
        size="sm"
        icon={<MdCircle className="w-2 h-2" />}
      />
    );
  }, [loadingStatuses, statuses, funnelStatusId]);

  return (
    <PanelHeader
      fixed
      size="lg"
      actions={
        <>
          <ContactCallButton
            addresses={addresses}
            onCall={onCall}
            disabled={isLoading}
          />
          {/* TODO: check when implementing i18n */}
          <Button
            variant="ghost"
            onClick={() => onOpenProfile(contact?.id ?? 0)}
            size="sm"
            disabled={isLoading}
          >
            View Profile
          </Button>
          <Button variant="ghost" size="sm" onClick={onClose}>
            Close
          </Button>
        </>
      }
    >
      {isLoading ? (
        <HeaderSkeleton actions={false} />
      ) : (
        <div className="flex items-center gap-3 min-w-0">
          <div
            className="cursor-pointer flex-shrink-0"
            onClick={() => onOpenProfile(contact?.id ?? 0)}
          >
            <Avatar
              text={conversationName.startsWith('+') ? '#' : conversationName}
              isOnline={false}
              size="md"
            />
          </div>
          <div className="flex flex-col gap-0.5 min-w-0">
            <Typography
              variant="h6"
              className="truncate cursor-pointer"
              onClick={() => onOpenProfile(contact?.id ?? 0)}
            >
              {conversationName}
            </Typography>
            <div>{statusDisplay}</div>
          </div>
        </div>
      )}
    </PanelHeader>
  );
}
