import { type FC, useMemo } from 'react';
import {
  rectIntersection,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import { type FunnelStatus } from 'src/api/generated';
import { DraggableBoard } from 'src/modules/shared/components/organisms/board';
import { PipelineStatusColumn } from '../../../../molecules/pipelines/status-column';
import { ConversationCard } from '../../../../molecules/conversations/card';
import { usePipelineDnd } from '../../../../../hooks/pipeline/board-dnd';

interface PipelineBoardProps {
  statuses: FunnelStatus[];
  selectedFunnelId?: number;
  searchTerm?: string;
  onConversationSelect?: (id: string) => void;
  selectedConversationId?: string;
  className?: string;
}

const dropAnimation = {
  duration: 200,
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

export const PipelineBoard: FC<PipelineBoardProps> = ({
  statuses,
  selectedFunnelId,
  searchTerm,
  onConversationSelect,
  selectedConversationId,
  className = '',
}) => {
  const {
    activeConversation,
    activeStatusId,
    shouldAnimate,
    isUpdating,
    sensors,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
  } = usePipelineDnd({
    onError: (error) => {
      console.error('Failed to update conversation status:', error);
    },
  });

  const statusColumns = useMemo(() => {
    if (!selectedFunnelId) return null;

    return (
      <div className="flex h-full min-h-0 bg-white">
        <div className="flex h-full">
          {statuses.map((status) => (
            <PipelineStatusColumn
              key={status.id}
              status={status}
              selectedFunnelId={selectedFunnelId}
              searchTerm={searchTerm}
              selectedConversationId={selectedConversationId}
              onConversationSelect={onConversationSelect}
              isHighlighted={activeStatusId === status.id.toString()}
            />
          ))}
        </div>
      </div>
    );
  }, [
    statuses,
    selectedFunnelId,
    searchTerm,
    selectedConversationId,
    onConversationSelect,
    activeStatusId,
  ]);

  if (!selectedFunnelId) {
    return (
      <div className="flex items-center justify-center h-full bg-gray-50">
        <p className="text-gray-500">Select a funnel to view conversations</p>
      </div>
    );
  }

  return (
    <DraggableBoard
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      collisionDetection={rectIntersection}
      dropAnimation={shouldAnimate ? dropAnimation : null}
      dragOverlay={
        activeConversation && (
          <div
            className={`transform-gpu will-change-transform w-[288px] opacity-80 grayscale ${
              isUpdating ? 'opacity-50' : ''
            }`}
            style={{
              cursor: 'grabbing',
              touchAction: 'none',
              pointerEvents: 'none',
            }}
          >
            <ConversationCard
              conversation={activeConversation}
              isSelected={selectedConversationId === activeConversation.id}
            />
          </div>
        )
      }
      className={className}
    >
      {statusColumns}
    </DraggableBoard>
  );
};
