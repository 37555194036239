/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContactCreateBodyParams } from '../models/ContactCreateBodyParams';
import type { ContactCreateResponse } from '../models/ContactCreateResponse';
import type { ContactDeleteResponse } from '../models/ContactDeleteResponse';
import type { ContactGetResponse } from '../models/ContactGetResponse';
import type { ContactListResponse } from '../models/ContactListResponse';
import type { ContactUpdateBodyParams } from '../models/ContactUpdateBodyParams';
import type { ContactUpdateResponse } from '../models/ContactUpdateResponse';
import type { SearcherDocumentSearchBodyParams } from '../models/SearcherDocumentSearchBodyParams';
import type { SearcherDocumentSearchResponse_ContactDocument_ } from '../models/SearcherDocumentSearchResponse_ContactDocument_';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CrmContactsService {

    /**
     * @param filterBy
     * @param searchBy
     * @param sortBy
     * @param page
     * @param pageSize
     * @param pageToken
     * @returns ContactListResponse Ok
     * @throws ApiError
     */
    public static getContacts(
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
        pageToken?: string,
    ): CancelablePromise<ContactListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/crm/contacts',
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
                'pageToken': pageToken,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns ContactCreateResponse Ok
     * @throws ApiError
     */
    public static createContact(
        requestBody: ContactCreateBodyParams,
    ): CancelablePromise<ContactCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/crm/contacts',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param id
     * @returns ContactGetResponse Ok
     * @throws ApiError
     */
    public static getContact(
        id: number,
    ): CancelablePromise<ContactGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/crm/contacts/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns ContactUpdateResponse Ok
     * @throws ApiError
     */
    public static updateContact(
        id: number,
        requestBody: ContactUpdateBodyParams,
    ): CancelablePromise<ContactUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/crm/contacts/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param id
     * @returns ContactDeleteResponse Ok
     * @throws ApiError
     */
    public static deleteContact(
        id: number,
    ): CancelablePromise<ContactDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/crm/contacts/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns SearcherDocumentSearchResponse_ContactDocument_ Ok
     * @throws ApiError
     */
    public static searchContacts(
        requestBody: SearcherDocumentSearchBodyParams,
    ): CancelablePromise<SearcherDocumentSearchResponse_ContactDocument_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/crm/contacts/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

}
