/**
 * Formats a duration in seconds into a human-readable string with minutes and seconds
 * @param seconds - The duration in seconds to format
 * @returns A formatted string in the format "X min Y seg"
 * @example
 * formatDuration(125) // returns "2 min 5 seg"
 */
export const formatDuration = (seconds: number) => {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${mins} min ${secs} seg`;
};
