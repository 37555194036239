import { type BaseProps } from 'src/modules/shared/types/component.type';

interface CardSkeletonProps extends BaseProps {
  header?: {
    avatar?: boolean;
    title?: boolean;
    subtitle?: boolean;
    action?: boolean;
  };
  content?: {
    lines?: number;
    widths?: string[];
  };
}

export const CardSkeleton: React.FC<CardSkeletonProps> = ({
  className = '',
  header,
  content,
  variant = 'primary',
}) => {
  const variantClasses = {
    primary: 'bg-gray-200',
    secondary: 'bg-neutral-200',
    tertiary: 'bg-tertiary-200',
    danger: 'bg-danger-200',
    ghost: 'bg-gray-200',
  };

  const bgClass = variantClasses[variant];

  return (
    <div
      className={`bg-white rounded-lg p-4 space-y-3 animate-pulse ${className}`}
    >
      {header && (
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            {header.avatar && (
              <div className={`w-10 h-10 ${bgClass} rounded-full`} />
            )}
            <div className="space-y-2">
              {header.title && (
                <div className={`h-4 w-32 ${bgClass} rounded`} />
              )}
              {header.subtitle && (
                <div className={`h-3 w-24 ${bgClass} rounded`} />
              )}
            </div>
          </div>
          {header.action && <div className={`h-6 w-6 ${bgClass} rounded`} />}
        </div>
      )}
      {content && (
        <div className="space-y-2">
          {[...Array(content.lines || 2)].map((_, index) => (
            <div
              key={index}
              className={`h-4 ${
                content.widths?.[index] || 'w-3/4'
              } ${bgClass} rounded`}
            />
          ))}
        </div>
      )}
    </div>
  );
};
