/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Partial_User_ } from '../models/Partial_User_';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * @param filterBy
     * @param searchBy
     * @param sortBy
     * @param page
     * @param pageSize
     * @param pageToken
     * @param tenantId
     * @returns User Ok
     * @throws ApiError
     */
    public static getUsers(
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
        pageToken?: string,
        tenantId?: string,
    ): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users',
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
                'pageToken': pageToken,
                'tenantId': tenantId,
            },
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @returns User Ok
     * @throws ApiError
     */
    public static getAgents(): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/agents',
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @returns User Ok
     * @throws ApiError
     */
    public static getAdmins(): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/admins',
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns User Ok
     * @throws ApiError
     */
    public static updateUser(
        id: number,
        requestBody: Partial_User_,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized.`,
                404: `Resource not found.`,
            },
        });
    }

    /**
     * @returns User Ok
     * @throws ApiError
     */
    public static getCurrentUser(): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/current',
            errors: {
                401: `Unauthorized.`,
            },
        });
    }

}
