import React from 'react';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import { ComponentVariant } from 'src/modules/shared/types/component.type';

interface ModalFooterProps {
  onCancel: () => void;
  onConfirm: () => void;
  cancelText?: string;
  confirmText?: string;
  confirmDisabled?: boolean;
  loading?: boolean;
  variant?: ComponentVariant;
}

export const ModalFooter: React.FC<ModalFooterProps> = ({
  onCancel,
  onConfirm,
  // TODO: check when implementing i18n
  cancelText = 'Cancelar',
  confirmText = 'Confirmar',
  confirmDisabled = false,
  loading = false,
  variant = 'primary',
}) => (
  <div className="flex justify-end space-x-2 rounded-lg shadow-sm py-2 md:py-4 px-4">
    <Button onClick={onCancel} variant="secondary" size="md">
      {cancelText}
    </Button>
    <Button
      onClick={onConfirm}
      disabled={confirmDisabled}
      loading={loading}
      size="md"
      variant={variant}
    >
      {/* TODO: check when implementing i18n */}
      {loading ? 'Cargando...' : confirmText}
    </Button>
  </div>
);

export default ModalFooter;
