import { useState } from 'react';
import { ContactAddress, ChannelType } from 'src/api/generated';
import { PencilIcon, TrashIcon, PlusIcon } from '@heroicons/react/24/outline';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import LabelTextInput from 'src/modules/shared/components/atoms/inputs/label-text-input';
import LabelSelect from 'src/modules/shared/components/atoms/inputs/label-select';
import { motion, AnimatePresence } from 'framer-motion';
import { useAlert } from 'src/contexts/alert-context';
import {
  useCreateContactAddressMutation,
  useUpdateContactAddressMutation,
  useDeleteContactAddressMutation,
} from 'src/modules/communications/hooks/api';
import Modal from 'src/modules/shared/components/molecules/modals/modal';
import ModalFooter from 'src/modules/shared/components/molecules/modals/modal-footer';
import { Typography } from '@material-tailwind/react';
import { ChannelIcon } from 'src/modules/shared/components/atoms/icons/channel-icon';

interface ContactAddressesSectionProps {
  addresses: ContactAddress[];
  loading?: boolean;
  contactId: number;
}

export const ContactAddressesSection: React.FC<
  ContactAddressesSectionProps
> = ({ addresses, loading = false, contactId }) => {
  const { showAlert } = useAlert();
  const [editingAddressId, setEditingAddressId] = useState<number | null>(null);
  const [isAddingAddress, setIsAddingAddress] = useState(false);
  const [deletingAddressId, setDeletingAddressId] = useState<number | null>(
    null
  );
  const [newAddress, setNewAddress] = useState({
    channelType: ChannelType.EMAIL,
    address: '',
  });
  const [editingAddress, setEditingAddress] = useState('');

  const { createAddress } = useCreateContactAddressMutation();
  const { updateAddress, loading: updating } =
    useUpdateContactAddressMutation();
  const { deleteAddress, loading: deletingAddress } =
    useDeleteContactAddressMutation();

  const handleAddAddress = async () => {
    try {
      await createAddress({
        contactId,
        ...newAddress,
      });
      setIsAddingAddress(false);
      setNewAddress({ channelType: ChannelType.EMAIL, address: '' });
      showAlert('Address added successfully', 'success');
    } catch (error) {
      console.error('Failed to add address:', error);
      showAlert('Failed to add address', 'error');
    }
  };

  const handleStartEditing = (address: ContactAddress) => {
    setEditingAddressId(address.id);
    setEditingAddress(address.address);
  };

  const handleCancelEditing = () => {
    setEditingAddressId(null);
    setEditingAddress('');
  };

  const handleUpdateAddress = async (addressId: number) => {
    if (!editingAddress.trim()) return;

    try {
      await updateAddress({ id: addressId, data: { address: editingAddress } });
      setEditingAddressId(null);
      setEditingAddress('');
      showAlert('Address updated successfully', 'success');
    } catch (error) {
      console.error('Failed to update address:', error);
      showAlert('Failed to update address', 'error');
    }
  };

  const handleDeleteAddress = async (addressId: number) => {
    try {
      await deleteAddress(addressId);
      setDeletingAddressId(null);
      showAlert('Address deleted successfully', 'success');
    } catch (error) {
      console.error('Failed to delete address:', error);
      showAlert('Failed to delete address', 'error');
    }
  };

  const renderLoadingState = () => (
    <div className="animate-pulse space-y-3">
      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
    </div>
  );

  const renderAddressItem = (address: ContactAddress) => (
    <motion.div
      key={address.id}
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      className="group relative flex items-center justify-between text-sm px-2 hover:bg-gray-50 rounded-lg"
    >
      {editingAddressId === address.id ? (
        <div className="flex-1 space-y-3">
          <LabelTextInput
            label="Address"
            labelPosition="top"
            size="sm"
            inputProps={{
              className: 'w-full',
              value: editingAddress,
              onChange: (e) => setEditingAddress(e.target.value),
              placeholder: 'Enter address...',
            }}
          />
          <div className="flex justify-end gap-2">
            <Button
              variant="ghost"
              size="sm"
              onClick={handleCancelEditing}
              disabled={updating}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={() => handleUpdateAddress(address.id)}
              disabled={
                updating ||
                !editingAddress.trim() ||
                editingAddress === address.address
              }
            >
              {/* TODO: check when implemnting i18n */}
              {updating ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className="flex items-center gap-2">
            <ChannelIcon channelType={address.channelType} />
            <div>
              <Typography variant="small" color="blue-gray">
                {address.address}
              </Typography>
            </div>
          </div>
          <div className="opacity-0 group-hover:opacity-100 transition-opacity flex items-center gap-2">
            <button
              onClick={() => handleStartEditing(address)}
              className="p-1 text-gray-500 hover:text-gray-700"
            >
              <PencilIcon className="w-4 h-4" />
            </button>
            <button
              onClick={() => setDeletingAddressId(address.id)}
              className="p-1 text-gray-500 hover:text-red-600"
            >
              <TrashIcon className="w-4 h-4" />
            </button>
          </div>
        </>
      )}
    </motion.div>
  );

  return (
    <>
      <div>
        {/* TODO: check when implemnting i18n */}
        <div className="flex items-center justify-between mb-4">
          <Typography variant="h6" color="blue-gray">
            Addresses
          </Typography>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => setIsAddingAddress(true)}
            className="flex items-center gap-1"
          >
            <PlusIcon className="w-4 h-4" />
            Add Address
          </Button>
        </div>

        <div className="space-y-3">
          {loading ? (
            <>
              {renderLoadingState()}
              {renderLoadingState()}
            </>
          ) : (
            <AnimatePresence>
              {isAddingAddress && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  className="border border-gray-200 rounded-lg p-4 mb-4"
                >
                  <div className="space-y-3">
                    <LabelSelect
                      label="Channel Type"
                      labelPosition="top"
                      size="xs"
                      options={Object.values(ChannelType).map((type) => ({
                        content: type,
                        value: type,
                      }))}
                      selectProps={{
                        value: newAddress.channelType,
                        onChange: (e) =>
                          setNewAddress((prev) => ({
                            ...prev,
                            channelType: e.target.value as ChannelType,
                          })),
                      }}
                    />
                    <LabelTextInput
                      label="Address"
                      labelPosition="top"
                      size="sm"
                      inputProps={{
                        className: 'w-full',
                        value: newAddress.address,
                        onChange: (e) =>
                          setNewAddress((prev) => ({
                            ...prev,
                            address: e.target.value,
                          })),
                        placeholder: 'Enter address...',
                      }}
                    />
                    <div className="flex justify-end gap-2">
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => {
                          setIsAddingAddress(false);
                          setNewAddress({
                            channelType: ChannelType.EMAIL,
                            address: '',
                          });
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={handleAddAddress}
                        disabled={!newAddress.address}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                </motion.div>
              )}
              {addresses.map(renderAddressItem)}
            </AnimatePresence>
          )}
        </div>
      </div>

      {/* TODO: check when implemnting i18n */}
      <Modal
        isOpen={!!deletingAddressId}
        onClose={() => setDeletingAddressId(null)}
        header={
          <Typography variant="h5" color="blue-gray">
            {/* TODO: check when implementing i18n */}
            Delete Address
          </Typography>
        }
        body={
          <Typography variant="paragraph" color="blue-gray">
            {/* TODO: check when implementing i18n */}
            Are you sure you want to delete this address? This action cannot be
            undone.
          </Typography>
        }
        footer={
          <ModalFooter
            onCancel={() => setDeletingAddressId(null)}
            onConfirm={() =>
              deletingAddressId && handleDeleteAddress(deletingAddressId)
            }
            cancelText="Cancel"
            confirmText="Delete"
            confirmDisabled={deletingAddress}
            loading={deletingAddress}
            variant="danger"
          />
        }
      />
    </>
  );
};
