import { useInfiniteQuery } from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from 'src/hooks/api/api';
import {
  Funnel,
  FunnelListResponse,
  CrmFunnelsService,
  FunnelListQueryParams,
} from 'src/api/generated';

interface UseFunnelsParams extends FunnelListQueryParams {}

interface UseFunnelsResponse extends ApiResponse {
  funnels: Funnel[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
}

export const useFunnels = (
  params: UseFunnelsParams = {}
): UseFunnelsResponse => {
  const fetchFunnels = async ({
    pageParam,
  }: {
    pageParam: string | undefined;
  }): Promise<FunnelListResponse> => {
    const { filterBy, searchBy, sortBy, page, pageSize } = params;

    return await CrmFunnelsService.getFunnels(
      filterBy,
      searchBy,
      sortBy,
      page,
      pageSize,
      pageParam
    );
  };

  const getNextPageParam = (lastPage: FunnelListResponse) =>
    lastPage.nextPageToken;

  const {
    data,
    error,
    isLoading: loading,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['crm/funnels', params],
    queryFn: fetchFunnels,
    getNextPageParam,
    initialPageParam: undefined,
  });

  const funnels = useMemoizedArrayData<Funnel>(
    data?.pages.flatMap((page) => page.items) || []
  );

  return {
    loading,
    error,
    funnels,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  };
};
