import React, { ComponentProps } from 'react';
import {
  BaseProps,
  ComponentSize,
} from 'src/modules/shared/types/component.type';
import Loading from 'src/modules/shared/components/atoms/loading';
import {
  variantClasses,
  getDisabledClasses,
} from 'src/modules/shared/styles/component.styles';

interface ButtonProps
  extends BaseProps<Omit<ComponentProps<'button'>, 'size' | 'variant'>> {}

const baseButtonClasses = [
  'inline-flex',
  'items-center',
  'justify-center',
  'gap-2',
  'font-medium',
  'rounded-md',
  'transition-all',
  'duration-200',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
];

const sizeClasses: Record<NonNullable<ComponentSize>, string> = {
  '2xs': 'h-4 px-1 text-2xs',
  xs: 'h-6 px-1.5 text-xs',
  sm: 'h-7 px-2 text-xs',
  md: 'h-8 px-3 text-sm',
  lg: 'h-10 px-4 text-base',
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className = '',
      variant = 'primary',
      size = 'md',
      disabled = false,
      loading = false,
      ...rest
    },
    ref
  ) => {
    const classes = [
      ...baseButtonClasses,
      variantClasses[variant],
      sizeClasses[size],
      getDisabledClasses(disabled || loading),
      className,
    ].join(' ');

    return (
      <button
        ref={ref}
        className={classes}
        disabled={disabled || loading}
        {...rest}
      >
        {loading ? (
          <>
            <Loading className="w-4 h-4" />
            {children}
          </>
        ) : (
          children
        )}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
