import { createContext, useContext, useMemo, ReactNode } from 'react';
import { CallCallbacks, CallProvider } from 'src/hooks/phones/types';
import { useTwilioCallProvider } from 'src/hooks/phones/providers/twilio/call.provider';
import { useBridgeCallProvider } from 'src/hooks/phones/providers/bridge/call.provider';
import { useBooleanSetting } from 'src/hooks/settings/settings';
import { SettingName } from 'src/api/generated';

interface ProviderContext {
  provider: CallProvider;
  parentCallbacks?: CallCallbacks;
}

const CallProviderContext = createContext<ProviderContext | null>(null);

interface GlobalCallProviderProps {
  children: ReactNode;
  callbacks?: CallCallbacks;
}

export function GlobalCallProvider({
  children,
  callbacks,
}: GlobalCallProviderProps) {
  const context = useContext(CallProviderContext);
  const { setting: callWithoutInternet } = useBooleanSetting(
    SettingName.CALL_WITHOUT_INTERNET
  );

  const mergedCallbacks = useMemo(() => {
    if (!callbacks) return context?.parentCallbacks;
    if (!context?.parentCallbacks) return callbacks;

    const parentCallbacks = context.parentCallbacks;
    return {
      onCallStarted: (call) => {
        parentCallbacks?.onCallStarted?.(call);
        callbacks.onCallStarted?.(call);
      },
      onCallEnded: (call) => {
        parentCallbacks?.onCallEnded?.(call);
        callbacks.onCallEnded?.(call);
      },
    };
  }, [callbacks, context?.parentCallbacks]);

  const twilioProvider = useTwilioCallProvider(mergedCallbacks);
  const bridgeProvider = useBridgeCallProvider(mergedCallbacks);

  const provider = useMemo(() => {
    return callWithoutInternet ? bridgeProvider : twilioProvider;
  }, [callWithoutInternet, bridgeProvider, twilioProvider]);

  const value = useMemo(
    () => ({
      provider,
      parentCallbacks: mergedCallbacks,
    }),
    [provider, mergedCallbacks]
  );

  return (
    <CallProviderContext.Provider value={value}>
      {children}
    </CallProviderContext.Provider>
  );
}

export function useGlobalCallProviderContext(): CallProvider {
  const context = useContext(CallProviderContext);
  if (!context) {
    throw new Error(
      'useCallProvider must be used within a CallProviderProvider'
    );
  }
  return context.provider;
}
