import { QueryClient, QueryFilters, QueryKey } from '@tanstack/react-query';
import { IModel, UpdateListCacheParams } from 'src/types/cache';

/**
 * Models that implement this interface can have an optional "updatedAt" property,
 * which is used to compare freshness in the cache.
 */
interface ITimestampedModel extends IModel {
  updatedAt?: string;
}

/**
 * Represents the data structure for infinite queries managed by React Query.
 */
export interface InfiniteQueryData<TModel> {
  pages: { items: TModel[] }[];
  pageParams: unknown[];
}

/**
 * Represents the data structure for regular (paginated) queries in React Query.
 */
export interface RegularQueryData<TModel> {
  count: number;
  data: TModel[];
  totalPages: number;
}

/**
 * Extended UpdateListCacheParams that allows conditional removal via "shouldRemoveItem",
 * and optional conditional addition via "shouldAddItem".
 */
export interface ExtendedUpdateListCacheParams<TModel extends IModel>
  extends UpdateListCacheParams<TModel> {
  shouldRemoveItem?: (oldItem: TModel) => boolean;
  shouldAddItem?: (matchedKey: QueryKey, newItem: TModel) => boolean;
}

/**
 * Gets a model from the cache by searching through all matching queries.
 * If multiple matches are found, returns the most recently updated one.
 *
 * @param queryClient - The query client instance used to manage the cache.
 * @param queryKey - The key used to identify the queries in the cache.
 * @param modelId - The ID of the model to find.
 * @returns The found model or undefined if not found.
 */
export const getModelFromCache = <TModel extends ITimestampedModel>(
  queryClient: QueryClient,
  queryKey: unknown[],
  modelId: string | number
): TModel | undefined => {
  const queriesData = queryClient.getQueriesData<
    InfiniteQueryData<TModel> | RegularQueryData<TModel> | TModel
  >({
    queryKey,
    exact: false,
  });

  return queriesData.reduce<TModel | undefined>(
    (previouslyFoundModel, [, data]) => {
      if (!data) return previouslyFoundModel;

      let modelInCurrentQuery: TModel | undefined;

      if (isInfiniteQueryData<TModel>(data)) {
        for (const page of data.pages) {
          const found = page.items.find(
            (item) => item.id.toString() === modelId.toString()
          );
          if (found) {
            modelInCurrentQuery = found;
            break;
          }
        }
      }

      if (isRegularQueryData<TModel>(data)) {
        modelInCurrentQuery = data.data.find(
          (item) => item.id.toString() === modelId.toString()
        );
      }

      if (isSingleModelQueryData<TModel>(data)) {
        modelInCurrentQuery = data;
      }

      if (!modelInCurrentQuery) return previouslyFoundModel;
      if (!previouslyFoundModel) return modelInCurrentQuery;

      const currentUpdatedAt = modelInCurrentQuery.updatedAt;
      const previousUpdatedAt = previouslyFoundModel.updatedAt;
      if (currentUpdatedAt && previousUpdatedAt) {
        return new Date(currentUpdatedAt) > new Date(previousUpdatedAt)
          ? modelInCurrentQuery
          : previouslyFoundModel;
      }

      return modelInCurrentQuery;
    },
    undefined
  );
};

/**
 * Updates or removes (or optionally adds) an item to every matching query in the cache.
 * If "exact" is true, only the exact query key is updated. For list queries,
 * you may also provide "shouldAddItem" to control whether the updated item should be added
 * to a query that does not yet have it.
 *
 * @param queryClient - The QueryClient instance
 * @param queryKey - The key identifying queries to be updated
 * @param updatedData - The new or updated item
 * @param shouldRemoveItem - If returns true, removes the item from the query
 * @param shouldAddItem - If returns true, adds the item if it doesn't exist
 * @param exact - Whether to match queryKey exactly or not
 */
export const updateCache = <TModel extends IModel>({
  queryClient,
  queryKey,
  updatedData,
  shouldRemoveItem = () => false,
  shouldAddItem,
  exact = false,
}: ExtendedUpdateListCacheParams<TModel> & { exact?: boolean }) => {
  const filters: QueryFilters = {
    queryKey,
    exact,
  };

  const matchedQueries = queryClient.getQueriesData<
    InfiniteQueryData<TModel> | RegularQueryData<TModel> | TModel
  >(filters);

  matchedQueries.forEach(([matchedKey, oldData]) => {
    const newData = updateEntry(
      matchedKey,
      oldData,
      updatedData,
      shouldRemoveItem,
      shouldAddItem
    );

    queryClient.setQueryData(matchedKey, newData);
  });
};

/**
 * Type guard checking if the provided data is InfiniteQueryData.
 */
const isInfiniteQueryData = <TModel>(
  data: unknown
): data is InfiniteQueryData<TModel> => {
  return (
    typeof data === 'object' &&
    data !== null &&
    'pages' in data &&
    Array.isArray((data as InfiniteQueryData<TModel>).pages)
  );
};

/**
 * Type guard checking if the provided data is RegularQueryData.
 */
const isRegularQueryData = <TModel>(
  data: unknown
): data is RegularQueryData<TModel> => {
  return (
    typeof data === 'object' &&
    data !== null &&
    'data' in data &&
    Array.isArray((data as RegularQueryData<TModel>).data)
  );
};

/**
 * Type guard checking if the provided data is a single Model.
 */
const isSingleModelQueryData = <TModel>(data: unknown): data is TModel => {
  return typeof data === 'object' && data !== null && 'id' in data;
};

/**
 * Updates page-based (infinite) query data by removing or updating the matching item,
 * or optionally adding the updated item if it does not yet exist and shouldAddItem is true.
 *
 * @param oldData - The existing InfiniteQueryData in the cache
 * @param updatedData - The item to update or add
 * @param shouldRemoveItem - If returns true for a matching item, it will be removed
 * @param shouldAddItem - If returns true, the updated item will be added if not found
 */
const updateInfiniteQueryCache = <TModel extends IModel>(
  matchedKey: QueryKey,
  oldData: InfiniteQueryData<TModel>,
  updatedData: TModel,
  shouldRemoveItem: (oldItem: TModel) => boolean = () => false,
  shouldAddItem?: (matchedKey: QueryKey, newItem: TModel) => boolean
): InfiniteQueryData<TModel> => {
  let itemWasRemoved = false;

  const newPages = oldData.pages.map((page) => {
    const updatedItems = page.items.map((item) => {
      const isSameItem = item.id.toString() === updatedData.id.toString();
      if (isSameItem) {
        if (shouldRemoveItem(item)) {
          itemWasRemoved = true;
          return null;
        }
        return { ...item, ...updatedData };
      }

      return item;
    });

    return {
      ...page,
      items: updatedItems.filter((item) => item !== null),
    };
  });

  const itemExists = newPages.some((page) =>
    page.items.some((item) => item.id.toString() === updatedData.id.toString())
  );

  const shouldAdd =
    !itemExists &&
    newPages.length > 0 &&
    !itemWasRemoved &&
    shouldAddItem?.(matchedKey, updatedData);
  if (shouldAdd) {
    newPages[0].items = [updatedData, ...newPages[0].items];
  }

  return {
    ...oldData,
    pages: newPages,
  };
};

/**
 * Updates a paginated (regular) query by removing or updating the matching item,
 * or optionally adding the updated item if it does not yet exist and shouldAddItem is true.
 *
 * @param oldData - The existing RegularQueryData in the cache
 * @param updatedData - The item to update or add
 * @param shouldRemoveItem - If returns true for a matching item, it will be removed
 * @param shouldAddItem - If returns true, the updated item will be added if not found
 */
const updateRegularQueryCache = <TModel extends IModel>(
  matchedKey: QueryKey,
  oldData: RegularQueryData<TModel>,
  updatedData: TModel,
  shouldRemoveItem: (oldItem: TModel) => boolean,
  shouldAddItem?: (matchedKey: QueryKey, newItem: TModel) => boolean
): RegularQueryData<TModel> => {
  const newDataArray = [...oldData.data];
  let newCount = oldData.count;

  const index = newDataArray.findIndex(
    (item) => item.id.toString() === updatedData.id.toString()
  );
  const itemExists = index !== -1;

  if (itemExists) {
    if (shouldRemoveItem(newDataArray[index])) {
      newDataArray.splice(index, 1);
      newCount -= 1;
    } else {
      newDataArray[index] = { ...newDataArray[index], ...updatedData };
    }
  } else if (shouldAddItem?.(matchedKey, updatedData)) {
    newDataArray.unshift(updatedData);
    newCount += 1;
  }

  return {
    ...oldData,
    data: newDataArray,
    count: newCount,
  };
};

/**
 * Updates a single model in the cache for the exact queryKey.
 *
 * @param queryClient - The QueryClient instance
 * @param queryKey - The exact query key
 * @param updatedData - The new data to set
 */
export const updateModelCache = <TModel extends IModel>(
  queryClient: QueryClient,
  queryKey: unknown[],
  updatedData: TModel
): void => {
  queryClient.setQueryData<TModel>(queryKey, (oldData) => {
    if (!oldData) return updatedData;

    return { ...oldData, ...updatedData };
  });
};

/**
 * Retrieves a single model from the cache for the exact queryKey, without searching all queries.
 *
 * @param queryClient - The query client instance managing the cache.
 * @param queryKey - The exact query key for the record.
 * @returns The model if found in the specific query, undefined otherwise.
 */
export const getModelFromQuery = <TModel extends IModel>(
  queryClient: QueryClient,
  queryKey: unknown[]
): TModel | undefined => {
  return queryClient.getQueryData<TModel>(queryKey);
};

/**
 * Helper that merges or removes/adds the updated item depending on data type.
 */
const updateEntry = <TModel extends IModel>(
  matchedKey: QueryKey,
  oldData:
    | InfiniteQueryData<TModel>
    | RegularQueryData<TModel>
    | TModel
    | undefined,
  updatedData: TModel,
  shouldRemoveItem: (oldItem: TModel) => boolean,
  shouldAddItem?: (matchedKey: QueryKey, newItem: TModel) => boolean
):
  | InfiniteQueryData<TModel>
  | RegularQueryData<TModel>
  | TModel
  | undefined => {
  if (!oldData) return undefined;

  if (isInfiniteQueryData<TModel>(oldData)) {
    return updateInfiniteQueryCache(
      matchedKey,
      oldData,
      updatedData,
      shouldRemoveItem,
      shouldAddItem
    );
  }
  if (isRegularQueryData<TModel>(oldData)) {
    return updateRegularQueryCache(
      matchedKey,
      oldData,
      updatedData,
      shouldRemoveItem,
      shouldAddItem
    );
  }
  if (isSingleModelQueryData<TModel>(oldData)) {
    if (shouldRemoveItem(oldData)) {
      return undefined;
    }
    return { ...oldData, ...updatedData };
  }
  return oldData;
};
