import { useEffect, useState, useRef } from 'react';
import { CallProvider } from 'src/hooks/phones/types';
import { formatDuration } from 'src/modules/shared/utils/formatting/time.util';
import { motion } from 'framer-motion';
import { Typography } from '@material-tailwind/react';
import { formatPhoneNumberAsYouType } from 'src/modules/shared/utils/formatting/phone.util';
import STATES from 'states/index';
import { usePhoneContext } from 'src/contexts/phone-context';
import { useGlobalPhoneContext } from 'src/contexts/global-phone-context';
import { Avatar } from 'src/modules/shared/components/atoms/avatar';
import { SettingsButton } from '../../../atoms/phones/buttons/settings-button';
import { EndCallButton } from '../../../atoms/phones/buttons/end-call-button';
import { MuteButton } from '../../../atoms/phones/buttons/mute-button';
import { AcceptCallButton } from '../../../atoms/phones/buttons/accept-call-button';

// type Position = PanInfo['point'];

interface FloatingPhoneProps {
  callProvider: CallProvider;
}

const phoneWrapperClasses = [
  'absolute top-1/2 left-1/2',
  '-translate-x-1/2 -translate-y-1/2',
  'pointer-events-auto',
].join(' ');
const phoneContentClasses = [
  'bg-white rounded-lg shadow-nm-floating-lg',
  'p-6 flex flex-col items-center',
  'gap-6 justify-between',
  'min-w-[180px] min-h-[200px]',
].join(' ');
const callControlsClasses = 'flex items-center gap-4';

export const FloatingPhone: React.FC<FloatingPhoneProps> = ({
  callProvider: {
    callActions,
    loading: callProviderLoading,
    error: callProviderError,
    onCallNumber,
    callerNumber,
  },
}) => {
  const [duration, setDuration] = useState(0);
  const { phoneState, isMuted, setIsMuted } = usePhoneContext();
  const { isGlobalPhoneVisible, isGlobalPhoneEnabled, setGlobalPhoneVisible } =
    useGlobalPhoneContext();
  // const [position, setPosition] = useState<Position>({ x: 0, y: 0 });
  const constraintsRef = useRef<HTMLDivElement>(null);
  const phoneRef = useRef<HTMLDivElement>(null);
  // const dragControls = useDragControls();

  // TODO: request the contact data from the backend
  // const { contact, loading: loadingContact } = useContacts({
  //   ...
  // });
  const contactName = null;

  useEffect(() => {
    if (phoneState === STATES.PHONE.INCOMING) {
      setGlobalPhoneVisible(true);
    }
  }, [phoneState, isGlobalPhoneEnabled, setGlobalPhoneVisible]);

  useEffect(() => {
    if (phoneState !== STATES.PHONE.ON_CALL) return;

    const timer = setInterval(() => {
      setDuration((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [phoneState]);

  useEffect(() => {
    if (phoneState !== STATES.PHONE.ON_CALL) {
      setDuration(0);
    }
  }, [phoneState]);

  if (!isGlobalPhoneEnabled || !isGlobalPhoneVisible) return null;

  if (
    phoneState === STATES.PHONE.READY ||
    phoneState === STATES.PHONE.REGISTERING
  ) {
    return null;
  }

  if (callProviderLoading || callProviderError) return null;

  const handleMute = () => {
    const newMutedState = !isMuted;
    setIsMuted(newMutedState);
    callActions.muteCall(newMutedState);
  };

  // const handleDragEnd = (
  //   _: MouseEvent | TouchEvent | PointerEvent,
  //   info: PanInfo
  // ) => {
  //   setPosition({
  //     x: position.x + Math.round(info.offset.x),
  //     y: position.y + Math.round(info.offset.y),
  //   });
  // };

  const currentNumber =
    phoneState === STATES.PHONE.INCOMING ? callerNumber : onCallNumber;
  const displayNumber = currentNumber
    ? formatPhoneNumberAsYouType(currentNumber)
    : '';

  const renderCallControls = () => {
    if (phoneState === STATES.PHONE.INCOMING) {
      return (
        <div className={callControlsClasses}>
          <AcceptCallButton onClick={callActions.acceptCall} />
          <EndCallButton onClick={callActions.rejectCall} />
        </div>
      );
    }

    return (
      <div className={callControlsClasses}>
        <MuteButton onClick={handleMute} isMuted={isMuted} />
        <EndCallButton onClick={callActions.endCall} />
        <SettingsButton disabled onClick={() => {}} />
      </div>
    );
  };

  return (
    <div ref={constraintsRef} className="fixed inset-0 pointer-events-none">
      <motion.div
        ref={phoneRef}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        drag
        dragMomentum={false}
        dragConstraints={constraintsRef}
        // onDragEnd={handleDragEnd}
        // style={{ x: position.x, y: position.y }}
        className={phoneWrapperClasses}
        dragListener={false}
        // dragControls={dragControls}
      >
        <div className={phoneContentClasses}>
          <div className="flex flex-col items-center gap-2">
            <div className="flex items-center gap-2">
              <Avatar text={contactName || displayNumber} size="lg" />
              {/* <motion.button
                onPointerDown={(e) => {
                  e.preventDefault();
                  dragControls.start(e);
                }}
                className="p-1 rounded hover:bg-gray-100 cursor-grab active:cursor-grabbing"
              >
                <MdDragIndicator className="w-6 h-6 text-gray-400" />
              </motion.button> */}
            </div>
            <div className="flex flex-col items-center">
              {contactName && (
                <Typography className="text-lg font-medium text-gray-900">
                  {contactName}
                </Typography>
              )}
              <Typography
                className={`text-sm ${
                  contactName ? 'text-gray-500' : 'text-gray-900 font-medium'
                }`}
              >
                {displayNumber}
              </Typography>
              {phoneState === STATES.PHONE.ON_CALL && (
                <Typography className="text-sm text-gray-500">
                  {formatDuration(duration)}
                </Typography>
              )}
              {phoneState === STATES.PHONE.INCOMING && (
                <Typography className="text-sm text-gray-500">
                  {/* TODO: check when implementing i18n */}
                  Incoming call...
                </Typography>
              )}
            </div>
          </div>

          {renderCallControls()}
        </div>
      </motion.div>
    </div>
  );
};
