/**
 * Formats a full name by combining first and last names
 * @param firstName - The first name of the person, can be undefined or null
 * @param lastName - The last name of the person, can be undefined or null
 * @param fallback - Optional fallback value if both names are null/empty
 * @returns A formatted string containing the full name with proper spacing, or fallback if no name
 */
export const formatFullName = (
  firstName?: string | null,
  lastName?: string | null,
  fallback: string = ''
) => {
  const first = formatName(firstName);
  const last = formatName(lastName);
  const fullName = `${first} ${last}`.trim();
  return fullName || fallback;
};

/**
 * Formats a single name component by trimming whitespace
 * @param name - The name string to format, can be undefined or null
 * @returns A trimmed name string or empty string if input is undefined/null
 */
export const formatName = (name?: string | null) => {
  return name?.trim() || '';
};
