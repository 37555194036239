import { type FC, type ReactNode } from 'react';
import {
  NeumorphicStyle,
  type BaseProps,
} from 'src/modules/shared/types/component.type';

interface PanelProps extends BaseProps {
  children: ReactNode;
  isHighlighted?: boolean;
}

export const Panel: FC<PanelProps> = ({
  children,
  neumorphic = 'flat',
  isHighlighted,
  className = '',
}) => {
  const baseClasses = 'rounded-lg transition-all duration-200';

  const neumorphicClasses: Record<NeumorphicStyle, string> = {
    inset: 'shadow-nm-inset',
    'inset-sm': 'shadow-nm-inset-2xs',
    flat: 'shadow-nm-flat',
  };

  const backgroundClass = isHighlighted
    ? 'bg-blue-50'
    : neumorphic === 'flat'
      ? 'bg-white'
      : 'bg-gradient-light';

  const neumorphicClass = neumorphicClasses[neumorphic];

  const combinedClasses = [
    baseClasses,
    neumorphicClass,
    backgroundClass,
    className,
  ].join(' ');

  return <div className={combinedClasses}>{children}</div>;
};
