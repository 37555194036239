import { Spinner } from '@material-tailwind/react';
import {
  BaseProps,
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';

interface LoadingProps extends BaseProps {
  className?: string;
}

const sizeClasses: Record<NonNullable<ComponentSize>, string> = {
  '2xs': 'h-3 w-3',
  xs: 'h-4 w-4',
  sm: 'h-5 w-5',
  md: 'h-6 w-6',
  lg: 'h-8 w-8',
};

const variantClasses: Record<NonNullable<ComponentVariant>, string> = {
  primary: 'text-primary-600',
  secondary: 'text-secondary-500',
  tertiary: 'text-tertiary-700',
  danger: 'text-danger-500',
  ghost: 'text-neutral-600',
};

const Loading: React.FC<LoadingProps> = ({
  className = '',
  variant = 'primary',
  size = 'md',
}) => {
  const classes = [
    'flex w-full justify-center',
    sizeClasses[size],
    variantClasses[variant],
    className,
  ].join(' ');

  return (
    <div className={classes}>
      <Spinner className={sizeClasses[size]} />
    </div>
  );
};

export default Loading;
