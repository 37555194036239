import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from 'src/hooks/api/api';
import {
  FunnelConversation,
  FunnelConversationListResponse,
  FunnelConversationSearchResponse,
  FunnelConversationSearchBodyParams,
  CrmFunnelsConversationsService,
  FunnelConversationListQueryParams,
  FunnelConversationDocument,
} from 'src/api/generated';

interface UseFunnelConversationsParams
  extends FunnelConversationListQueryParams {}

interface UseFunnelConversationsResponse extends ApiResponse {
  conversations: FunnelConversation[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
}

export const useFunnelConversations = (
  params: UseFunnelConversationsParams,
  enabled: boolean = true
): UseFunnelConversationsResponse => {
  const fetchConversations = async ({
    pageParam,
  }: {
    pageParam: string | undefined;
  }): Promise<FunnelConversationListResponse> => {
    const { filterBy, searchBy, sortBy, page, pageSize } = params;

    return await CrmFunnelsConversationsService.getFunnelConversations(
      filterBy,
      searchBy,
      sortBy,
      page,
      pageSize,
      pageParam
    );
  };

  const getNextPageParam = (lastPage: FunnelConversationListResponse) =>
    lastPage.nextPageToken;

  const {
    data,
    error,
    isLoading: loading,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['crm/funnel-conversations', params],
    queryFn: fetchConversations,
    getNextPageParam,
    initialPageParam: undefined,
    enabled,
  });

  const conversations = useMemoizedArrayData<FunnelConversation>(
    data?.pages.flatMap((page) => page.items) || []
  );

  return {
    loading,
    error,
    conversations,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  };
};

interface UseFunnelConversationParams {
  id: number;
}

interface UseFunnelConversationResponse extends ApiResponse {
  conversation: FunnelConversation | null;
}

export const useFunnelConversation = (
  { id }: UseFunnelConversationParams,
  enabled: boolean = true
): UseFunnelConversationResponse => {
  const fetchConversation = async (): Promise<FunnelConversation | null> => {
    return await CrmFunnelsConversationsService.getFunnelConversation(id);
  };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['crm/funnel-conversations', id],
    queryFn: fetchConversation,
    enabled,
  });

  return {
    loading,
    error,
    conversation: data ?? null,
    refetch,
  };
};

interface UseSearchFunnelConversationsParams
  extends FunnelConversationSearchBodyParams {}

interface UseSearchFunnelConversationsResponse extends ApiResponse {
  conversations: FunnelConversationDocument[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
}

export function useFunnelConversationDocuments(
  params: UseSearchFunnelConversationsParams,
  enabled = true
): UseSearchFunnelConversationsResponse {
  const fetchConversations = async ({
    pageParam,
  }: {
    pageParam: string | undefined;
  }): Promise<FunnelConversationSearchResponse> => {
    return CrmFunnelsConversationsService.searchFunnelConversations({
      ...params,
      pageToken: pageParam,
    });
  };

  const getNextPageParam = (lastPage: FunnelConversationSearchResponse) =>
    lastPage.nextPageToken;

  const {
    data,
    error,
    isLoading: loading,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['crm/documents/funnel-conversations', params],
    queryFn: fetchConversations,
    getNextPageParam,
    initialPageParam: undefined,
    enabled,
  });

  const conversations = useMemoizedArrayData<FunnelConversationDocument>(
    data?.pages.flatMap((page) => page.items) || []
  );

  return {
    loading,
    error,
    conversations,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  };
}
