import { useState } from 'react';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import { MdPhone, MdSettings } from 'react-icons/md';
import { ChannelType, ContactAddress } from 'src/api/generated';
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Tooltip,
} from '@material-tailwind/react';
import { formatPhoneNumberAsYouType } from 'src/modules/shared/utils/formatting/phone.util';

interface ContactCallButtonProps {
  addresses?: ContactAddress[];
  onCall: (phoneNumber: string) => void;
  onOpenConfig?: () => void;
  disabled?: boolean;
}

export function ContactCallButton({
  addresses,
  onCall,
  onOpenConfig,
  disabled,
}: ContactCallButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const phoneAddresses =
    addresses?.filter((addr) => addr.channelType === ChannelType.PHONE) ?? [];
  const hasPhoneNumbers = phoneAddresses.length > 0;
  const hasMultiplePhoneNumbers = phoneAddresses.length > 1;

  if (!hasPhoneNumbers) {
    return (
      <Tooltip content="No phone numbers available">
        <span>
          <Button
            variant="ghost"
            size="sm"
            disabled
            className="flex items-center gap-1"
          >
            <MdPhone className="w-4 h-4" />
          </Button>
        </span>
      </Tooltip>
    );
  }

  if (!hasMultiplePhoneNumbers) {
    return (
      <div className="flex items-center gap-1">
        <Button
          variant="ghost"
          size="sm"
          onClick={() => onCall(phoneAddresses[0].address)}
          disabled={disabled}
          className="flex items-center gap-1"
        >
          <MdPhone className="w-4 h-4" />
        </Button>
        <Button
          variant="ghost"
          size="sm"
          onClick={onOpenConfig}
          disabled={true} // Disabled for now as requested
          className="flex items-center gap-1"
        >
          <MdSettings className="w-4 h-4" />
        </Button>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-1">
      <Menu open={isOpen} handler={setIsOpen}>
        <MenuHandler>
          <Button
            variant="ghost"
            size="sm"
            disabled={disabled}
            className="flex items-center gap-1"
          >
            <MdPhone className="w-4 h-4" />
          </Button>
        </MenuHandler>
        <MenuList className="min-w-[200px]">
          {phoneAddresses.map((address) => (
            <MenuItem
              key={address.id}
              onClick={() => {
                onCall(address.address);
                setIsOpen(false);
              }}
            >
              {formatPhoneNumberAsYouType(address.address)}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <Button
        variant="ghost"
        size="sm"
        onClick={onOpenConfig}
        disabled={true} // Disabled for now as requested
        className="flex items-center gap-1"
      >
        <MdSettings className="w-4 h-4" />
      </Button>
    </div>
  );
}
