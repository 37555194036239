import { type FC } from 'react';
import { type FunnelConversationDocument } from 'src/api/generated';
import { Card } from 'src/modules/shared/components/atoms/cards';
import { ConversationCardHeader } from './card-header';
import { ConversationCardInteraction } from './card-interaction';

interface ConversationCardProps {
  conversation: FunnelConversationDocument;
  isSelected?: boolean;
  onClick?: () => void;
  className?: string;
}

export const ConversationCard: FC<ConversationCardProps> = ({
  conversation,
  isSelected,
  onClick,
  className = '',
}) => {
  return (
    <Card
      onClick={onClick}
      className={`
        w-full
        max-w-full
        ${isSelected ? 'ring-2' : ''}
        ${className}
      `}
    >
      <ConversationCardHeader conversation={conversation} className="mb-2" />
      <ConversationCardInteraction
        interaction={conversation.lastInteraction}
        lastInteractionAt={conversation.lastInteractionAt}
      />
    </Card>
  );
};
