import React, { ReactNode } from 'react';
import Label from '../label';
import Select from '../select';
import {
  BaseProps,
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';

interface LabelSelectProps extends BaseProps {
  label: string | ReactNode;
  options: { content: ReactNode | string; value: string }[];
  className?: string;
  labelProps?: Omit<React.ComponentProps<typeof Label>, 'size' | 'variant'>;
  selectProps?: Omit<
    React.ComponentProps<typeof Select>,
    'size' | 'variant' | 'options'
  >;
  labelPosition?: 'side' | 'top';
}

const LabelSelect: React.FC<LabelSelectProps> = ({
  label,
  options,
  variant = 'primary' as ComponentVariant,
  size = 'md' as ComponentSize,
  className = '',
  labelProps = {},
  selectProps = {},
  labelPosition = 'side',
}) => {
  const containerClasses = {
    side: 'flex-col sm:flex-row sm:items-center justify-between',
    top: 'flex-col relative',
  };

  const labelClasses = {
    top: [
      'absolute -top-[0.6rem] left-3 z-10',
      'px-0.5',
      'bg-gradient-to-b from-white via-white to-transparent',
      'pb-1',
    ].join(' '),
    side: '',
  };

  const topLabelSize: ComponentSize = ['xs', 'sm'].includes(size)
    ? '2xs'
    : 'xs';
  const labelSize = labelPosition === 'top' ? topLabelSize : size;

  return (
    <div
      className={`flex ${containerClasses[labelPosition]} ${
        labelPosition === 'top' ? '' : 'gap-2'
      } ${className}`}
    >
      <div className={labelPosition === 'side' ? 'sm:w-1/3' : ''}>
        <Label
          {...labelProps}
          variant={variant}
          size={labelSize}
          className={`${labelClasses[labelPosition]} ${labelProps.className || ''}`}
        >
          {label}
        </Label>
      </div>
      <div className="flex-1">
        <Select
          {...selectProps}
          variant={variant}
          size={size}
          options={options}
        />
      </div>
    </div>
  );
};

export default LabelSelect;
