import {
  BaseProps,
  ComponentSize,
} from 'src/modules/shared/types/component.type';
import { CircleDisplay } from '../circle-display';

interface AvatarProps extends BaseProps {
  text?: string;
  isOnline?: boolean;
}

export const Avatar: React.FC<AvatarProps> = ({ isOnline, ...props }) => {
  const statusSizeClasses: Record<ComponentSize, string> = {
    '2xs': 'w-1.5 h-1.5',
    xs: 'w-2 h-2',
    sm: 'w-2.5 h-2.5',
    md: 'w-3 h-3',
    lg: 'w-4 h-4',
  };

  return (
    <div className="relative">
      <CircleDisplay {...props} />
      {isOnline !== undefined && (
        <div
          className={`
            absolute 
            bottom-0 
            right-0 
            ${statusSizeClasses[props.size || 'md']} 
            rounded-full 
            border-2 
            border-white 
            ${isOnline ? 'bg-green-500' : 'bg-gray-400'}
            ${props.disabled ? 'opacity-50' : ''}
          `}
        />
      )}
    </div>
  );
};
