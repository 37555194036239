import {
  MessageDocument,
  MessageDirection,
  MessageStatus,
} from 'src/api/generated';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import { MdAttachment } from 'react-icons/md';
import { SiWhatsapp } from 'react-icons/si';
import { CircleDisplay } from 'src/modules/shared/components/atoms/circle-display';
import { WhatsAppText } from 'src/modules/shared/components/molecules/messages/whatsapp-text';
import { MessageStatusIndicator } from 'src/modules/shared/components/atoms/indicators/message-status-indicator';
import { Typography } from '@material-tailwind/react';
import { DirectionIndicator } from 'src/modules/shared/components/atoms/indicators/direction-indicator';

interface TimelineWhatsAppItemProps {
  item: MessageDocument;
  groupedItems?: MessageDocument[];
  isNew?: boolean;
}

export const TimelineWhatsAppItem: React.FC<TimelineWhatsAppItemProps> = ({
  item,
  groupedItems,
  isNew = false,
}) => {
  const isOutbound = item.direction === MessageDirection.OUTBOUND;

  const combinedContent = groupedItems
    ? groupedItems.map((msg) => msg.content).join('\n\n')
    : item.content;

  return (
    <motion.div
      initial={isNew ? { opacity: 0, y: 20 } : false}
      animate={{ opacity: 1, y: 0 }}
      className={`flex ${isOutbound ? 'justify-end' : 'justify-start'}`}
    >
      <div
        className={`w-fit min-w-0 max-w-[min(90%,_max-content)] overflow-hidden ${
          isOutbound ? 'bg-blue-50' : 'bg-white'
        } rounded-lg shadow-nm-flat-xs`}
      >
        <div className="flex items-start gap-3 p-3">
          <CircleDisplay
            icon={<SiWhatsapp />}
            variant="primary"
            size="sm"
            className={`flex-shrink-0 !text-emerald-500 ${
              isOutbound ? '!bg-white' : '!bg-emerald-50'
            }`}
          />

          <div className="flex-1 flex flex-col gap-1 min-w-0">
            <div className="flex flex-wrap items-center justify-between gap-4">
              <div className="flex flex-wrap items-center gap-1.5">
                <DirectionIndicator
                  isOutbound={isOutbound}
                  variant="primary"
                  className="!text-emerald-500"
                />
                <h4 className="text-sm font-semibold text-primary-800 break-all">
                  {isOutbound ? 'You' : item.sender.displayName || 'Contact'}
                </h4>
              </div>
              <Typography className="text-xs text-gray-500">
                {format(new Date(item.createdAt), 'HH:mm')}
              </Typography>
            </div>

            <div className="p-2">
              <div className="break-words whitespace-pre-wrap">
                <WhatsAppText text={combinedContent || ''} />
              </div>
              {item.attachments && item.attachments.length > 0 && (
                <div className="flex flex-wrap items-center gap-2 mt-2 text-xs text-gray-500">
                  <MdAttachment className="w-4 h-4 flex-shrink-0" />
                  <span className="break-all">
                    {item.attachments.length} attachment(s)
                  </span>
                </div>
              )}
            </div>

            {isOutbound && (
              <div className="flex flex-wrap items-center justify-end gap-4 gap-y-2">
                <div className="flex flex-wrap items-center gap-1 text-xs">
                  <MessageStatusIndicator
                    status={item.status as MessageStatus}
                    className="!text-emerald-600"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};
