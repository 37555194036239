import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from 'src/hooks/api/api';
import {
  ContactAddress,
  CrmContactsAddressesService,
  ContactAddressCreateBodyParams,
  ContactAddressUpdateBodyParams,
  ContactAddressListResponse,
  ContactAddressDeleteResponse,
  ContactAddressUpdateResponse,
  ContactAddressListQueryParams,
} from 'src/api/generated';

interface UseContactAddressesParams extends ContactAddressListQueryParams {}

interface UseContactAddressesResponse extends ApiResponse {
  addresses: ContactAddress[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
}

export const useContactAddresses = (
  params: UseContactAddressesParams,
  enabled: boolean = true
): UseContactAddressesResponse => {
  const fetchAddresses = async ({
    pageParam,
  }: {
    pageParam: string | undefined;
  }): Promise<ContactAddressListResponse> => {
    const { filterBy, searchBy, sortBy, page, pageSize = 20 } = params;

    return await CrmContactsAddressesService.getContactAddresses(
      filterBy,
      searchBy,
      sortBy,
      page,
      pageSize,
      pageParam
    );
  };

  const getNextPageParam = (lastPage: ContactAddressListResponse) =>
    lastPage.nextPageToken;

  const {
    data,
    error,
    isLoading: loading,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['crm/contacts/addresses', params],
    queryFn: fetchAddresses,
    getNextPageParam,
    initialPageParam: undefined,
    enabled,
  });

  const addresses = useMemoizedArrayData<ContactAddress>(
    data?.pages.flatMap((page) => page.items) || []
  );

  return {
    loading,
    error,
    addresses,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  };
};

interface UseCreateContactAddressMutationResponse {
  createAddress: (
    data: ContactAddressCreateBodyParams
  ) => Promise<ContactAddress>;
  loading: boolean;
  error: Error | null;
}

export const useCreateContactAddressMutation = (
  onSuccess: (newAddress: ContactAddress) => void = () => {},
  onError: (error: Error) => void = () => {}
): UseCreateContactAddressMutationResponse => {
  const queryClient = useQueryClient();

  const createContactAddress = async (
    data: ContactAddressCreateBodyParams
  ): Promise<ContactAddress> => {
    return await CrmContactsAddressesService.createContactAddress(data);
  };

  const {
    mutateAsync,
    isPending: loading,
    error,
  } = useMutation({
    mutationFn: createContactAddress,
    onSuccess: (newAddress) => {
      queryClient.invalidateQueries({
        queryKey: ['crm/contacts/addresses'],
      });
      onSuccess(newAddress);
    },
    onError: (error: Error) => onError(error),
  });

  return {
    createAddress: mutateAsync,
    loading,
    error,
  };
};

interface UpdateContactAddressParams {
  id: number;
  data: ContactAddressUpdateBodyParams;
}

interface UseUpdateContactAddressMutationResponse {
  updateAddress: (
    params: UpdateContactAddressParams
  ) => Promise<ContactAddress>;
  loading: boolean;
  error: Error | null;
}

export const useUpdateContactAddressMutation = (
  onSuccess: (newAddress: ContactAddress) => void = () => {},
  onError: (error: Error) => void = () => {}
): UseUpdateContactAddressMutationResponse => {
  const queryClient = useQueryClient();

  const updateContactAddress = async (
    params: UpdateContactAddressParams
  ): Promise<ContactAddressUpdateResponse> => {
    return await CrmContactsAddressesService.updateContactAddress(
      params.id,
      params.data
    );
  };

  const {
    mutateAsync,
    isPending: loading,
    error,
  } = useMutation({
    mutationFn: updateContactAddress,
    onSuccess: (newAddress) => {
      queryClient.invalidateQueries({
        queryKey: ['crm/contacts/addresses'],
      });
      onSuccess(newAddress);
    },
    onError: (error: Error) => onError(error),
  });

  return {
    updateAddress: mutateAsync,
    loading,
    error,
  };
};

interface UseDeleteContactAddressMutationResponse {
  deleteAddress: (id: number) => Promise<ContactAddress>;
  loading: boolean;
  error: Error | null;
}

export const useDeleteContactAddressMutation = (
  onSuccess: () => void = () => {},
  onError: (error: Error) => void = () => {}
): UseDeleteContactAddressMutationResponse => {
  const queryClient = useQueryClient();

  const deleteContactAddress = async (
    id: number
  ): Promise<ContactAddressDeleteResponse> => {
    return await CrmContactsAddressesService.deleteContactAddress(id);
  };

  const {
    mutateAsync,
    isPending: loading,
    error,
  } = useMutation({
    mutationFn: deleteContactAddress,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['crm/contacts/addresses'],
      });
      onSuccess();
    },
    onError: (error: Error) => onError(error),
  });

  return {
    deleteAddress: mutateAsync,
    loading,
    error,
  };
};
