/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TimelineItemSearchBodyParams } from '../models/TimelineItemSearchBodyParams';
import type { TimelineItemSearchResponse } from '../models/TimelineItemSearchResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CrmTimelineItemsService {

    /**
     * Search timeline items with pagination, filtering, and sorting
     * @param requestBody Search parameters for filtering, sorting, and pagination
     * @returns TimelineItemSearchResponse Ok
     * @throws ApiError
     */
    public static searchTimelineItems(
        requestBody: TimelineItemSearchBodyParams,
    ): CancelablePromise<TimelineItemSearchResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/crm/timeline-items/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

}
