import { motion } from 'framer-motion';
import { formatDistanceToNow } from 'date-fns';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import { Panel } from 'src/modules/shared/components/molecules/panel';
import { PanelHeader } from 'src/modules/shared/components/molecules/panel/panel-header';
import {
  useContact,
  useContactAddresses,
} from 'src/modules/communications/hooks/api';
import { createQuery } from 'src/modules/shared/utils/api/query-builder.util';
import { ContactInfoSection } from '../../molecules/contact-panel/contact-info-section';
import { ContactAddressesSection } from '../../molecules/contact-panel/contact-addresses-section';
import { CompanyInfoSection } from '../../molecules/contact-panel/company-info-section';
import { Separator } from 'src/modules/shared/components/atoms/separator';
import { Typography } from '@material-tailwind/react';
import { ErrorState } from 'src/modules/shared/components/atoms/states/error-state';

interface ContactPanelProps {
  onClose?: () => void;
  contactId: number;
}

const ContactPanel: React.FC<ContactPanelProps> = ({ onClose, contactId }) => {
  const {
    contact,
    loading: loadingContact,
    error: contactError,
  } = useContact({ id: contactId }, !!contactId);

  const contactAddressesQuery = createQuery()
    .equals('contactId', contactId)
    .orderByDesc('createdAt')
    .build();

  const { addresses, loading: loadingAddresses } = useContactAddresses(
    contactAddressesQuery,
    !!contactId
  );

  if (contactError) {
    return (
      <motion.div
        initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 20, opacity: 0 }}
        className="flex flex-col h-full bg-white"
      >
        <ErrorState
          title="Failed to load"
          description="We couldn't load the contact information. Please try again."
          onAction={onClose}
          actionText="Close"
        />
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ x: 20, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: 20, opacity: 0 }}
      className="flex flex-col h-full bg-white"
    >
      <PanelHeader
        fixed
        size="lg"
        actions={
          onClose && (
            <Button variant="ghost" size="sm" onClick={onClose}>
              Close
            </Button>
          )
        }
      >
        <div className="flex items-center min-w-0">
          <div className="flex flex-col gap-0.5 min-w-0">
            <Typography variant="h6" color="blue-gray">
              Contact Profile
            </Typography>
          </div>
        </div>
      </PanelHeader>

      <Panel
        neumorphic="flat"
        className="flex-1 overflow-y-auto bg-white rounded-none"
      >
        <div className="p-4 space-y-6">
          <ContactInfoSection contact={contact} loading={loadingContact} />
          <Separator />

          <ContactAddressesSection
            addresses={addresses}
            loading={loadingAddresses}
            contactId={contactId}
          />
          <Separator />

          <CompanyInfoSection contact={contact} loading={loadingContact} />
          <Separator />

          {contact?.updatedAt && (
            <Typography variant="small" color="gray">
              Last updated{' '}
              {formatDistanceToNow(new Date(contact.updatedAt), {
                addSuffix: true,
              })}
            </Typography>
          )}
        </div>
      </Panel>
    </motion.div>
  );
};

export default ContactPanel;
