import { type FC, type ReactNode } from 'react';
import {
  type BaseProps,
  type ComponentSize,
} from 'src/modules/shared/types/component.type';

interface PanelHeaderProps extends BaseProps {
  children: ReactNode;
  actions?: ReactNode;
  border?: boolean;
  fixed?: boolean;
  size?: ComponentSize;
}

export const PanelHeader: FC<PanelHeaderProps> = ({
  children,
  actions,
  border = true,
  fixed = false,
  size = 'md',
  className = '',
}) => {
  const heightClasses: Record<ComponentSize, string> = {
    '2xs': 'h-10',
    xs: 'h-12',
    sm: 'h-14',
    md: 'h-18',
    lg: 'h-24',
  };

  return (
    <div
      className={`
        flex items-center justify-between
        ${border ? 'border-b border-gray-200' : ''}
        ${fixed ? heightClasses[size] : ''}
        ${className}
      `}
    >
      <div
        className={`
          flex items-center justify-between w-full px-4
          ${fixed ? 'h-full overflow-y-auto' : ''}
        `}
      >
        <div className={`flex-1 ${fixed ? 'min-h-0' : ''}`}>{children}</div>
        {actions && (
          <div className="flex items-center gap-2 flex-shrink-0 ml-4">
            {actions}
          </div>
        )}
      </div>
    </div>
  );
};
