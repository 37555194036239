import { FunnelConversationDocument } from 'src/api/generated';
import { ConversationCard } from '../conversations/card';
import { ConversationCardSkeleton } from '../conversations/card/card-skeleton';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import { ErrorState } from 'src/modules/shared/components/atoms/states/error-state';
import { EmptyState } from 'src/modules/shared/components/atoms/states/empty-state';

interface ConversationListProps {
  isLoading?: boolean;
  error?: Error | null;
  conversations?: FunnelConversationDocument[];
  onLoadMore?: () => void;
  hasNextPage?: boolean;
  onConversationSelect?: (id: number) => void;
  selectedConversationId?: string;
  onRetry?: () => void;
}

const ConversationList: React.FC<ConversationListProps> = ({
  isLoading,
  error,
  conversations = [],
  onLoadMore,
  hasNextPage,
  onConversationSelect,
  selectedConversationId,
  onRetry,
}) => {
  const handleConversationClick = (
    conversation: FunnelConversationDocument
  ) => {
    onConversationSelect?.(Number(conversation.id));
  };

  if (error) {
    return (
      <ErrorState
        title="Failed to load conversations"
        description={error.message || 'An unexpected error occurred'}
        onAction={onRetry}
        variant="primary"
      />
    );
  }

  if (isLoading) {
    return (
      <div className="flex flex-col space-y-3">
        {[...Array(5)].map((_, index) => (
          <ConversationCardSkeleton key={index} />
        ))}
      </div>
    );
  }

  if (conversations.length === 0) {
    return (
      <EmptyState
        title="No conversations found"
        description="Start a new conversation or change your filters"
        icon={<span className="text-2xl">💬</span>}
      />
    );
  }

  return (
    <div className="flex flex-col space-y-3 h-full">
      {conversations.map((conversation) => (
        <ConversationCard
          key={conversation.id}
          conversation={conversation}
          onClick={() => handleConversationClick(conversation)}
          isSelected={conversation.id === selectedConversationId}
        />
      ))}
      {hasNextPage && (
        <Button variant="ghost" onClick={onLoadMore} className="w-full">
          Load more
        </Button>
      )}
    </div>
  );
};

export default ConversationList;
