import React, { ComponentProps } from 'react';
import {
  BaseProps,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';
import { labelSizeClasses } from 'src/modules/shared/styles/component.styles';

interface LabelProps
  extends BaseProps<Omit<ComponentProps<'label'>, 'size' | 'variant'>> {}

const Label: React.FC<LabelProps> = ({
  children,
  className = '',
  variant = 'primary',
  size = 'md',
  ...rest
}) => {
  const baseClasses = ['font-medium', 'transition-colors', 'duration-200'];

  const variantTextColors: Record<ComponentVariant, string> = {
    primary: 'text-neutral-700',
    secondary: 'text-primary-600',
    tertiary: 'text-neutral-600',
    danger: 'text-danger-600',
    ghost: 'text-neutral-500',
  };

  const classes = [
    ...baseClasses,
    variantTextColors[variant],
    labelSizeClasses[size],
    className,
  ].join(' ');

  return (
    <label className={classes} {...rest}>
      {children}
    </label>
  );
};

export default Label;
