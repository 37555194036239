/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LoginImport } from './routes/login'
import { Route as IndexImport } from './routes/index'
import { Route as UsersIndexImport } from './routes/users.index'
import { Route as SettingsIndexImport } from './routes/settings.index'
import { Route as ReportsIndexImport } from './routes/reports.index'
import { Route as OpportunitiesIndexImport } from './routes/opportunities.index'
import { Route as FeaturedCallsIndexImport } from './routes/featured-calls.index'
import { Route as ConversationsIndexImport } from './routes/conversations.index'
import { Route as ContactsIndexImport } from './routes/contacts.index'
import { Route as CommunicationsIndexImport } from './routes/communications/index'
import { Route as CallsIndexImport } from './routes/calls.index'
import { Route as IntegrationsPipedriveIndexImport } from './routes/integrations/pipedrive.index'

// Create/Update Routes

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const UsersIndexRoute = UsersIndexImport.update({
  id: '/users/',
  path: '/users/',
  getParentRoute: () => rootRoute,
} as any)

const SettingsIndexRoute = SettingsIndexImport.update({
  id: '/settings/',
  path: '/settings/',
  getParentRoute: () => rootRoute,
} as any)

const ReportsIndexRoute = ReportsIndexImport.update({
  id: '/reports/',
  path: '/reports/',
  getParentRoute: () => rootRoute,
} as any)

const OpportunitiesIndexRoute = OpportunitiesIndexImport.update({
  id: '/opportunities/',
  path: '/opportunities/',
  getParentRoute: () => rootRoute,
} as any)

const FeaturedCallsIndexRoute = FeaturedCallsIndexImport.update({
  id: '/featured-calls/',
  path: '/featured-calls/',
  getParentRoute: () => rootRoute,
} as any)

const ConversationsIndexRoute = ConversationsIndexImport.update({
  id: '/conversations/',
  path: '/conversations/',
  getParentRoute: () => rootRoute,
} as any)

const ContactsIndexRoute = ContactsIndexImport.update({
  id: '/contacts/',
  path: '/contacts/',
  getParentRoute: () => rootRoute,
} as any)

const CommunicationsIndexRoute = CommunicationsIndexImport.update({
  id: '/communications/',
  path: '/communications/',
  getParentRoute: () => rootRoute,
} as any)

const CallsIndexRoute = CallsIndexImport.update({
  id: '/calls/',
  path: '/calls/',
  getParentRoute: () => rootRoute,
} as any)

const IntegrationsPipedriveIndexRoute = IntegrationsPipedriveIndexImport.update(
  {
    id: '/integrations/pipedrive/',
    path: '/integrations/pipedrive/',
    getParentRoute: () => rootRoute,
  } as any,
)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/calls/': {
      id: '/calls/'
      path: '/calls'
      fullPath: '/calls'
      preLoaderRoute: typeof CallsIndexImport
      parentRoute: typeof rootRoute
    }
    '/communications/': {
      id: '/communications/'
      path: '/communications'
      fullPath: '/communications'
      preLoaderRoute: typeof CommunicationsIndexImport
      parentRoute: typeof rootRoute
    }
    '/contacts/': {
      id: '/contacts/'
      path: '/contacts'
      fullPath: '/contacts'
      preLoaderRoute: typeof ContactsIndexImport
      parentRoute: typeof rootRoute
    }
    '/conversations/': {
      id: '/conversations/'
      path: '/conversations'
      fullPath: '/conversations'
      preLoaderRoute: typeof ConversationsIndexImport
      parentRoute: typeof rootRoute
    }
    '/featured-calls/': {
      id: '/featured-calls/'
      path: '/featured-calls'
      fullPath: '/featured-calls'
      preLoaderRoute: typeof FeaturedCallsIndexImport
      parentRoute: typeof rootRoute
    }
    '/opportunities/': {
      id: '/opportunities/'
      path: '/opportunities'
      fullPath: '/opportunities'
      preLoaderRoute: typeof OpportunitiesIndexImport
      parentRoute: typeof rootRoute
    }
    '/reports/': {
      id: '/reports/'
      path: '/reports'
      fullPath: '/reports'
      preLoaderRoute: typeof ReportsIndexImport
      parentRoute: typeof rootRoute
    }
    '/settings/': {
      id: '/settings/'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof SettingsIndexImport
      parentRoute: typeof rootRoute
    }
    '/users/': {
      id: '/users/'
      path: '/users'
      fullPath: '/users'
      preLoaderRoute: typeof UsersIndexImport
      parentRoute: typeof rootRoute
    }
    '/integrations/pipedrive/': {
      id: '/integrations/pipedrive/'
      path: '/integrations/pipedrive'
      fullPath: '/integrations/pipedrive'
      preLoaderRoute: typeof IntegrationsPipedriveIndexImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/login': typeof LoginRoute
  '/calls': typeof CallsIndexRoute
  '/communications': typeof CommunicationsIndexRoute
  '/contacts': typeof ContactsIndexRoute
  '/conversations': typeof ConversationsIndexRoute
  '/featured-calls': typeof FeaturedCallsIndexRoute
  '/opportunities': typeof OpportunitiesIndexRoute
  '/reports': typeof ReportsIndexRoute
  '/settings': typeof SettingsIndexRoute
  '/users': typeof UsersIndexRoute
  '/integrations/pipedrive': typeof IntegrationsPipedriveIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/login': typeof LoginRoute
  '/calls': typeof CallsIndexRoute
  '/communications': typeof CommunicationsIndexRoute
  '/contacts': typeof ContactsIndexRoute
  '/conversations': typeof ConversationsIndexRoute
  '/featured-calls': typeof FeaturedCallsIndexRoute
  '/opportunities': typeof OpportunitiesIndexRoute
  '/reports': typeof ReportsIndexRoute
  '/settings': typeof SettingsIndexRoute
  '/users': typeof UsersIndexRoute
  '/integrations/pipedrive': typeof IntegrationsPipedriveIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/login': typeof LoginRoute
  '/calls/': typeof CallsIndexRoute
  '/communications/': typeof CommunicationsIndexRoute
  '/contacts/': typeof ContactsIndexRoute
  '/conversations/': typeof ConversationsIndexRoute
  '/featured-calls/': typeof FeaturedCallsIndexRoute
  '/opportunities/': typeof OpportunitiesIndexRoute
  '/reports/': typeof ReportsIndexRoute
  '/settings/': typeof SettingsIndexRoute
  '/users/': typeof UsersIndexRoute
  '/integrations/pipedrive/': typeof IntegrationsPipedriveIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/login'
    | '/calls'
    | '/communications'
    | '/contacts'
    | '/conversations'
    | '/featured-calls'
    | '/opportunities'
    | '/reports'
    | '/settings'
    | '/users'
    | '/integrations/pipedrive'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/login'
    | '/calls'
    | '/communications'
    | '/contacts'
    | '/conversations'
    | '/featured-calls'
    | '/opportunities'
    | '/reports'
    | '/settings'
    | '/users'
    | '/integrations/pipedrive'
  id:
    | '__root__'
    | '/'
    | '/login'
    | '/calls/'
    | '/communications/'
    | '/contacts/'
    | '/conversations/'
    | '/featured-calls/'
    | '/opportunities/'
    | '/reports/'
    | '/settings/'
    | '/users/'
    | '/integrations/pipedrive/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  LoginRoute: typeof LoginRoute
  CallsIndexRoute: typeof CallsIndexRoute
  CommunicationsIndexRoute: typeof CommunicationsIndexRoute
  ContactsIndexRoute: typeof ContactsIndexRoute
  ConversationsIndexRoute: typeof ConversationsIndexRoute
  FeaturedCallsIndexRoute: typeof FeaturedCallsIndexRoute
  OpportunitiesIndexRoute: typeof OpportunitiesIndexRoute
  ReportsIndexRoute: typeof ReportsIndexRoute
  SettingsIndexRoute: typeof SettingsIndexRoute
  UsersIndexRoute: typeof UsersIndexRoute
  IntegrationsPipedriveIndexRoute: typeof IntegrationsPipedriveIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  LoginRoute: LoginRoute,
  CallsIndexRoute: CallsIndexRoute,
  CommunicationsIndexRoute: CommunicationsIndexRoute,
  ContactsIndexRoute: ContactsIndexRoute,
  ConversationsIndexRoute: ConversationsIndexRoute,
  FeaturedCallsIndexRoute: FeaturedCallsIndexRoute,
  OpportunitiesIndexRoute: OpportunitiesIndexRoute,
  ReportsIndexRoute: ReportsIndexRoute,
  SettingsIndexRoute: SettingsIndexRoute,
  UsersIndexRoute: UsersIndexRoute,
  IntegrationsPipedriveIndexRoute: IntegrationsPipedriveIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/login",
        "/calls/",
        "/communications/",
        "/contacts/",
        "/conversations/",
        "/featured-calls/",
        "/opportunities/",
        "/reports/",
        "/settings/",
        "/users/",
        "/integrations/pipedrive/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/calls/": {
      "filePath": "calls.index.tsx"
    },
    "/communications/": {
      "filePath": "communications/index.tsx"
    },
    "/contacts/": {
      "filePath": "contacts.index.tsx"
    },
    "/conversations/": {
      "filePath": "conversations.index.tsx"
    },
    "/featured-calls/": {
      "filePath": "featured-calls.index.tsx"
    },
    "/opportunities/": {
      "filePath": "opportunities.index.tsx"
    },
    "/reports/": {
      "filePath": "reports.index.tsx"
    },
    "/settings/": {
      "filePath": "settings.index.tsx"
    },
    "/users/": {
      "filePath": "users.index.tsx"
    },
    "/integrations/pipedrive/": {
      "filePath": "integrations/pipedrive.index.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
