import { Typography } from '@material-tailwind/react';
import { Contact } from 'src/api/generated';
import { useState } from 'react';
import { PencilIcon } from '@heroicons/react/24/outline';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import LabelTextInput from 'src/modules/shared/components/atoms/inputs/label-text-input';
import { motion } from 'framer-motion';
import { useAlert } from 'src/contexts/alert-context';

interface ContactInfoSectionProps {
  contact: Contact | null;
  loading?: boolean;
}

interface EditableFieldProps {
  label: string;
  value: string;
  onSave: (value: string) => Promise<void>;
  placeholder?: string;
}

const EditableField: React.FC<EditableFieldProps> = ({
  label,
  value,
  onSave,
  placeholder,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value);
  const [saving, setSaving] = useState(false);

  const handleSave = async () => {
    if (!editedValue.trim()) return;
    setSaving(true);
    try {
      await onSave(editedValue);
      setIsEditing(false);
    } finally {
      setSaving(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedValue(value);
  };

  return (
    <div className="group relative flex items-center justify-between">
      {isEditing ? (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className="w-full"
        >
          <LabelTextInput
            label={label}
            labelPosition="top"
            size="sm"
            inputProps={{
              className: 'w-full',
              value: editedValue,
              onChange: (e) => setEditedValue(e.target.value),
              placeholder: placeholder,
            }}
          />
          <div className="flex justify-end gap-2 mt-2">
            <Button variant="ghost" size="sm" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={handleSave}
              disabled={!editedValue.trim() || saving}
            >
              {saving ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </motion.div>
      ) : (
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-2">
            <Typography variant="small" color="gray">
              {label}:
            </Typography>
            <Typography variant="small" color="blue-gray">
              {value || 'Not provided'}
            </Typography>
          </div>
          <button
            onClick={() => setIsEditing(true)}
            className="opacity-0 group-hover:opacity-100 transition-opacity p-1 text-gray-500 hover:text-gray-700"
          >
            <PencilIcon className="w-4 h-4" />
          </button>
        </div>
      )}
    </div>
  );
};

export const ContactInfoSection: React.FC<ContactInfoSectionProps> = ({
  contact,
  loading = false,
}) => {
  const { showAlert } = useAlert();

  const handleSaveField = async (_field: keyof Contact, _value: string) => {
    try {
      // Mock API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
      showAlert(`Field updated successfully`, 'success');
    } catch (error) {
      console.error(`Failed to update field:`, error);
      showAlert(`Failed to update field`, 'error');
      throw error;
    }
  };

  if (loading) {
    return (
      <div className="animate-pulse space-y-6">
        <div className="mb-4">
          <div className="h-5 bg-gray-200 rounded w-1/4 mb-1"></div>
          <div className="h-4 bg-gray-200 rounded w-1/3"></div>
        </div>
        <div className="space-y-4">
          <div className="h-4 bg-gray-200 rounded w-1/2"></div>
          <div className="h-4 bg-gray-200 rounded w-1/3"></div>
          <div className="h-4 bg-gray-200 rounded w-2/3"></div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <div>
          <Typography variant="h6" color="blue-gray">
            Contact
          </Typography>
        </div>
      </div>

      <div className="space-y-3">
        <EditableField
          label="First Name"
          value={contact?.firstName || ''}
          onSave={(value) => handleSaveField('firstName', value)}
          placeholder="Enter first name..."
        />
        <EditableField
          label="Last Name"
          value={contact?.lastName || ''}
          onSave={(value) => handleSaveField('lastName', value)}
          placeholder="Enter last name..."
        />
        <EditableField
          label="Personal ID"
          value={contact?.nationalId || ''}
          onSave={(value) => handleSaveField('nationalId', value)}
          placeholder="Enter personal ID..."
        />
        <EditableField
          label="Company Position"
          value={contact?.companyPosition || ''}
          onSave={(value) => handleSaveField('companyPosition', value)}
          placeholder="Enter company position..."
        />
      </div>
    </div>
  );
};
