import { ComponentSize, ComponentVariant } from '../types/component.type';

export const baseInputClasses = [
  'w-full',
  'rounded-md',
  'transition-all',
  'duration-200',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'border',
];

export const variantClasses: Record<NonNullable<ComponentVariant>, string> = {
  primary:
    'bg-primary-600 text-white hover:bg-primary-700 active:bg-primary-800 focus:ring-primary-500',
  secondary:
    'bg-white text-secondary-500 border border-secondary-500 hover:bg-secondary-50 active:bg-secondary-100 focus:ring-secondary-500',
  tertiary:
    'bg-neutral-100 text-tertiary-700 border border-tertiary-500 hover:bg-tertiary-200 active:bg-tertiary-300 focus:ring-tertiary-500',
  danger:
    'bg-danger-500 text-white hover:bg-danger-600 active:bg-danger-700 focus:ring-danger-500',
  ghost:
    'bg-transparent text-neutral-600 hover:bg-neutral-100 active:bg-neutral-200',
};

export const inputVariantClasses: Record<
  NonNullable<ComponentVariant>,
  string
> = {
  primary: 'border-neutral-300 focus:border-primary-500 focus:ring-primary-500',
  secondary:
    'border-primary-300 focus:border-primary-600 focus:ring-primary-500',
  tertiary:
    'border-neutral-200 focus:border-neutral-400 focus:ring-neutral-400',
  danger: 'border-danger-300 focus:border-danger-500 focus:ring-danger-500',
  ghost:
    'border-transparent bg-neutral-100 focus:bg-white focus:border-neutral-300',
};

export const inputSizeClasses: Record<NonNullable<ComponentSize>, string> = {
  '2xs': 'h-6 px-2 text-xs',
  xs: 'h-7 px-2.5 text-xs',
  sm: 'h-8 px-3 text-sm',
  md: 'h-10 px-4 text-sm',
  lg: 'h-12 px-6 text-base',
};

export const labelSizeClasses: Record<NonNullable<ComponentSize>, string> = {
  '2xs': 'text-xs',
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-sm',
  lg: 'text-base',
};

export function getDisabledClasses(isDisabled: boolean) {
  return isDisabled ? 'opacity-50 cursor-not-allowed' : '';
}

export function getErrorClasses(hasError: boolean) {
  return hasError
    ? 'border-danger-500 focus:border-danger-500 focus:ring-danger-500'
    : '';
}
