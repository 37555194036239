import { BaseComponentProps } from 'src/modules/shared/types/component.type';

interface SeparatorProps extends BaseComponentProps {
  padded?: boolean;
}

export const Separator: React.FC<SeparatorProps> = ({
  className = '',
  padded = false,
}) => (
  <div className={padded ? 'px-4' : ''}>
    <div className={`h-px bg-gray-200 ${className}`} />
  </div>
);
