import { ReactNode } from 'react';
import withAuthentication from 'src/hocs/withAuthentication';
import TimezoneProvider from './timezone-sync-provider';
import { GlobalCallProvider } from './global-call-provider-context';

interface AuthenticatedProvidersProps {
  children: ReactNode;
}

const AuthenticatedProviders: React.FC<AuthenticatedProvidersProps> = ({
  children,
}) => {
  const AuthenticatedTimezoneProvider = withAuthentication(TimezoneProvider);
  const AuthenticatedCallProviderProvider =
    withAuthentication(GlobalCallProvider);

  return (
    <AuthenticatedCallProviderProvider>
      <AuthenticatedTimezoneProvider>
        {/* Add other providers that require authentication here */}
        {children}
      </AuthenticatedTimezoneProvider>
    </AuthenticatedCallProviderProvider>
  );
};

export default AuthenticatedProviders;
