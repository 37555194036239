import { type FC, type ReactNode } from 'react';
import {
  DndContext,
  DragOverlay,
  type DropAnimation,
  type SensorDescriptor,
  type SensorOptions,
  type DragStartEvent,
  type DragOverEvent,
  type DragEndEvent,
  type CollisionDetection,
} from '@dnd-kit/core';
import { type BaseProps } from 'src/modules/shared/types/component.type';

interface DraggableBoardProps extends BaseProps {
  sensors: SensorDescriptor<SensorOptions>[];
  onDragStart: (event: DragStartEvent) => void;
  onDragOver: (event: DragOverEvent) => void;
  onDragEnd: (event: DragEndEvent) => void;
  dragOverlay?: ReactNode;
  dropAnimation?: DropAnimation | null;
  collisionDetection?: CollisionDetection;
}

export const DraggableBoard: FC<DraggableBoardProps> = ({
  children,
  className = '',
  sensors,
  onDragStart,
  onDragOver,
  onDragEnd,
  dragOverlay,
  dropAnimation,
  collisionDetection,
}) => {
  return (
    <DndContext
      sensors={sensors}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
      collisionDetection={collisionDetection}
    >
      <div
        className={`relative h-full min-h-0 overflow-x-auto ${className} p-0`}
      >
        {children}
      </div>

      <DragOverlay dropAnimation={dropAnimation}>{dragOverlay}</DragOverlay>
    </DndContext>
  );
};
