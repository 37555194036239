import { Typography } from '@material-tailwind/react';
import { Contact } from 'src/api/generated';
import { Avatar } from 'src/modules/shared/components/atoms/avatar';
import { useState } from 'react';
import { PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import LabelTextInput from 'src/modules/shared/components/atoms/inputs/label-text-input';
import { motion, AnimatePresence } from 'framer-motion';
import { useAlert } from 'src/contexts/alert-context';
import Modal from 'src/modules/shared/components/molecules/modals/modal';
import ModalFooter from 'src/modules/shared/components/molecules/modals/modal-footer';

interface CompanyInfoSectionProps {
  contact: Contact | null;
  loading?: boolean;
}

export const CompanyInfoSection: React.FC<CompanyInfoSectionProps> = ({
  contact,
  loading = false,
}) => {
  const { showAlert } = useAlert();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeletingCompany, setIsDeletingCompany] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  const handleSaveCompany = async () => {
    try {
      // Mock API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
      showAlert('Company updated successfully', 'success');
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update company:', error);
      showAlert('Failed to update company', 'error');
    }
  };

  const handleDeleteCompany = async () => {
    try {
      setIsDeleting(true);
      // Mock API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
      showAlert('Company removed successfully', 'success');
      setCompanyName('');
      setIsDeletingCompany(false);
    } catch (error) {
      console.error('Failed to remove company:', error);
      showAlert('Failed to remove company', 'error');
    } finally {
      setIsDeleting(false);
    }
  };

  if (loading) {
    return (
      <div className="animate-pulse">
        <div className="mb-4">
          <div className="h-5 bg-gray-200 rounded w-1/4 mb-1"></div>
          <div className="h-4 bg-gray-200 rounded w-1/3"></div>
        </div>
        <div className="flex items-center gap-3">
          <div className="w-8 h-8 rounded-full bg-gray-200"></div>
          <div className="flex-1">
            <div className="h-4 bg-gray-200 rounded w-1/3"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div>
        <div className="flex items-center justify-between mb-4">
          <div>
            <Typography variant="h6" color="blue-gray">
              Company
            </Typography>
          </div>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => setIsEditing(true)}
            className="flex items-center gap-1"
          >
            {!contact?.contactCompanyId && (
              <>
                <PlusIcon className="w-4 h-4" />
                Add Company
              </>
            )}
          </Button>
        </div>

        <AnimatePresence>
          {isEditing ? (
            <motion.div className="border border-gray-200 rounded-lg p-4 mb-4">
              <div className="space-y-4">
                <LabelTextInput
                  label="Company Name"
                  labelPosition="top"
                  size="sm"
                  inputProps={{
                    className: 'w-full',
                    value: companyName,
                    onChange: (e) => setCompanyName(e.target.value),
                    placeholder: 'Enter company name...',
                  }}
                />
                <div className="flex justify-end gap-2">
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                      setIsEditing(false);
                      setCompanyName('');
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={handleSaveCompany}
                    disabled={!companyName}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </motion.div>
          ) : (
            companyName && (
              <motion.div className="group relative flex items-center justify-between text-sm p-2 hover:bg-gray-50 rounded-lg">
                <div className="flex items-center gap-3">
                  <Avatar text={companyName} size="sm" />
                  <Typography color="blue-gray" className="text-sm font-normal">
                    {companyName || 'No company'}
                  </Typography>
                </div>
                <div className="opacity-0 group-hover:opacity-100 transition-opacity flex items-center gap-2">
                  <button
                    onClick={() => setIsEditing(true)}
                    className="p-1 text-gray-500 hover:text-gray-700"
                  >
                    <PencilIcon className="w-4 h-4" />
                  </button>
                  <button
                    onClick={() => setIsDeletingCompany(true)}
                    className="p-1 text-gray-500 hover:text-red-600"
                  >
                    <TrashIcon className="w-4 h-4" />
                  </button>
                </div>
              </motion.div>
            )
          )}
        </AnimatePresence>
      </div>

      <Modal
        isOpen={isDeletingCompany}
        onClose={() => setIsDeletingCompany(false)}
        header={
          <Typography variant="h5" color="blue-gray">
            Remove Company
          </Typography>
        }
        body={
          <Typography variant="paragraph" color="blue-gray">
            Are you sure you want to remove this company? This action cannot be
            undone.
          </Typography>
        }
        footer={
          <ModalFooter
            onCancel={() => setIsDeletingCompany(false)}
            onConfirm={handleDeleteCompany}
            cancelText="Cancel"
            confirmText="Remove"
            confirmDisabled={isDeleting}
            loading={isDeleting}
            variant="danger"
          />
        }
      />
    </>
  );
};
