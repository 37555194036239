import { type FC, type ReactNode } from 'react';
import { type BaseProps } from 'src/modules/shared/types/component.type';
import { Typography } from '@material-tailwind/react';

type EmptyStateProps = Omit<
  BaseProps,
  'children' | 'variant' | 'size' | 'state' | 'loading' | 'error'
> & {
  title: string;
  description?: string;
  icon?: ReactNode;
};

export const EmptyState: FC<EmptyStateProps> = ({
  title,
  description,
  icon,
  className = '',
  disabled,
}) => {
  return (
    <div
      className={`
        flex flex-col w-full items-center justify-center h-full space-y-4
        ${disabled ? 'opacity-50' : ''}
        ${className}
      `}
    >
      {icon && (
        <div className="w-6 h-6 rounded-full bg-gray-100 flex items-center justify-center">
          {icon}
        </div>
      )}
      <div className="text-center space-y-1">
        <Typography variant="paragraph" className="text-gray-900 font-medium">
          {title}
        </Typography>
        {description && (
          <Typography variant="small" className="text-gray-600">
            {description}
          </Typography>
        )}
      </div>
    </div>
  );
};
