import { type FC } from 'react';
import { formatDistanceToNow } from 'date-fns';
import {
  FunnelConversationDocument,
  FunnelInteractionDirection,
  FunnelInteractionStatus,
  FunnelInteractionType,
} from 'src/api/generated';
import {
  MdOutlineMessage,
  MdPhone,
  MdArrowOutward,
  MdArrowDownward,
} from 'react-icons/md';
import { StyledIcon } from 'src/modules/shared/components/atoms/icons/styled-icon';

interface ConversationCardInteractionProps {
  interaction?: FunnelConversationDocument['lastInteraction'];
  lastInteractionAt?: string;
  className?: string;
}

const getDirectionIcon = (direction?: FunnelInteractionDirection) => {
  switch (direction) {
    case FunnelInteractionDirection.INCOMING:
      return (
        <StyledIcon icon={<MdArrowDownward />} variant="secondary" size="sm" />
      );
    case FunnelInteractionDirection.OUTGOING:
      return (
        <StyledIcon icon={<MdArrowOutward />} variant="primary" size="sm" />
      );
    default:
      return null;
  }
};

const getStatusIcon = (
  type?: FunnelInteractionType,
  status?: FunnelInteractionStatus
) => {
  if (type === FunnelInteractionType.CALL) {
    switch (status) {
      case 'queued':
      case 'initiated':
      case 'ringing':
        return (
          <StyledIcon
            icon={<MdPhone />}
            variant="secondary"
            size="sm"
            className="animate-pulse"
          />
        );
      case 'in-progress':
      case 'completed':
        return <StyledIcon icon={<MdPhone />} variant="primary" size="sm" />;
      case 'busy':
      case 'no-answer':
      case 'failed':
        return <StyledIcon icon={<MdPhone />} variant="danger" size="sm" />;
      default:
        return <StyledIcon icon={<MdPhone />} variant="ghost" size="sm" />;
    }
  }

  switch (status) {
    case 'pending':
      return (
        <StyledIcon icon={<MdOutlineMessage />} variant="secondary" size="sm" />
      );
    case 'delivered':
      return (
        <StyledIcon icon={<MdOutlineMessage />} variant="primary" size="sm" />
      );
    case 'failed':
      return (
        <StyledIcon icon={<MdOutlineMessage />} variant="danger" size="sm" />
      );
    default:
      return (
        <StyledIcon icon={<MdOutlineMessage />} variant="ghost" size="sm" />
      );
  }
};

export const ConversationCardInteraction: FC<
  ConversationCardInteractionProps
> = ({ interaction, lastInteractionAt, className = '' }) => {
  if (!interaction) {
    // TODO: check when implementing i18n
    return (
      <div className={`text-xs text-gray-500 ${className}`}>No messages</div>
    );
  }

  return (
    <div className={`space-y-1 ${className}`}>
      <div className="flex items-center gap-2 text-xs">
        <div className="flex gap-0.5">
          {getStatusIcon(interaction.modelType, interaction.status)}
          {getDirectionIcon(interaction.direction)}
        </div>

        {interaction.snippet && (
          <p className="flex-1 text-gray-600 truncate">{interaction.snippet}</p>
        )}
      </div>

      {lastInteractionAt && (
        <div className="text-xs text-gray-500">
          {formatDistanceToNow(new Date(lastInteractionAt), {
            addSuffix: true,
          })}
        </div>
      )}
    </div>
  );
};
