import { MdCallMade, MdCallReceived } from 'react-icons/md';
import {
  ComponentVariant,
  type BaseProps,
} from 'src/modules/shared/types/component.type';

interface DirectionIndicatorProps extends BaseProps {
  isOutbound: boolean;
}

export const DirectionIndicator = ({
  isOutbound,
  variant = 'primary',
  className = '',
}: DirectionIndicatorProps) => {
  const variantColors: Record<ComponentVariant, string> = {
    primary: 'text-blue-500',
    secondary: 'text-cyan-500',
    tertiary: 'text-neutral-500',
    danger: 'text-rose-500',
    ghost: 'text-gray-500',
  };

  const colorClass = variantColors[variant];

  return isOutbound ? (
    <MdCallMade className={`w-3 h-3 ${colorClass} ${className}`} />
  ) : (
    <MdCallReceived className={`w-3 h-3 ${colorClass} ${className}`} />
  );
};
