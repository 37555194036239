import { createRootRouteWithContext } from '@tanstack/react-router';
import React, { Suspense } from 'react';
import { App } from '../app';
import NotFoundView from 'components/views/not-found-view/not-found-view';
import { Auth0ContextInterface, User } from '@auth0/auth0-react';
import { z } from 'zod';

const TanStackRouterDevtools = import.meta.env.PROD
  ? () => null
  : React.lazy(() =>
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
        // For Embedded Mode
        // default: res.TanStackRouterDevtoolsPanel
      }))
    );

// Example to add search params
// https://tanstack.com/router/latest/docs/framework/react/guide/search-params
export const globalSearchSchema = z.object({
  phoneNumber: z.string().optional(),
  phoneVersion: z.enum(['default', 'v1']).optional(),
});
export type GlobalSearchSchema = z.infer<typeof globalSearchSchema>;

interface RouterContext {
  auth: Auth0ContextInterface<User>;
}
export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
  notFoundComponent: () => {
    return <NotFoundView />;
  },
  validateSearch: (search) => globalSearchSchema.parse(search),
});

function RootComponent() {
  return (
    <>
      <Suspense>
        <App />
      </Suspense>

      <Suspense>
        <TanStackRouterDevtools initialIsOpen={true} />
      </Suspense>
    </>
  );
}
