/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents the available communication channel types in the CRM system
 */
export enum ChannelType {
    WHATSAPP = 'WHATSAPP',
    PHONE = 'PHONE',
    EMAIL = 'EMAIL',
}
