import { MessageDocument, MessageDirection } from 'src/api/generated';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import {
  MdEmail,
  MdDone,
  MdDoneAll,
  MdError,
  MdAttachment,
  MdSubject,
} from 'react-icons/md';

interface TimelineEmailItemProps {
  item: MessageDocument;
  isNew?: boolean;
}

export function TimelineEmailItem({
  item,
  isNew = false,
}: TimelineEmailItemProps) {
  const isOutbound = item.direction === MessageDirection.OUTBOUND;

  const getStatusIcon = () => {
    switch (item.status) {
      case 'delivered':
        return <MdDoneAll className="w-4 h-4 text-blue-500" />;
      case 'sent':
        return <MdDone className="w-4 h-4 text-blue-500" />;
      case 'failed':
        return <MdError className="w-4 h-4 text-rose-500" />;
      default:
        return null;
    }
  };

  return (
    <motion.div
      initial={isNew ? { opacity: 0, y: 20 } : false}
      animate={{ opacity: 1, y: 0 }}
      className={`flex ${isOutbound ? 'justify-end' : 'justify-start'}`}
    >
      <div className="max-w-[70%] bg-white rounded-lg shadow-nm-flat">
        <div className="flex items-start gap-4 p-4">
          {/* Email Icon */}
          <div className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-50">
            <MdEmail className="w-5 h-5 text-blue-500" />
          </div>

          {/* Message Content */}
          <div className="flex-1">
            {/* Message Header */}
            <div className="flex items-start justify-between mb-2">
              <div>
                <h4 className="text-sm font-semibold text-gray-900">
                  {isOutbound ? 'You' : item.sender.displayName || 'Contact'}
                </h4>
                <div className="flex items-center gap-1 text-xs text-gray-500">
                  <MdSubject className="w-4 h-4" />
                  <span>{item.metadata?.subject || 'No Subject'}</span>
                </div>
              </div>
              <p className="text-xs text-gray-500">
                {format(new Date(item.createdAt), 'MMM d, yyyy')} at{' '}
                {format(new Date(item.createdAt), 'h:mm a')}
              </p>
            </div>

            {/* Message Body */}
            <div
              className={`p-3 rounded-lg mb-2 ${
                isOutbound
                  ? 'bg-blue-50 text-blue-900'
                  : 'bg-gray-50 text-gray-900'
              }`}
            >
              <p className="text-sm whitespace-pre-wrap">{item.content}</p>
              {item.attachments && item.attachments.length > 0 && (
                <div className="flex items-center gap-2 mt-2 text-xs text-gray-500">
                  <MdAttachment className="w-4 h-4" />
                  <span>{item.attachments.length} attachment(s)</span>
                </div>
              )}
            </div>

            {/* Message Status */}
            {isOutbound && (
              <div className="flex items-center gap-1 text-xs text-gray-500">
                <span>{item.status}</span>
                {getStatusIcon()}
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
}
