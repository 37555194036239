import { CallDirection, CallDocument, CallStatus } from 'src/api/generated';
import { motion } from 'framer-motion';
import { format, formatDuration } from 'date-fns';
import { es } from 'date-fns/locale';
import {
  MdPhone,
  MdPhoneMissed,
  MdOutlineDescription,
  MdOutlineAnalytics,
  MdTimer,
} from 'react-icons/md';
import { callStatusMap } from 'src/modules/shared/utils/call/formatting.util';
import { CircleDisplay } from 'src/modules/shared/components/atoms/circle-display';
import { Typography } from '@material-tailwind/react';
import { DirectionIndicator } from 'src/modules/shared/components/atoms/indicators/direction-indicator';
import ButtonModal from 'src/modules/shared/components/molecules/modals/button-modal';
import { AcademicCapIcon } from '@heroicons/react/24/outline';
import FeedbackModalContent from 'components/molecules/analysis/feedback-modal-content';
import SummaryModalContent from 'components/molecules/analysis/summary-modal-content';
import TranscriptionModalContent from 'components/molecules/analysis/transcription-modal-content';
import { Expandable } from 'src/modules/shared/components/molecules/expandable';

interface TimelineCallItemProps {
  item: CallDocument;
  groupedItems?: CallDocument[];
  isNew?: boolean;
}

const buttonClasses = [
  '!bg-white',
  'text-cyan-700',
  'border',
  'border-cyan-500',
  'hover:!bg-cyan-200',
  'active:!bg-cyan-300',
  'focus:!ring-cyan-500',
  'gap-1',
].join(' ');

export const TimelineCallItem: React.FC<TimelineCallItemProps> = ({
  item,
  groupedItems = [],
  isNew = false,
}) => {
  const isOutbound = item.direction === CallDirection.OUTGOING;
  const isMissed = item.status === CallStatus.NO_ANSWER;
  const isBusy = item.status === CallStatus.BUSY;
  const isCompleted = item.status === CallStatus.COMPLETED;

  const renderCallItem = (call: CallDocument, isMainItem: boolean = false) => (
    <div className="flex items-start gap-4 p-4">
      <CircleDisplay
        icon={<MdPhone />}
        variant="secondary"
        size="sm"
        className={`flex-shrink-0 !text-cyan-500 ${
          isOutbound ? '!bg-white' : '!bg-cyan-50'
        }`}
      />

      <div className="flex-1 flex flex-col gap-1 min-w-0">
        <div className="flex flex-wrap items-center justify-between gap-4 mb-2">
          <div className="flex flex-wrap items-center gap-1.5">
            <DirectionIndicator isOutbound={isOutbound} variant="secondary" />
            <h4 className="text-sm font-semibold text-primary-800 break-all">
              {isOutbound ? 'You' : call.to}
            </h4>
          </div>
          <Typography className="text-xs text-gray-500">
            {format(new Date(call.createdAt), 'HH:mm')}
          </Typography>
        </div>

        {isCompleted && (
          <div className="flex flex-wrap gap-2 gap-y-1.5 mb-2">
            <ButtonModal
              buttonContent={
                <>
                  <MdOutlineDescription className="w-3 h-3" />
                  <span>Transcription</span>
                </>
              }
              title="Transcription"
              size="2xs"
              className={buttonClasses}
            >
              {(enabled: boolean) => (
                <TranscriptionModalContent
                  callId={parseInt(call.id)}
                  enabled={enabled}
                />
              )}
            </ButtonModal>
            <ButtonModal
              buttonContent={
                <>
                  <MdOutlineAnalytics className="w-3 h-3" />
                  <span>Resumen</span>
                </>
              }
              title="Resumen"
              size="2xs"
              className={buttonClasses}
              bodyClassName={'!p-0'}
            >
              {(enabled: boolean) => (
                <SummaryModalContent
                  callId={parseInt(call.id)}
                  enabled={enabled}
                />
              )}
            </ButtonModal>
            <ButtonModal
              buttonContent={
                <>
                  <AcademicCapIcon className="w-3 h-3" />
                  <span>Feedback</span>
                </>
              }
              title="Feedback"
              bodyClassName={'!p-0'}
              size="2xs"
              className={buttonClasses}
            >
              {(enabled: boolean) => (
                <FeedbackModalContent
                  callId={parseInt(call.id)}
                  enabled={enabled}
                />
              )}
            </ButtonModal>
          </div>
        )}

        <div className="flex flex-wrap items-center justify-end gap-4 gap-y-2">
          <div className="flex flex-wrap items-center gap-1.5 text-xs text-gray-500">
            {call.duration > 0 && (
              <>
                <MdTimer className="w-4 h-4 text-cyan-500" />
                <Typography className="text-xs break-all">
                  {formatDuration({ seconds: call.duration }, { locale: es })}
                </Typography>
              </>
            )}
          </div>
          <div className="flex flex-wrap items-center gap-1.5 text-xs text-gray-500">
            {isMissed || isBusy ? (
              <MdPhoneMissed className="w-4 h-4 text-rose-500" />
            ) : (
              <MdPhone className="w-4 h-4 text-cyan-500" />
            )}
            <Typography className="text-xs break-all">
              {callStatusMap(call.status)}
            </Typography>
            {isMainItem && groupedItems && groupedItems.length > 1 && (
              <Typography className="text-xs text-gray-500">
                ({groupedItems.length})
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const expandedContent = groupedItems.slice(1).map((call) => (
    <div key={call.id} className="border-t border-gray-100">
      {renderCallItem(call)}
    </div>
  ));

  return (
    <motion.div
      initial={isNew ? { opacity: 0, y: 20 } : false}
      animate={{ opacity: 1, y: 0 }}
      className={`flex ${isOutbound ? 'justify-end' : 'justify-start'}`}
    >
      <div
        className={`w-fit min-w-0 max-w-[min(80%,_max-content)] overflow-hidden ${
          isOutbound ? 'bg-blue-50' : 'bg-white'
        } rounded-lg shadow-nm-flat-xs`}
      >
        {groupedItems.length > 1 ? (
          <Expandable expandedContent={expandedContent}>
            {renderCallItem(item, true)}
          </Expandable>
        ) : (
          renderCallItem(item, true)
        )}
      </div>
    </motion.div>
  );
};
