import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';
import AuthenticateRoute from 'components/organisms/app-layouts/authenticate-route';
import { globalSearchSchema } from '../__root';
import CommunicationsPage from 'src/modules/communications/components/pages/communications-page';

const searchSchema = z.object({
  view: z.enum(['cards', 'pipeline', 'table']).default('cards'),
  conversationId: z.number().optional(),
  contactId: z.number().optional(),
  funnelId: z.number().optional(),
  phoneVersion: globalSearchSchema.shape.phoneVersion.transform(() => 'v1'),
});

export const communicationsSearchSchema =
  globalSearchSchema.merge(searchSchema);
export type CommunicationsSearch = z.infer<typeof communicationsSearchSchema>;

export const Route = createFileRoute('/communications/')({
  component: CommunicationsLayoutComponent,
  validateSearch: (search) => communicationsSearchSchema.parse(search),
});

function CommunicationsLayoutComponent() {
  return (
    <AuthenticateRoute>
      <CommunicationsPage />
    </AuthenticateRoute>
  );
}
