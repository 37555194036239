import React, { forwardRef } from 'react';
import Label from '../label';
import {
  BaseProps,
  ComponentSize,
} from 'src/modules/shared/types/component.type';
import TextInput from '../text-input';

interface LabelTextInputProps extends BaseProps<object> {
  label: string;
  labelProps?: Omit<React.ComponentProps<typeof Label>, 'size' | 'variant'>;
  inputProps?: Omit<React.ComponentProps<typeof TextInput>, 'size' | 'variant'>;
  labelPosition?: 'side' | 'top';
}

const LabelTextInput = forwardRef<HTMLInputElement, LabelTextInputProps>(
  (
    {
      label,
      variant = 'primary',
      size = 'md' as ComponentSize,
      className = '',
      labelProps = {},
      inputProps = {},
      labelPosition = 'side',
    },
    ref
  ) => {
    const containerClasses = {
      side: 'flex-col sm:flex-row sm:items-center justify-between',
      top: 'flex-col relative',
    };

    const labelClasses = {
      top: [
        'absolute -top-[0.6rem] left-3 z-10',
        'px-0.5',
        'bg-gradient-to-b from-white via-white to-transparent',
        'pb-1',
      ].join(' '),
      side: '',
    };

    const topLabelSize: ComponentSize = ['xs', 'sm'].includes(size)
      ? '2xs'
      : 'xs';
    const labelSize = labelPosition === 'top' ? topLabelSize : size;

    return (
      <div
        className={`flex ${containerClasses[labelPosition]} ${
          labelPosition === 'top' ? '' : 'gap-2'
        } ${className}`}
      >
        <div className={labelPosition === 'side' ? 'sm:w-1/3' : ''}>
          <Label
            {...labelProps}
            variant={variant}
            size={labelSize}
            className={`${labelClasses[labelPosition]} ${labelProps.className || ''}`}
          >
            {label}
          </Label>
        </div>
        <div className="flex-1">
          <TextInput {...inputProps} ref={ref} variant={variant} size={size} />
        </div>
      </div>
    );
  }
);

LabelTextInput.displayName = 'LabelTextInput';

export default LabelTextInput;
