/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ChannelType } from './ChannelType';
import type { FunnelInteractionDirection } from './FunnelInteractionDirection';
import type { FunnelInteractionStatus } from './FunnelInteractionStatus';
import type { FunnelInteractionType } from './FunnelInteractionType';
import type { Record_string_unknown_ } from './Record_string_unknown_';

/**
 * Interface representing a funnel conversation document
 */
export type FunnelConversationDocument = {
    tenantId: string;
    createdAt: string;
    updatedAt: string;
    id: string;
    modelType: FunnelConversationDocument.modelType;
    archivedAt?: string;
    eventSequence?: number;
    eventTimestamp?: string;
    contactId?: string | null;
    funnelId?: string | null;
    funnelStatusId?: string | null;
    lastInteractionAt?: string;
    lastInteraction?: {
        direction?: FunnelInteractionDirection;
        status?: FunnelInteractionStatus;
        snippet?: string;
        modelType: FunnelInteractionType;
        id: string;
    };
    contacts?: Array<{
        customFields?: Record_string_unknown_;
        nationalId?: string;
        lastName?: string;
        firstName?: string;
        addresses?: Array<{
            channelType: ChannelType;
            address: string;
        }>;
        id: string;
    }>;
    sessions?: Array<{
        messagingConversationId?: string;
        channelType: ChannelType;
        endTime?: string | null;
        startTime: string;
        contactAddressId: number;
        funnelAddressId: number;
        id: string;
    }>;
};

export namespace FunnelConversationDocument {

    export enum modelType {
        CONVERSATION = 'Conversation',
    }


}

