import React, { ComponentProps, ReactNode } from 'react';
import Loading from '../../loading';
import {
  BaseProps,
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';
import {
  baseInputClasses,
  inputVariantClasses,
  inputSizeClasses,
  getDisabledClasses,
} from 'src/modules/shared/styles/component.styles';

interface SelectOption {
  content: ReactNode | string;
  value: string;
}

interface SelectProps
  extends Omit<BaseProps<ComponentProps<'select'>>, 'size' | 'variant'> {
  options: SelectOption[];
  size: ComponentSize;
  variant: ComponentVariant;
  containerClassName?: string;
}

const Select: React.FC<SelectProps> = ({
  options,
  variant,
  size,
  loading = false,
  disabled = false,
  className = '',
  containerClassName = '',
  ...rest
}) => {
  const classes = [
    'appearance-none',
    ...baseInputClasses,
    inputVariantClasses[variant],
    inputSizeClasses[size],
    getDisabledClasses(disabled || loading),
    className,
  ].join(' ');

  return (
    <div className={`relative flex-1 ${containerClassName}`}>
      <select className={classes} disabled={disabled || loading} {...rest}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.content}
          </option>
        ))}
      </select>
      <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
        {loading ? (
          <Loading className="w-4 h-4" />
        ) : (
          <svg
            className="w-4 h-4 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </div>
    </div>
  );
};

export default Select;
