/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Type representing the allowed operators for search filters
 */
export enum SearcherDocumentSearchFilterOperator {
    EQ = 'eq',
    NEQ = 'neq',
    GT = 'gt',
    LT = 'lt',
    GTE = 'gte',
    LTE = 'lte',
    CONTAINS = 'contains',
    EXISTS = 'exists',
    MISSING = 'missing',
    IN = 'in',
    NIN = 'nin',
    STARTS_WITH = 'startsWith',
}
