import { useState } from 'react';
import {
  List,
  ListItem,
  ListItemPrefix,
  Typography,
} from '@material-tailwind/react';
import { Avatar } from 'src/modules/shared/components/atoms/avatar';
import { formatPhoneNumberAsYouType } from 'src/modules/shared/utils/formatting/phone.util';
import TextInput from '../../atoms/inputs/text-input';

interface ContactSelectProps {
  onSelect: (contactId: string, phoneNumber: string) => void;
  selectedContactId: string | null;
}

// TODO: Replace with actual contact type from API
interface Contact {
  id: string;
  name: string;
  phoneNumber: string;
}

export const ContactSelect = ({
  onSelect,
  selectedContactId,
}: ContactSelectProps) => {
  const [searchTerm, setSearchTerm] = useState('');

  // TODO: Replace with actual contacts hook
  const contacts: Contact[] = [
    // Temporary mock data
    { id: '1', name: 'John Doe', phoneNumber: '+1234567890' },
    { id: '2', name: 'Jane Smith', phoneNumber: '+0987654321' },
  ];

  const filteredContacts = contacts.filter(
    (contact) =>
      contact.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.phoneNumber.includes(searchTerm)
  );

  return (
    <div className="space-y-2">
      <div className="relative">
        <TextInput
          type="text"
          placeholder="Search Contacts"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="!border-t-blue-gray-200 focus:!border-t-blue-600"
        />
      </div>

      <List className="max-h-48 overflow-auto">
        {filteredContacts.map((contact) => (
          <ListItem
            key={contact.id}
            selected={contact.id === selectedContactId}
            onClick={() => onSelect(contact.id, contact.phoneNumber)}
            className="hover:bg-blue-gray-50"
          >
            <ListItemPrefix>
              <Avatar text={contact.name} size="sm" />
            </ListItemPrefix>
            <div>
              <Typography
                variant="small"
                color="blue-gray"
                className="font-medium"
              >
                {contact.name}
              </Typography>
              <Typography variant="small" color="gray" className="font-normal">
                {formatPhoneNumberAsYouType(contact.phoneNumber)}
              </Typography>
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  );
};
